const PaginatedNumbers = ({ totalPages, page, changePage, maxPageNumberLimit, minPageNumberLimit }: any) => {
    const pageNumbers = [];
    const ellipsis = <span className="inline-block px-2 py-1">...</span>;

    for (let i = 1; i <= totalPages; i++) {
        if ((i >= minPageNumberLimit && i <= maxPageNumberLimit) || i === 1 || i === totalPages) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => changePage(i)}
                    className={`${
                        page === i ? 'text-white bg-slate-950' : 'hover:bg-gray-50'
                    } border-gray-300 relative inline-flex items-center px-4 py-2 border text-sm font-medium`}
                >
                    {i}
                </button>,
            );
        } else if (
            (i === minPageNumberLimit - 1 && minPageNumberLimit > 2) ||
            (i === maxPageNumberLimit + 1 && maxPageNumberLimit < totalPages - 1)
        ) {
            pageNumbers.push(ellipsis);
        }
    }

    return pageNumbers;
};

export default function Pagination({ totalPages, totalRecords, page, changePage, incrementPage, decrementPage, pageSize }: any) {
    const firstRecordIndex = (page - 1) * pageSize + 1;
    const lastRecordIndex = Math.min(page * pageSize, totalRecords);
    const maxPageNumberLimit = Math.min(page + 2, totalPages);
    const minPageNumberLimit = Math.max(page - 2, 1);

    return (
        <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">{firstRecordIndex}</span> to{' '}
                        <span className="font-medium">{lastRecordIndex}</span> of
                        <span className="font-medium">{totalRecords}</span> results
                    </p>
                </div>
                <div>
                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                        <button
                            disabled={page < 2}
                            onClick={decrementPage}
                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                            <span className="sr-only">Previous</span>
                            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path
                                    fillRule="evenodd"
                                    d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                        <PaginatedNumbers
                            totalPages={totalPages}
                            changePage={changePage}
                            page={page}
                            minPageNumberLimit={minPageNumberLimit}
                            maxPageNumberLimit={maxPageNumberLimit}
                        />
                        <button
                            disabled={page === totalPages}
                            onClick={incrementPage}
                            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        >
                            <span className="sr-only">Next</span>
                            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path
                                    fillRule="evenodd"
                                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    );
}
