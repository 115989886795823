import { createTRPCReact, httpBatchLink } from '@trpc/react-query';

import type { AppRouter } from 'api';

export const trpc = createTRPCReact<AppRouter>();

export const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url:import.meta.env.VITE_BACKEND_BASEURL,
    }),
  ],
});