/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from 'date-fns';
import { HTMLInputTypeAttribute, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Alert from '../components/alert';
import LoaderContainer from '../components/loader';
import Modal from '../components/modal';
import Pagination from '../components/pagination';
import Table from '../components/table';
import useAlert from '../hooks/useAlert';
import useModal from '../hooks/useModal';
import { trpc } from '../lib/trpc';
import FilterContact from '../section/contact/filterContact';
import ResolveMessageContact from '../section/contact/resolveContactMessage';
import ViewMessageContact, { dataMessageType } from '../section/contact/viewContactMessage';
import { IFilterBaseQuery } from './transactions';


interface Option {
    value: string;
    label: string;
}

export type contactStatusEnum = 'READ' | 'UNREAD';

type Column = {
    label: string;
    key: string;
    format?: (v1: any, v2?: any) => any;
    meta?: { type: HTMLInputTypeAttribute | 'select'; options?: Option[] } | null;
};

const columnHeaders: Column[] = [
    { label: 'Customer Name', key: 'name' },
    { label: 'Issue Category', key: 'issue' },
    { label: 'Created At', key: 'createdAt', format: (date: string) => format(date, 'hh:mm dd MMMM, yyyy') },
    { label: 'Status', key: 'status', format: (status: string) => status?.replaceAll('_', ' ') },
    { label: 'Resolved', key: 'resolution' },
];

type ContactQueryParams = Omit<IFilterBaseQuery, 'type'> & {
    search?: string;
};

function ContactUs () {
    const { alert, showAlert } = useAlert();
    const { isModalVisible, setModalClose, setModalOpen } = useModal();
    const [messageData, setMessageData] = useState({} as dataMessageType);
    const [searchTerm, setSearchTerm] = useState(''); // Debounce state
    const [queryParams, setQueryParams] = useState({
        page: 1,
        limit: 25,
        search: '',
    });

    useEffect(() => {
        const handler = setTimeout(() => {
            updateQueryParams({ search: searchTerm, page: 1 });
        }, 500); // 500ms debounce

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    const contactFormField = useForm({
        defaultValues: {
            dateFrom: '' as Date | string,
            dateTo: '' as Date | string,
            status: '' as contactStatusEnum | string,
        },
        mode: 'onChange',
    });

    const { data: contactData, isError, isLoading } = trpc.contact.getAllContactUs.useQuery(queryParams);
    const { data, meta } = contactData || {};
    const updateQueryParams = (params: ContactQueryParams) => {
        setQueryParams(prev => ({ ...prev, ...params }));
    };
    // For the pagination
    const [pageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);

    const changePage = (pageNumber: number) => {
        updateQueryParams({ page: pageNumber });
    };

    const incrementPage = () => {
        updateQueryParams({ page: queryParams?.page + 1 });
        if (queryParams?.page + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };

    const decrementPage = (): void | null => {
        updateQueryParams({ page: queryParams?.page - 1 });
        if ((queryParams?.page - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
        if (queryParams?.page - 1 === 0) {
            return null;
        }
    };

    const submitFilter = () => {
        const { dateFrom, dateTo } = contactFormField.getValues();
        const dateFromFormatted = dateFrom ? format(dateFrom, 'yyyy-MM-dd') : '';
        const dateToFormatted = dateTo ? format(dateTo, 'yyyy-MM-dd') : '';

        updateQueryParams({
            page: 1,
            dateFrom: dateFromFormatted,
            dateTo: dateToFormatted,
            status: contactFormField.getValues().status as contactStatusEnum | string,
        });
    };

    if (isError) {
        return <h2>Error...</h2>;
    }
    const handleFilterModal = () => {
        setModalOpen(1);
    };
    const handleClear = () => {
        setSearchTerm('');
        contactFormField.reset();
        updateQueryParams({ dateFrom: '', dateTo: '', page: 1, status: '' });
        setModalClose(1);
    };

    return (
        <div>
            {alert && <Alert classes={'w-[20%] ml-auto'} message={alert.message} event={alert.type} />}

            <Modal title="Filter Contact Us" showModal={isModalVisible(1)} closeModal={() => setModalClose(1)}>
                <FilterContact
                    contactFormField={contactFormField}
                    setModalClose={() => setModalClose(1)}
                    submitFilter={submitFilter}
                    handleResetForm={handleClear}
                />
            </Modal>
            <Modal title="View Message Details" closeModal={() => setModalClose(2)} showModal={isModalVisible(2)}>
                <ViewMessageContact dataMessage={messageData} setModalClose={() => setModalClose(2)} showAlert={showAlert}
                />
            </Modal>
            <Modal title="Resolve Message" closeModal={() => setModalClose(3)} showModal={isModalVisible(3)}>
                <ResolveMessageContact dataMessage={messageData} setModalClose={() => setModalClose(3)} showAlert={showAlert} />
            </Modal>
            <div className="flex items-center justify-between">
                <h1 className="text-xl">
                    <span className="font-bold">Contact Us</span>
                </h1>
            </div>
            <div className="flex items-center justify-end gap-3">
                <div className="flex items-center gap-[20px] sm:gap-[0px] h-[45px] w-[400px] py-1 px-3 rounded-[8px] border-[1px] ring-offset-gray-800 focus:ring-2 border-gray-600">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={({ target: { value } }) => setSearchTerm(value)}
                        placeholder="Search by customer name or issue..."
                        className="w-full h-full text-[18px] sm:text-[14px] outline-none bg-transparent border-none focus:outline-none"
                    />
                    <p
                        onClick={handleClear}
                        className="font-secondary font-[400] text-darky-col rounded-[45px] py-1 cursor-pointer text-[13px]"
                    >
                        X
                    </p>
                </div>
                <button
                    onClick={handleFilterModal}
                    className="border-2 border-solid rounded-lg border-[#374151] text-white bg-[#374151] px-3 py-2 mr-3.5 font-medium hover:bg-white hover:text-[#374151] hover:font-semibold"
                >
                    Filter
                </button>
            </div>
            <main className="mt-[30px]">
                <LoaderContainer loading={isLoading}>
                    <Table
                        title=""
                        columns={columnHeaders}
                        data={data || []}
                        useView={true}
                        enableActions={true}
                        onRowReport={(data: any) => {
                            setModalOpen(2);
                            setMessageData(data);
                        }}
                        onRowView={(data: any) => {
                            setModalOpen(3);
                            setMessageData(data);
                        }}
                    />
                    <Pagination
                        totalPages={meta?.totalPages || 0}
                        pageSize={meta?.limit || 0}
                        totalRecords={meta?.totalRecords || 0}
                        page={meta?.currentPage || 0}
                        changePage={changePage}
                        incrementPage={incrementPage}
                        decrementPage={decrementPage}
                        pageNumberLimit={pageNumberLimit || 0}
                        minPageNumberLimit={minPageNumberLimit || 0}
                        maxPageNumberLimit={maxPageNumberLimit || 0}
                    />{' '}
                </LoaderContainer>
            </main>
        </div>
    );
}

export default ContactUs;
