import { zodResolver } from '@hookform/resolvers/zod';
import React, { HTMLInputTypeAttribute, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EditIcon, SaveIcon } from '../table';

interface EditableTextProps {
    value: string;
    onChange: (newValue: string) => void;
    type?: HTMLInputTypeAttribute | undefined;
    validationSchema?: any;
    name: string
}

const EditableText: React.FC<EditableTextProps> = ({ value, onChange, type, validationSchema, name }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ defaultValues: { [name]: value }, resolver: zodResolver(validationSchema) });
    const [isEditing, setIsEditing] = useState(false);

    const handleSave = (data: any) => {
        const value = data[name]
        onChange(value);
        setIsEditing(false);
    };

    const handleClick = () => {
        setIsEditing(true);
    };
    return (
        <div className="flex cursor-pointer">
            {isEditing ? (
                <form className="flex flex-col" onSubmit={handleSubmit(handleSave)}>
                    <div className='flex flex-row items-center'>
                        <input
                            className="py-3 px-2 rounded-md"
                            type={type || 'text'}
                            autoFocus
                            {...register(name)}
                        />
                        <button type="submit" className="focus:outline-none">
                            <SaveIcon />
                        </button>
                    </div>
                    {errors && (
                        <span className="text-red-500 text-sm">{errors?.[name]?.message}</span>
                    )}
                </form>
            ) : (
                <p onClick={handleClick} className="cursor-pointer mr-5">
                    {watch(name)}
                </p>
            )}
            {!isEditing && <EditIcon onClick={handleClick} />}
        </div>
    );
};

export default EditableText;
