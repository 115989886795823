import { useState } from 'react';
import Alert from '../../components/alert';
import LoaderContainer from '../../components/loader';
import useAlert from '../../hooks/useAlert';
import { trpc } from '../../lib/trpc';

type FeeOpt = 'add' | 'subtract' | '';
type Currency = 'EUR' | 'GBP' | 'USD' | '';
type Direction = 'OFFRAMP' | 'ONRAMP' | '';

export const FeesForm = ({ merchantFees = [], isLoadingMerchantFees = false }: any) => {
    const [fee, setFee] = useState('');
    const [inputCurrency, setInputCurrency] = useState<Currency>('');
    const [outputCurrency, setOutputCurrency] = useState<Currency>('');
    const [direction, setDirection] = useState<Direction>('');
    const [feeOpType, setFeeOpType] = useState<FeeOpt>('');
    const { showAlert, alert } = useAlert();
    const utils = trpc.useUtils();

    const { mutate, isLoading } = trpc.adminSetting.updateMerchantCustomerFee.useMutation({
        onSuccess: () => {
            showAlert('Fee successfully updated', 'success');
            utils.adminSetting.getMerchantFees.refetch();
            setInputCurrency('');
            setOutputCurrency('');
            setDirection('');
            setFeeOpType('');
            setFee('');
        },
        onError: () => {
            showAlert('Fee failed to update', 'error');
        },
    });

    const handleSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        if (!inputCurrency || !outputCurrency || !direction || !feeOpType) return;
        const feeData = {
            fee: parseFloat(fee),
            input_currency: inputCurrency,
            output_currency: outputCurrency,
            direction,
            fee_op_type: feeOpType,
        };

        mutate(feeData);
    };

    return (
        <main>
            {alert && <Alert classes={'w-[20%] ml-auto'} message={alert.message} event={alert.type} />}

            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Current Fee Information</h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Details about the current fee settings.</p>
            </div>
            <div className="grid grid-cols-2 gap-2">
                <div className="border-t border-gray-200">
                    <dl>
                        {merchantFees.length > 0 && !isLoadingMerchantFees ? (
                            merchantFees.map(
                                (fee: {
                                    uuid: string;
                                    percentage_fee: string;
                                    input_currency: string;
                                    output_currency: string;
                                    direction: string;
                                    fee_op_type: string | number;
                                }) => (
                                    <div
                                        key={fee.uuid}
                                        className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                                    >
                                        <dt className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded ">
                                            Fee
                                        </dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {fee.percentage_fee}
                                        </dd>
                                        <dt className="text-sm font-medium text-gray-500">Input Currency</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {fee.input_currency}
                                        </dd>
                                        <dt className="text-sm font-medium text-gray-500">Output Currency</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {fee.output_currency}
                                        </dd>
                                        <dt className="text-sm font-medium text-gray-500">Direction</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {fee.direction}
                                        </dd>
                                        <dt className="text-sm font-medium text-gray-500">Fee Operation Type</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {fee.fee_op_type}
                                        </dd>
                                    </div>
                                ),
                            )
                        ) : (
                            <LoaderContainer loading={isLoadingMerchantFees}>
                                <div className="px-4 py-5 sm:px-6">
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        No fee information available for the selected criteria.
                                    </p>
                                </div>
                            </LoaderContainer>
                        )}
                    </dl>
                </div>
                <form onSubmit={handleSubmit} className="mt-5 w-[50%] space-y-4">
                    <div className="py-2">
                        <p className="mt-1 max-w-2xl text-xs text-gray-500">
                            <span className="font-bold text-blue-500">Normal Fee:</span> Choose this if you want users
                            to pay an additional fee on top of their purchase, which goes to you.
                        </p>
                        <p className="mt-1 max-w-2xl text-xs text-gray-500">
                            <span className="font-bold text-blue-500"> Discount Fee by: </span> Opt for this if you
                            prefer to reduce the fee charged to users, with you covering the discounted portion. Ideal
                            for making purchases more affordable for customers.
                        </p>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Fee Operation Type:
                            <select
                                value={feeOpType}
                                onChange={e => setFeeOpType(e.target.value as FeeOpt)}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="" disabled>
                                    Select
                                </option>
                                <option value="add">Normal Fee </option>
                                <option value="subtract">Discount Fee by </option>
                            </select>
                        </label>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Fee:
                            <input
                                placeholder="0.01"
                                type="number"
                                step="0.0001"
                                value={fee}
                                onChange={e => setFee(e.target.value)}
                                required
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                            <p className="text-xs font-medium text-gray-700"> e.g.: 0.01 corresponds to 1% </p>
                        </label>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Input Currency:
                            <select
                                value={inputCurrency}
                                onChange={e => setInputCurrency(e.target.value as Currency)}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="" disabled>
                                    Select
                                </option>
                                <option value="EUR">EUR</option>
                                <option value="USD">USD</option>
                                <option value="GBP">GBP</option>
                            </select>
                        </label>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Output Currency:
                            <select
                                value={outputCurrency}
                                onChange={e => setOutputCurrency(e.target.value as Currency)}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="" disabled>
                                    Select
                                </option>
                                <option value="EUR">EUR</option>
                                <option value="USD">USD</option>
                                <option value="GBP">GBP</option>
                            </select>
                        </label>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">
                            Direction:
                            <select
                                value={direction}
                                onChange={e => setDirection(e.target.value as Direction)}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                <option value="" disabled>
                                    Select
                                </option>
                                <option value="ONRAMP">ONRAMP</option>
                                <option value="OFFRAMP">OFFRAMP</option>
                            </select>
                        </label>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            {isLoading ? 'Processing' : 'Update Fee'}
                        </button>
                    </div>
                </form>
            </div>
        </main>
    );
};


