// import { zodResolver } from '@hookform/resolvers/zod';
import { Event } from '../../components/alert';
import { trpc } from '../../lib/trpc';
import { UnblockBankObject } from '../../route/userView';

type UserSetUnblockBankDefaultProps = {
    setModalClose: () => void;
    id: number;
    showAlert?: (message: string, type: Event, delay?: number) => void;
    incomingBankData: UnblockBankObject;
    corporateId?: number;
};

function UserSetUnblockBankDefault({
    setModalClose,
    id,
    showAlert,
    incomingBankData,
    corporateId,
}: UserSetUnblockBankDefaultProps) {
    const utils = trpc.useUtils();

    const closeModal = () => {
        setModalClose();
    };

    const { mutate: setUnblockBankDefault, isLoading } = trpc.admin.userSetDefaultUnblockBankAccount.useMutation({
        onSuccess: res => {
            if (res?.success) {
                utils.admin.listUnblockDestinationBankAccounts.refetch();
                showAlert && showAlert('Default bank set successfully', 'success');
                closeModal();
            }
        },
        onError: () => {
            showAlert && showAlert('Failed to set default bank', 'error');
        },
    });

    const { mutate: corporateSetUnblockBankDefault, isLoading: corporateLoading } =
        trpc.corporate.corporateSetDefaultUnblockBankAccount.useMutation({
            onSuccess: () => {
                utils.admin.listUnblockDestinationBankAccounts.refetch();
                showAlert && showAlert('Default bank set successfully', 'success');
                closeModal();
            },
            onError: () => {
                showAlert && showAlert('Failed to set default bank', 'error');
            },
        });

    const handleBankDefault = ({ bankUuid }: { bankUuid: string }) => {
        if (corporateId) {
            corporateSetUnblockBankDefault({ bankUuid: bankUuid, userId: id, corporateId: corporateId });
        } else {
            setUnblockBankDefault({ userId: id, bankUuid: bankUuid });
        }
    };

    return (
        <div>
            <p className="text-[20px] text-center">
                Do you want to set this bank with{' '}
                <b>
                    {incomingBankData?.currency === 'EUR' ? 'IBAN' : 'SORT_CODE'} {''}
                </b>
                <span className="italic font-bold">
                    {incomingBankData?.currency === 'EUR' ? incomingBankData.iban : incomingBankData.sort_code}
                </span>{' '}
                as your default bank for <b>{incomingBankData?.currency === 'EUR' ? ' EUR' : 'GBP'}</b>?
            </p>
            <div className="flex items-center justify-end p-4 rounded-b md:p-5">
                <button
                    type="submit"
                    className="mr-5 btn btn-outline"
                    onClick={() => handleBankDefault({ bankUuid: incomingBankData?.uuid })}
                >
                    {isLoading || corporateLoading ? 'Submitting...' : 'Submit'}
                </button>
                <button type="button" onClick={closeModal} className="btn btn-outline">
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default UserSetUnblockBankDefault;
