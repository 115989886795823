import { useForm } from 'react-hook-form';
import Alert from '../../components/alert';
import useAlert from '../../hooks/useAlert';
import { trpc } from '../../lib/trpc';

export const RefreshIcon = ({ onClick, isRefetching }: { onClick?: () => void; isRefetching: boolean }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="12"
            height="12"
            viewBox="0 0 40 40"
            onClick={onClick}
            className={`${isRefetching ? 'animate-spin text-green-500' : ''} cursor-pointer`}
        >
            <path
                fill="#8bb7f0"
                d="M4.207,35.5l2.573-2.574l-0.328-0.353C3.259,29.143,1.5,24.677,1.5,20C1.5,9.799,9.799,1.5,20,1.5 c0.776,0,1.598,0.062,2.5,0.19v4.032C21.661,5.575,20.823,5.5,20,5.5C12.005,5.5,5.5,12.005,5.5,20 c0,3.578,1.337,7.023,3.765,9.701l0.353,0.389l2.883-2.883V35.5H4.207z"
            ></path>
            <path
                fill="#4e7ab5"
                d="M20,2c0.627,0,1.287,0.042,2,0.129v3.009C21.33,5.046,20.661,5,20,5C11.729,5,5,11.729,5,20 c0,3.702,1.383,7.267,3.894,10.037l0.705,0.778l0.743-0.743L12,28.414V35H5.414l1.379-1.379l0.682-0.682l-0.657-0.706 C3.711,28.895,2,24.551,2,20C2,10.075,10.075,2,20,2 M20,1C9.507,1,1,9.507,1,20c0,4.994,1.934,9.527,5.086,12.914L3,36h10V26 l-3.365,3.365C7.387,26.885,6,23.612,6,20c0-7.732,6.268-14,14-14c1.031,0,2.033,0.119,3,0.33V1.259C22.02,1.104,21.023,1,20,1 L20,1z"
            ></path>
            <g>
                <path
                    fill="#8bb7f0"
                    d="M20,38.5c-0.776,0-1.598-0.062-2.5-0.19v-4.032c0.839,0.147,1.677,0.222,2.5,0.222 c7.995,0,14.5-6.505,14.5-14.5c0-3.583-1.336-7.03-3.761-9.706l-0.353-0.389L27.5,12.793V4.5h8.293l-2.581,2.582l0.328,0.354 C36.738,10.872,38.5,15.334,38.5,20C38.5,30.201,30.201,38.5,20,38.5z"
                ></path>
                <path
                    fill="#4e7ab5"
                    d="M34.586,5l-1.387,1.387l-0.682,0.682l0.657,0.706C36.286,11.119,38,15.461,38,20 c0,9.925-8.075,18-18,18c-0.627,0-1.287-0.042-2-0.129v-3.009C18.67,34.954,19.339,35,20,35c8.271,0,15-6.729,15-15 c0-3.708-1.381-7.274-3.89-10.041l-0.705-0.778l-0.743,0.743L28,11.586V5H34.586 M37,4H27v10l3.369-3.369 C32.618,13.111,34,16.388,34,20c0,7.732-6.268,14-14,14c-1.031,0-2.033-0.119-3-0.33v5.071C17.98,38.896,18.977,39,20,39 c10.493,0,19-8.507,19-19c0-4.993-1.942-9.519-5.094-12.906L37,4L37,4z"
                ></path>
            </g>
        </svg>
    );
};
export const ExchangeRateView = () => {
    const { showAlert, alert } = useAlert();
    const utils = trpc.useUtils();
    const { register, handleSubmit, reset } = useForm({
        defaultValues: {
            rate: '',
        },
    });

    const {
        data,
        refetch,
        isRefetching,
        isLoading: isLoadingExchangeRate,
    } = trpc.adminInfo.getExchangeRateInShiga.useQuery({});


    const { mutate, isLoading } = trpc.adminSetting.modifyShigaExchangeRate.useMutation({
        onSuccess: res => {
            showAlert(res.message, 'success');
            if (res.status === 'success') {
                utils.adminInfo.getExchangeRateInShiga.invalidate();
            }
            reset();
        },
        onError: () => {
            showAlert('Failed to update exchange rate', 'error');
        },
    });

    const submitForm = (data: { rate: string }) => {
        if (!data.rate) return showAlert('Rate Fee is required', 'error');

        if (data.rate.startsWith('-')) return showAlert('Rate Fee cannot be negative', 'error');

        mutate({
            rate: Number(data.rate),
        });
    };

    const handleRefetch = () => {
        refetch();
    };

    return (
        <div>
            {alert && <Alert classes={'w-[20%] ml-auto'} message={alert.message} event={alert.type} />}{' '}
            <div className="text-xl font-[500] flex gap-x-2 items-center">
                <p>Current Exchange Rate: {data?.rate}</p>
                <RefreshIcon onClick={handleRefetch} isRefetching={isRefetching || isLoadingExchangeRate || isLoading} />
            </div>
            <form onSubmit={handleSubmit(submitForm)} className="max-w-lg p-6 mx-auto">
                <label className="block text-sm font-medium text-gray-700">
                    Exchange Rate:
                    <input
                        placeholder="0.01"
                        type="number"
                        step="0.0001"
                        {...register('rate')}
                        // required
                        className="block w-full p-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <p className="text-xs font-medium text-gray-700"> e.g.: 0.01 corresponds to 1% </p>
                </label>

                <button
                    type="submit"
                    className="w-full px-4 py-2 mt-4 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    {isLoading ? 'Updating...' : 'Update Exchange Rate'}
                </button>

                <p className="mt-8 text-[15px] leading-5 font-medium text-gray-700">
                    Note: The exchange rate is derived at by adding the inputted value to unblock rate at the backend to
                    get the total exchange rate. The rate showing is never added to or subtracted from to generate a new
                    exchange rate but only for display.
                </p>
            </form>
        </div>
    );
};
