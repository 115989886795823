import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from '../components/navbar';

function Authentication() {
    const { isAuthenticated } = useAuth0();
    const navigate = useNavigate();

    useEffect(()=>{
        if (isAuthenticated) {
            navigate('/home');
            return;
        }
    })

    return (
        <div className="h-screen flex items-center justify-center">
            <NavBar />
            <img className="h-[10%] max-w-full" src="./images/shiga.svg" alt="shiga logo" />
        </div>
    );
}

export default Authentication;
