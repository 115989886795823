import { Controller, } from 'react-hook-form';
import { accountStatementForm, Option } from './type';


const SelectUserType = ({
    option,
    accountStatementForm,
}: {
    option: Option[];
    accountStatementForm: accountStatementForm;
}) => {
    const { control, watch, setValue } = accountStatementForm;
    const watchedValue = watch('idType');
    return (
        <div className="flex w-full gap-4">
            {option.map(type => {
                return (
                    <div key={type.label} className="m-0 w-fullitems-center">
                        <div className="flex items-center justify-between w-full cursor-pointer rounded-xl gap-x-2">
                            <div>
                                <label htmlFor={type.value} className="text-[13px] font-[400] cursor-pointer">
                                    {type.label}
                                </label>
                            </div>
                            <div>
                                <Controller
                                    name={'idType'}
                                    control={control}
                                    render={({ field: { onChange } }) => (
                                        <div className="text-[13px] flex gap-[5px] items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                name={'idType'}
                                                checked={watchedValue === type.value}
                                                id={type.value}
                                                value={type.value}
                                                className={`border-[#0e2038] accent-[#0e2038] cursor-pointer`}
                                                onChange={e => {
                                                    onChange(e.target.checked ? e.target.value : '')
                                                    if (type.value === 'corporate') {
                                                        setValue('userId', '');
                                                    } else if (type.value === 'user') {
                                                        setValue('corporateId', '');
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default SelectUserType;
