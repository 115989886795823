export function BlockText({
    status,
    chain,
    direction,
    transactionUuid,
    timeStamp,
    amountCrypto,
    amountFiat,
}: {
    status: string;
    chain: string;
    direction: string;
    transactionUuid: string;
    timeStamp: string;
    amountCrypto: number;
    amountFiat: number;
}) {
    return [
        {
            type: 'section',
            text: {
                type: 'mrkdwn',
                text: 'Hi Shiga Admin :wave:',
            },
        },
        {
            type: 'section',
            text: {
                type: 'mrkdwn',
                text: `Dear Admin,  I encountered an issue with my recent transaction, and it appears to have a status of ${status.toUpperCase()}. Could you please assist in resolving this matter as soon as possible. Below is the details of the transaction:`,
            },
        },
        {
            type: 'section',
            text: {
                type: 'mrkdwn',
                text: `• Chain: ${chain} \n • Direction: ${direction} \n • Transaction UUID: ${transactionUuid} \n • Time Stamp: ${timeStamp} \n • Amount Crypto: ${amountCrypto} \n • Amount Fiat: ${amountFiat}`,
            },
        },
        {
            type: 'section',
            text: {
                type: 'mrkdwn',
                text: 'Thank you for your help!',
            },
        },
    ];
}
export function SuccessBlockText({
    chain,
    direction,
    transactionUuid,
    timeStamp,
    amountCrypto,
    amountFiat,
}: {
    chain: string;
    direction: string;
    transactionUuid: string;
    timeStamp: string;
    amountCrypto: number;
    amountFiat: number;
}) {
    return [
        {
            type: 'section',
            text: {
                type: 'mrkdwn',
                text: 'Hi Shiga Admin :wave:',
            },
        },
        {
            type: 'section',
            text: {
                type: 'mrkdwn',
                text: `Dear Admin, here is a copy of your recent transaction, and it appears to have a status of SUCCESS. Below is the details of the transaction:`,
            },
        },
        {
            type: 'section',
            text: {
                type: 'mrkdwn',
                text: `• Chain: ${chain} \n • Direction: ${direction} \n • Transaction UUID: ${transactionUuid} \n • Time Stamp: ${timeStamp} \n • Amount Crypto: ${amountCrypto} \n • Amount Fiat: ${amountFiat}`,
            },
        },
        {
            type: 'section',
            text: {
                type: 'mrkdwn',
                text: 'Thank you',
            },
        },
    ];
}

// export default BlockText
