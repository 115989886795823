import { format } from 'date-fns';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import LoaderContainer from '../components/loader';
import { trpc } from '../lib/trpc';
import CorporateDropdown from '../section/accountsStatement/corporateDropDown';
import DateSelect from '../section/accountsStatement/dateSelect';
import DirectionAndChain from '../section/accountsStatement/directionAndChain';
import SelectUserType from '../section/accountsStatement/selectUserType';
import StatementTransactionsList from '../section/accountsStatement/statementTable';
import { queryType, StatementQuery } from '../section/accountsStatement/type';
import UserDropdown from '../section/accountsStatement/userDropdown';

const accountType = [
    {
        label: 'Individual',
        value: 'user',
    },
    {
        label: 'Corporate',
        value: 'company',
    },
];

function AccountsStatement() {
    const [queryParams, setQueryParams] = useState({
        type: 'user' as queryType,
    } as StatementQuery);
    const updateQueryParams = (params: StatementQuery) => {
        setQueryParams(prev => ({ ...prev, ...params }));
    };
    const accountStatementForm = useForm({
        defaultValues: {
            dateFrom: '' as Date | string,
            dateTo: '' as Date | string,
            idType: 'user' as queryType,
            userId: '' as string,
            corporateId: '' as string,
            direction: '' as string,
            chain: '' as string,
        },
        mode: 'onChange',
    });

    const { getValues, handleSubmit, watch } = accountStatementForm;
    const watchedValue = watch('idType');

    const { data, isLoading } = trpc.adminTransaction.summaryTransactions.useQuery(queryParams, {
        enabled: String(queryParams.id) !== '' || !!queryParams.direction,
        refetchOnWindowFocus: false,
    });

    const submitForm = () => {
        const { dateFrom, dateTo } = accountStatementForm.getValues();
        const dateFromFormatted = dateFrom ? format(dateFrom, 'yyyy-MM-dd') : '';
        const dateToFormatted = dateTo ? format(dateTo, 'yyyy-MM-dd') : '';
        const id = watchedValue === 'user' ? getValues('userId') : getValues('corporateId');

        updateQueryParams({
            chain: getValues('chain'),
            direction: getValues('direction') as 'cryptoToFiat' | 'fiatToCrypto',
            id: id ? Number(id) : undefined,
            type: getValues('idType'),
            dateFrom: dateFromFormatted,
            dateTo: dateToFormatted,
        });
    };

    const handleClear = () => {
        accountStatementForm.reset();
        updateQueryParams({
            dateFrom: '',
            dateTo: '',
            type: 'user' as queryType,
            id: undefined,
            direction: '' as 'cryptoToFiat' | 'fiatToCrypto',
            chain: '',
        });
    };

    return (
        <div className="mx-5">
            <div className="flex items-center justify-between mb-4">
                <h1 className="text-xl">
                    <span className="font-bold">Accounts Statement</span>
                </h1>
                <div>
                    <h1 className="text-[16px] font-[500]">Select Account Type</h1>
                    <div>
                        <SelectUserType option={accountType} accountStatementForm={accountStatementForm} />
                    </div>
                </div>
            </div>
            <div>
                <div className="flex items-center justify-between gap-x-3">
                    {watchedValue === 'user' ? (
                        <UserDropdown accountStatementForm={accountStatementForm} />
                    ) : (
                        <CorporateDropdown accountStatementForm={accountStatementForm} />
                    )}
                    <DateSelect accountStatementForm={accountStatementForm} />
                    <DirectionAndChain accountStatementForm={accountStatementForm} />
                </div>

                <div className="flex items-center justify-end mt-3 gap-x-3">
                    <button className="btn btn-outline" onClick={handleSubmit(submitForm)}>
                        submit
                    </button>
                    <button className="btn btn-outline" onClick={handleClear}>
                        clear
                    </button>
                </div>
            </div>
            <div className="mt-12">
                <h1 className="text-xl font-[500]">Total Fiat Values</h1>
                <LoaderContainer loading={isLoading}>
                    <div className="grid grid-cols-3 mt-4">
                        <div>
                            <p className="font-[400] text-[17px]">Fiat In GBP</p>
                            <p className="text-[20px] font-[500]">
                                <b>£</b>
                                {(data?.totalFiatGBP as number) || 0}
                            </p>
                        </div>
                        <div>
                            <p className="font-[400] text-[17px]">Fiat In USD</p>
                            <p className="text-[20px] font-[500]">
                                <b>$</b>
                                {(data?.totalFiatUSD as number) || 0}
                            </p>
                        </div>
                        <div>
                            <p className="font-[400] text-[17px]">Fiat In EUR</p>
                            <p className="text-[20px] font-[500]">
                                <b>€</b>
                                {(data?.totalFiatEUR as number) || 0}
                            </p>
                        </div>
                    </div>
                </LoaderContainer>
            </div>
            <div className="mt-12">
                <h1 className="text-sm font-[500]">{`Unblock Fee Values (A)`}</h1>
                <LoaderContainer loading={isLoading}>
                    <div className="grid grid-cols-3 mt-4">
                        <div>
                            <p className="font-[400] text-[15px]">Fee In GBP</p>
                            <p className="text-[18px] font-[500]">
                                <b>£</b>
                                {Number(data?.totalOverallFeesGBP) - Number(data?.totalMerchantFeesGBP) || 0}
                            </p>
                        </div>
                        <div>
                            <p className="font-[400] text-[15px]">Fee In USD</p>
                            <p className="text-[18px] font-[500]">
                                <b>$</b>
                                {Number(data?.totalOverallFeesUSD) - Number(data?.totalMerchantFeesUSD) || 0}
                            </p>
                        </div>
                        <div>
                            <p className="font-[400] text-[15px]">Fee In EUR</p>
                            <p className="text-[18px] font-[500]">
                                <b>€</b>
                                {Number(data?.totalOverallFeesEUR) - Number(data?.totalMerchantFeesEUR) || 0}
                            </p>
                        </div>
                    </div>
                </LoaderContainer>
            </div>
            <div className="mt-12">
                <h1 className="text-sm font-[500]">{`Shiga Fee Values (B)`}</h1>
                <LoaderContainer loading={isLoading}>
                    <div className="grid grid-cols-3 mt-4">
                        <div>
                            <p className="font-[400] text-[15px]">Fee In GBP</p>
                            <p className="text-[18px] font-[500]">
                                <b>£</b>
                                {(data?.totalMerchantFeesGBP as number) || 0}
                            </p>
                        </div>
                        <div>
                            <p className="font-[400] text-[15px]">Fee In USD</p>
                            <p className="text-[18px] font-[500]">
                                <b>$</b>
                                {(data?.totalMerchantFeesUSD as number) || 0}
                            </p>
                        </div>
                        <div>
                            <p className="font-[400] text-[15px]">Fee In EUR</p>
                            <p className="text-[18px] font-[500]">
                                <b>€</b>
                                {(data?.totalMerchantFeesEUR as number) || 0}
                            </p>
                        </div>
                    </div>
                </LoaderContainer>
            </div>
            <div className="mt-12">
                <h1 className="text-xl font-[500]">{`Total Fee Charged (A + B)`}</h1>
                <LoaderContainer loading={isLoading}>
                    <div className="grid grid-cols-3 mt-4">
                        <div>
                            <p className="font-[400] text-[17px]">Fee In GBP</p>
                            <p className="text-[20px] font-[500]">
                                <b>£</b>
                                {(data?.totalOverallFeesGBP as number) || 0}
                            </p>
                        </div>
                        <div>
                            <p className="font-[400] text-[17px]">Fee In USD</p>
                            <p className="text-[20px] font-[500]">
                                <b>$</b>
                                {(data?.totalOverallFeesUSD as number) || 0}{' '}
                            </p>
                        </div>
                        <div>
                            <p className="font-[400] text-[17px]">Fee In EUR</p>
                            <p className="text-[20px] font-[500]">
                                <b>€</b>
                                {(data?.totalOverallFeesEUR as number) || 0}{' '}
                            </p>
                        </div>
                    </div>
                </LoaderContainer>
            </div>
            <div className="mt-12">
                <LoaderContainer loading={isLoading}>
                    <StatementTransactionsList incomingqueryParams={queryParams} />
                </LoaderContainer>
            </div>
        </div>
    );
}

export default AccountsStatement;
