import React from 'react';
import LoaderContainer from '../loader';

interface ExchangeRate {
    exchange_rate: number;
}

interface ExchangeRates {
    [currency: string]: ExchangeRate;
}

interface Props {
    exchangeRates: ExchangeRates | undefined | null;
    isLoadingExchangeRates: boolean;
    shigaProcessesedExchangeRates: ExchangeRates | undefined | null;
    ublockProcessesedExchangeRates: ExchangeRates | undefined | null;
}

const UnblockExchangeRates: React.FC<Props> = ({
    exchangeRates,
    isLoadingExchangeRates,
    shigaProcessesedExchangeRates,
    ublockProcessesedExchangeRates
}) => {
    return (
        <div className="p-5 mb-10 border border-gray-200 rounded">
            <h1 className="mb-6 text-xl font-bold">Unblock Exchange Rates</h1>
            <LoaderContainer loading={isLoadingExchangeRates}>
                {exchangeRates && (
                    <div className="overflow-hidden bg-white rounded-lg shadow-md">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        Currency
                                    </th>
                                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        Exchange Rate
                                    </th>

                                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        Ublock Exchange Rate
                                    </th>
                                    <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        Shiga Exchange Rate
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {Object.entries(exchangeRates).map(([currency, { exchange_rate }]) => (
                                    <tr key={currency} className="hover:bg-gray-50">
                                        <td className="px-6 py-4 whitespace-nowrap">{currency}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">{exchange_rate}</td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {ublockProcessesedExchangeRates?.[currency]?.exchange_rate ?? 'N/A'}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            {shigaProcessesedExchangeRates?.[currency]?.exchange_rate ?? 'N/A'}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </LoaderContainer>
        </div>
    );
};

export default UnblockExchangeRates;
