import { format } from "date-fns"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import LoaderContainer from "../components/loader"
import Modal from "../components/modal"
import Pagination from "../components/pagination"
import Table from "../components/table"
import featureFlags from "../featureFlags"
import useModal from "../hooks/useModal"
import { trpc } from "../lib/trpc"
import DeleteCorporate from "../section/deleteCorporate"

const columns = [
  {
    label: "Date",
    key: "createdAt",
    format: (date: string) => format(date, "dd MMMM, yyyy"),
  },
  { label: "Legal Name", key: "legalName" },
  { label: "Registration Number", key: "registrationNumber" }, // Added registrationNumber
  {
    label: "Type of Business",
    key: "type",
    format: (type: string) => type?.replaceAll("_", " "),
  },
  { label: "Country", key: "country" },
  {
    label: "KYC status",
    key: "kycstatus",
    className: (status: string) => {
      switch (status) {
        case "SOFT_KYB_FAILED":
        case "HARD_KYB_FAILED":
        case "SUSPENDED":
          return "bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded"
        case "ACTIVE":
          return "bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded"
        default:
          return "bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded"
      }
    },
    format: (data: string) => data.replaceAll("_", " ").toUpperCase(),
  },
]

function Corporates() {
  const { isModalVisible, setModalClose, setModalOpen } = useModal()
  const [page, setPage] = useState(1)
  const navigate = useNavigate()
  const [corporateId, setCorporateId] = useState<number | null>(null)
  const corporateList = trpc.admin.listCoprates.useQuery(
    { page, limit: 25 },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )
  const { meta, data } = corporateList.data || {} // Destructuring meta once

  if (corporateList.isError) {
    return <h2>Error...</h2>
  }

  const onRowClick = (_: any, item: any) => {
    navigate(`/corporates/${item.id}`)
  }

  const onClickSave = (data: any) => {
    console.log({ data })
  }

  // For the pagination
  const [pageNumberLimit] = useState(5)
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)

  const changePage = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const incrementPage = () => {
    setPage(page + 1)
    if (page + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  const decrementPage = (): void | null => {
    setPage(page - 1)
    if ((page - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
    if (page - 1 === 0) {
      return null
    }
  }

  const handleDeleteModal = (id: string) => {
    setModalOpen(1)
    setCorporateId(Number(id))
  }



  return (
    <div className="mx-5">
      <Modal
        title="You are about to delete this Corporate?"
        showModal={isModalVisible(1)}
        closeModal={() => setModalClose(1)}
      >
       <DeleteCorporate corporateId={corporateId as number} setModalClose={() => setModalClose(1)}/>
      </Modal>

      {featureFlags.CREATE_CORPORATE && (
        <button
          disabled={!featureFlags.CREATE_CORPORATE}
          className="mb-5 btn btn-primary"
          onClick={() => navigate("/corporates/create")}
        >
          Create Corporate
        </button>
      )}
      <LoaderContainer loading={corporateList.isLoading}>
        <main className="px-4">
          <Table
            title="Corporates"
            onClickSave={onClickSave}
            onRowClick={onRowClick}
            columns={columns}
            data={data || []}
            clickable={true}
            enableActions={true}
            useDelete={true}
            onRowDelete={(data: any) => {
              handleDeleteModal(data.id)
            }}
          />
          <Pagination
            totalPages={meta?.totalPages}
            pageSize={meta?.limit}
            totalRecords={meta?.totalRecords}
            page={meta?.currentPage}
            changePage={changePage}
            incrementPage={incrementPage}
            decrementPage={decrementPage}
            pageNumberLimit={pageNumberLimit}
            minPageNumberLimit={minPageNumberLimit}
            maxPageNumberLimit={maxPageNumberLimit}
          />
        </main>
      </LoaderContainer>
    </div>
  )
}

export default Corporates
