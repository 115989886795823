import { useCallback, useState } from 'react';

function useModal() {
    const [modals, setModals] = useState<{ [modalId: string]: boolean }>({});

    const setModalOpen = useCallback((modalId: number) => {
        setModals(prevModals => ({
            ...prevModals,
            [modalId]: true,
        }));
    }, []);

    const setModalClose = useCallback((modalId: number) => {
        setModals(prevModals => ({
            ...prevModals,
            [modalId]: false,
        }));
    }, []);

    const isModalVisible = useCallback(
        (modalId: number) => {
            return !!modals[modalId];
        },
        [modals],
    );

    return {
        isModalVisible,
        setModalOpen,
        setModalClose,
    };
}

export default useModal;
