import { FC, useState } from 'react';

const CopySVG = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
<path d="M10.6668 8.62499V11.425C10.6668 13.7583 9.7335 14.6917 7.40016 14.6917H4.60016C2.26683 14.6917 1.3335 13.7583 1.3335 11.425V8.62499C1.3335 6.29166 2.26683 5.35832 4.60016 5.35832H7.40016C9.7335 5.35832 10.6668 6.29166 10.6668 8.62499Z" stroke="#94A7B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.6668 4.62499V7.42499C14.6668 9.75832 13.7335 10.6917 11.4002 10.6917H10.6668V8.62499C10.6668 6.29165 9.7335 5.35832 7.40016 5.35832H5.3335V4.62499C5.3335 2.29166 6.26683 1.35832 8.60016 1.35832H11.4002C13.7335 1.35832 14.6668 2.29166 14.6668 4.62499Z" stroke="#94A7B7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

interface CopyButtonProps {
    textToCopy: object[];
    extraClass: boolean;
}

const CopyButton: FC<CopyButtonProps> = ({ textToCopy, extraClass }) => {
    const [copied, setCopied] = useState<boolean>(false);
    const formatText = (data: any): string => {
        let formattedText = '';

        formattedText += `Wallet Address: ${data.walletAddress}\n`;
        formattedText += `Solana Wallet Address: ${data.solanaWalletAddress ?? 'None'}\n\n`;

        formattedText += `\n Portal Out wallets:\n`;
        data.unblockWallets?.forEach((wallet: any, index: number) => {
            formattedText += `${index + 1}. \n`;
            formattedText += `   - Network: ${wallet.network}\n`;
            formattedText += `   - Address: ${wallet.address}\n`;
            formattedText += `   - Active: ${wallet.active ? 'Yes' : 'No'}\n\n`;
        });

        formattedText += '\n Portal in bank accounts:\n';
        data.unblockBankAccts?.forEach((bankAcct: any, index: number) => {
            formattedText += `${index + 1}. \n`;
            formattedText += `   - Account Name: ${bankAcct.account_name}\n`;
            formattedText += `   - Account Number: ${bankAcct.account_number}\n`;
            formattedText += `   - Sort Code: ${bankAcct.sort_code}\n`;
            formattedText += `   - Currency: ${bankAcct.currency}\n`;
            formattedText += `   - BIC: ${bankAcct.bic}\n`;
            formattedText += `   - IBAN: ${bankAcct.iban}\n`;
            formattedText += `   - Bank Country: ${bankAcct.bank_country}\n`;
            formattedText += `   - Status: ${bankAcct.status}\n\n`;
        });
        formattedText += '\nDestination Bank Account Details:\n';
        data.unblockDestBankAcct?.forEach((acct: any, index: number) => {
            formattedText += `${index + 1}. \n`;
            formattedText += `   - Currency: ${acct.currency}\n`;
            formattedText += `   - Main Beneficiary: ${acct.main_beneficiary ? 'Yes' : 'No'}\n`;
            formattedText += `   - Account Number: ${acct.account_number ?? 'None'}\n`;
            formattedText += `   - Account Name: ${acct.account_name}\n`;
            formattedText += `   - Sort Code: ${acct.sort_code ?? 'None'}\n`;
            formattedText += `   - IBAN: ${acct.iban}\n`;
        });
        return formattedText;
    };

    const handleCopy = async () => {
        try {
            const text = formatText(textToCopy[0]);
            await navigator.clipboard.writeText(text);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset copied status after 2 seconds
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <div className="relative flex items-center cursor-pointer border-2 px-3 py-1 rounded-lg border-[#374151] text-[#374151] font-medium hover:bg-[#374151] hover:text-white">
            <button
                type="button"
                onClick={() => handleCopy()}
                className="flex items-center px-1 py-1 cursor-pointer space-x-2"
            >
                <p
                    className={`font-instrument-sans text-[15px] ${
                        extraClass ? 'font-semibold text-left' : 'font-medium'
                    }`}
                >
                    Copy data
                </p>
                <span dangerouslySetInnerHTML={{ __html: CopySVG }} className="" />
            </button>
            {copied && (
                <div className="absolute right-1 bottom-full mb-[2.5px] w-[4rem] p-1 text-xs text-center text-[#06AD00] bg-[#f6f6f6] rounded">
                    Copied!
                </div>
            )}
        </div>
    );
};

export default CopyButton;
