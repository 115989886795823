import { useState } from 'react';
import { trpc } from '../lib/trpc';
import { ExchangeRateView } from '../section/settings/exchangeRate';
import { FeesForm } from '../section/settings/feeForm';
import SupportedCountryForm from '../section/settings/SupportedCountryForm';

function Settings() {
    const [activeTab, setActiveTab] = useState('fees');
    const { data: merchantFees, isLoading: isLoadingMerchantFees } = trpc.adminSetting.getMerchantFees.useQuery();

    return (
        <div>
            <div className="border-b border-gray-200 dark:border-gray-700">
                <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                    <li className="me-2">
                        <button
                            onClick={() => setActiveTab('fees')}
                            className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group ${
                                activeTab === 'fees'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                            }`}
                        >
                            Fees
                        </button>
                    </li>

                    <li className="me-2">
                        <button
                            onClick={() => setActiveTab('webhooks')}
                            className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group ${
                                activeTab === 'webhooks'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                            }`}
                        >
                            Webhooks
                        </button>
                    </li>
                    <li className="me-2">
                        <button
                            onClick={() => setActiveTab('SupportedCountry')}
                            className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group ${
                                activeTab === 'webhooks'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                            }`}
                        >
                            Supported Countries
                        </button>
                    </li>
                    <li className="me-2">
                        <button
                            onClick={() => setActiveTab('ExchangeRate')}
                            className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group ${
                                activeTab === 'webhooks'
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                            }`}
                        >
                            Exchange Rate
                        </button>
                    </li>
                </ul>
            </div>

            <div className="p-4">
                {activeTab === 'fees' && (
                    <FeesForm isLoadingMerchantFees={isLoadingMerchantFees} merchantFees={merchantFees} />
                )}
                {activeTab === 'webhooks' && <div>Webhooks Content</div>}
                {activeTab === 'SupportedCountry' && <SupportedCountryForm />}
                {activeTab === 'ExchangeRate' && <ExchangeRateView />}
            </div>
        </div>
    );
}

export default Settings;
