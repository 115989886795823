import { differenceInMilliseconds } from 'date-fns';
import { Fragment, useMemo } from 'react';

export function SessionValidityChecker({ lastUpdated }: { lastUpdated: string | undefined }) {
    const isSessionIdStillValid = useMemo(() => {
        if (!lastUpdated) {
            return false;
        }

        const lastUpdatedDate = new Date(lastUpdated);

        const difference = differenceInMilliseconds(Date.now(), lastUpdatedDate);
        return difference < (4 * 60 * 60 * 1000);
    }, [lastUpdated]);

    return (
        <Fragment>
            {isSessionIdStillValid ? 
                <span className='text-primary text-sm ml-2 p-1'>(The session id is still valid)</span>
            : 
                <span className='text-red-600 text-sm ml-2 p-1'>(The session id has expired. Please trigger the OTP again)</span>
            }
        </Fragment>
    );
}
