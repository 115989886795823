import ReactDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import { accountStatementForm } from './type';

const formDateArray = [
    {
        label: 'Date From',
        name: 'dateFrom',
    },
    {
        label: 'Date To',
        name: 'dateTo',
    },
];

type inputName = 'dateFrom' | 'dateTo';

function DateSelect({ accountStatementForm }: { accountStatementForm: accountStatementForm }) {
    const { control } = accountStatementForm;
    return (
        
        <div className="flex items-center justify-between w-full gap-x-3">
            {formDateArray.map((item, index) => (
                <div key={`${item.label}-${index}`} className="w-full">
                    <label className="mb-3 text-[13px] font-[500]">{item.label}</label>
                    <div className="w-full border border-[#dfe2e2f2] px-3 py-2 focus:outline-none focus:ring-0 focus:border-[#374151] rounded-md">
                        <Controller
                            control={control}
                            name={item.name as inputName}
                            render={({ field: { onChange, value, ...fieldProps } }) => (
                                <ReactDatePicker
                                    {...fieldProps}
                                    dateFormat="yyyy/MM/dd"
                                    placeholderText="Select date"
                                    className="w-full focus:outline-none focus:border-none"
                                    selected={value ? new Date(value) : undefined}
                                    maxDate={item.name === 'dateTo' ? new Date() : undefined}
                                    onChange={(date: any) => onChange(date)} // Ensure that the date is handled correctly
                                />
                            )}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DateSelect;
