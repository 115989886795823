import { zodResolver } from '@hookform/resolvers/zod';
import { countryISOMap } from '@repo/constants';
import { Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { twMerge } from 'tailwind-merge';
import * as z from 'zod';
import Alert, { Event } from '../components/alert';
import ErrorInput from '../components/input';
import { trpc } from '../lib/trpc';
import { isEvmAddress } from '../utils/isEvmAddress';
import { isSolanaAddress } from '../utils/isSolanaAddress';
import { CreateComponent } from './create';

const createCorporatechema = z.object({
    legalName: z.string().trim().min(1, 'legal name is required'),
    registrationNumber: z.string().trim(),
    walletAddress: z
        .string()
        .trim()
        .optional()
        .refine(value => !value || isEvmAddress(value), {
            message: 'Invalid Ethereum address',
        }),
    targetSolanaAddress: z.preprocess(
        value => {
            if ((typeof value === 'string' && value.trim() === '') || value === undefined || value == null) {
                return undefined;
            }
            return value;
        },
        z
            .string()
            .trim()
            .optional()
            .refine(value => value === undefined || isSolanaAddress(value), {
                message: 'Invalid Solana address',
            }),
    ),
    type: z.enum(
        [
            'LIMITED_LIABILITY',
            'SOLE_TRADER',
            'PARTNERSHIP',
            'PUBLIC_LIMITED_COMPANY',
            'JOINT_STOCK_COMPANY',
            'CHARITY',
            'DECENTRALISED_AUTONOMOUS_ORGANISATION',
        ],
        {
            errorMap: (_issue, _ctx) => ({
                message: `Select any of LIMITED LIABILITY' , 'SOLE TRADER' , 'PARTNERSHIP' , 'PUBLIC LIMITED COMPANY' , 'JOINT STOCK COMPANY' , 'CHARITY' , 'DECENTRALISED AUTONOMOUS ORGANISATION'`,
            }),
        },
    ),
    contactDetails: z.object({
        name: z.string().trim().min(1, 'Name number is required'),
        phone: z
            .string()
            .trim()
            .min(1, 'Phone number is required')
            .regex(/^\+\d{1,3}\d{1,14}$/, 'Please enter a valid phone number starting with a country code'),
        email: z.string().email().min(1, 'Email number is required'),
    }),
    registeredAddress: z.object({
        addressLine1: z.string().trim().min(1, 'Address line 1 is required'),
        postcode: z.string().trim().min(1, 'Postcode is required'),
        city: z.string().trim().min(1, 'City is required'),
        country: z
            .string()
            .trim()
            .length(2, 'Country must be exactly 2 characters long')
            .regex(/^[A-Za-z]{2}$/, 'Invalid country code format'),
    }),
});

type CreateCorporatechema = z.infer<typeof createCorporatechema>;

function CreateCoporate({ useTitle = true, sectionClassName, btnContainerClassName, closeModal }: CreateComponent) {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [alert, setAlert] = useState<{ message: string; type: Event } | null>(null);
    const { isLoading, mutate } = trpc.corporate.createCorporate.useMutation({
        onError: res => {
            setAlert({ message: res.message, type: 'error' });
        },
        onSuccess: (insertedId: number) => {
            reset();
            setAlert({ message: 'Corporate successfully created', type: 'success' });
            if (pathname === '/home') {
                closeModal?.();
            } else {
                navigate(`/corporates/${insertedId}`);
            }
        },
    });

    const {
        reset,
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<CreateCorporatechema>({
        resolver: zodResolver(createCorporatechema),
    });

    const onSubmit = (data: CreateCorporatechema) => {
        mutate({ ...data, country: data.registeredAddress.country });
    };
    const redirect = () => {
        navigate('/corporates');
    };

    const countryOptions = Object.entries(countryISOMap).map(([key, value]) => ({
        value: key,
        label: `${value} - ${key}`,
    }));

    return (
        <Fragment>
            {alert && <Alert classes={'w-[20%] ml-auto'} message={alert.message} event={alert.type} />}
            {useTitle && (
                <h1 className="block text-center  text-3xl font-bold text-dark mt-5 mb-3">CREATE CORPORATE</h1>
            )}
            <section className={twMerge('flex pt-10 justify-center', sectionClassName)}>
                <form className="w-[90%]" onSubmit={handleSubmit(onSubmit)}>
                    <div className="bg-slate-500 py-2 px-5 mb-5">
                        <p className="block  text-xl font-bold text-white">Corporate Information</p>
                    </div>
                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                        <div>
                            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Legal name
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.legalName?.message,
                                }}
                                register={register('legalName')}
                                type="text"
                                id="first_name"
                                className={`bg-gray-50 border ${
                                    errors.legalName ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Registration Number
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.registrationNumber?.message,
                                }}
                                register={register('registrationNumber')}
                                type="text"
                                id="last_name"
                                className={`bg-gray-50 border ${
                                    errors.registrationNumber ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label htmlFor="walletAddress" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Ethereum Wallet Address
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.walletAddress?.message,
                                }}
                                register={register('walletAddress')}
                                type="text"
                                id="targetAddress"
                                placeholder="0x..."
                                className={`bg-gray-50 border ${
                                    errors.walletAddress ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="targetSolanaAddress"
                                className="block mb-2 text-sm font-medium text-gray-900 "
                            >
                                Solana Wallet Address
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.targetSolanaAddress?.message,
                                }}
                                register={register('targetSolanaAddress')}
                                type="text"
                                id="targetSolanaAddress"
                                placeholder="0x57..."
                                className={`bg-gray-50 border ${
                                    errors.targetSolanaAddress ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>

                        <div>
                            <label htmlFor="sourceOfFunds" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Type{' '}
                            </label>

                            <select
                                id="sourceOfFunds"
                                {...register('type')}
                                className={`bg-gray-50 border ${
                                    errors.type ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            >
                                <option disabled value={''} selected>
                                    select
                                </option>
                                <option value="LIMITED_LIABILITY">LIMITED LIABILITY</option>
                                <option value="SOLE_TRADER">SOLE TRADER</option>
                                <option value="PARTNERSHIP">PARTNERSHIP</option>
                                <option value="PUBLIC_LIMITED_COMPANY">PUBLIC LIMITED COMPANY</option>
                                <option value="JOINT_STOCK_COMPANY">JOINT STOCK COMPANY</option>
                                <option value="CHARITY">CHARITY</option>
                                <option value="DECENTRALISED_AUTONOMOUS_ORGANISATION">
                                    DECENTRALISED AUTONOMOUS ORGANISATION
                                </option>
                            </select>
                            {errors.type && <span className="text-red-500 text-sm">{errors.type.message}</span>}
                        </div>
                    </div>
                    <hr className="my-10" />
                    <div className="bg-slate-500 py-2 px-5 mb-5">
                        <p className="block  text-xl font-bold text-white">Contact Details </p>
                    </div>
                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                        <div>
                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Name
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.contactDetails?.name?.message,
                                }}
                                register={register('contactDetails.name')}
                                type="text"
                                id="name"
                                className={`bg-gray-50 border ${
                                    errors?.contactDetails?.name ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Email
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors?.contactDetails?.email?.message,
                                }}
                                register={register('contactDetails.email')}
                                type="text"
                                id="email"
                                placeholder="user@example.com"
                                className={`bg-gray-50 border ${
                                    errors.contactDetails?.email ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Phone number
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors?.contactDetails?.phone?.message,
                                }}
                                register={register('contactDetails.phone')}
                                type="text"
                                id="Phone"
                                placeholder="e.g +442038857874"
                                className={`bg-gray-50 border ${
                                    errors?.contactDetails?.phone ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                    </div>
                    <hr className="my-10" />
                    <div className="bg-slate-500 py-2 px-5 mb-5">
                        <p className="block  text-xl font-bold text-white">Address </p>
                    </div>
                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                        <div>
                            <label htmlFor="addressLine1" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Address Line
                            </label>

                            <ErrorInput
                                error={{
                                    message: errors.registeredAddress?.addressLine1?.message,
                                }}
                                register={register('registeredAddress.addressLine1')}
                                type="text"
                                id="addressLine1"
                                className={`bg-gray-50 border ${
                                    errors.registeredAddress?.addressLine1 ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>

                        <div>
                            <label htmlFor="postcode" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Post Code
                            </label>

                            <ErrorInput
                                error={{
                                    message: errors.registeredAddress?.postcode?.message,
                                }}
                                register={register('registeredAddress.postcode')}
                                type="text"
                                id="postcode"
                                className={`bg-gray-50 border ${
                                    errors.registeredAddress?.postcode ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900 ">
                                City
                            </label>

                            <ErrorInput
                                error={{
                                    message: errors.registeredAddress?.city?.message,
                                }}
                                register={register('registeredAddress.city')}
                                type="text"
                                id="city"
                                className={`bg-gray-50 border ${
                                    errors.registeredAddress?.city ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label htmlFor="country" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Country (2-letter code, ISO 3166-1 alfa-2 )
                            </label>
                            <Controller
                                name="registeredAddress.country"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        name={field.name}
                                        ref={field.ref}
                                        isClearable={true}
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                        value={countryOptions.find(c => c.value === field.value)}
                                        onChange={(val: any) => field.onChange(val.value)}
                                        options={countryOptions}
                                        className={`bg-gray-50 border ${
                                            errors.registeredAddress?.country ? 'border-red-500' : 'border-gray-300'
                                        } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                    />
                                )}
                            />
                            {errors?.registeredAddress?.country && (
                                <span className="text-red-500 text-sm">
                                    {errors?.registeredAddress?.country.message}
                                </span>
                            )}{' '}
                        </div>
                    </div>
                    <div className={twMerge('flex justify-start mt-10', btnContainerClassName)}>
                        <button
                            onClick={redirect}
                            className=" bg-white mr-5 btn btn-outline focus:ring-4 focus:outline-none font-medium rounded-lg text-sm lg:w-48 sm:w-auto px-5 py-2.5 text-center"
                        >
                            Cancel
                        </button>
                        <button
                            disabled={isLoading}
                            type="submit"
                            className="text-white btn bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm lg:w-48 sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            {isLoading ? 'Submitting' : 'Submit'}{' '}
                        </button>
                    </div>
                </form>
            </section>
        </Fragment>
    );
}

export default CreateCoporate;
