import { format } from 'date-fns';
import { trpc } from '../../lib/trpc';
import { BlockText, SuccessBlockText } from './blockText';

export interface TransactionType {
    id: number;
    status: string;
    corporateId: number | null;
    userId: number | null;
    direction: string;
    vendorCustomerId: string | null;
    vendorTransactionId: string | null;
    vendorStatus: string;
    vendorName: string | null;
    data: Record<string, any> | null;
    transactionHash: string;
    exchangeRate: number | null;
    currencyFiat: string | null;
    currencyCrypto: string | null;
    amountFiat: number | null;
    amountCrypto: number | null;
    chain: string | null;
    fees: string | null;
    merchantFee: string | null;
    updatedAt: Date;
    createdAt: Date;
}

const slackChannel = import.meta.env.VITE_SLACK_CHANNEL;

function ReportTransaction({ dataReport, setModalClose }: { dataReport: TransactionType; setModalClose: () => void }) {
    const { mutate, isLoading } = trpc.adminTransaction.reportTransaction.useMutation();

    const handleSubmitReport = () => {
        const reportSatus =
            dataReport?.status === 'FAILED' ? 'failed' : dataReport?.status === 'IN_PROGRESS' ? 'in progress' : '';

        mutate({
            channel: slackChannel as string,
            blocks:
                dataReport?.status === 'SUCCESS'
                    ? SuccessBlockText({
                          chain: dataReport?.chain as string,
                          direction: dataReport?.direction,
                          transactionUuid: dataReport?.data?.transactionUuid as string,
                          timeStamp: format(dataReport?.data?.timestamp, 'yyyy-MM-dd HH:mm:ss'),
                          amountCrypto: dataReport?.amountCrypto as number,
                          amountFiat: dataReport?.amountFiat as number,
                      })
                    : BlockText({
                          status: reportSatus,
                          chain: dataReport?.chain as string,
                          direction: dataReport?.direction,
                          transactionUuid: dataReport?.data?.transactionUuid as string,
                          timeStamp: format(dataReport?.data?.timestamp, 'yyyy-MM-dd HH:mm:ss'),
                          amountCrypto: dataReport?.amountCrypto as number,
                          amountFiat: dataReport?.amountFiat as number,
                      }),
        });
    };

    return (
        <>
            <div className="grid w-full grid-cols-2">
                <div className="text-[16px] font-semibold flex flex-col gap-y-4">
                    <p>Chain:</p>
                    <p>Direction:</p>
                    <p>Transaction UUID:</p>
                    <p>Crypto Amount:</p>
                    <p>Fiat Amount</p>
                    <p>Status:</p>
                </div>
                <div className="text-[16px] font-semibold flex flex-col gap-y-4">
                    <p>{dataReport?.data?.chain}</p>
                    <p>{dataReport?.data?.direction}</p>
                    <p>{dataReport?.data?.transactionUuid}</p>
                    <p>{dataReport?.amountCrypto}</p>
                    <p>{dataReport?.amountFiat}</p>
                    <p>{dataReport?.status}</p>
                </div>
            </div>
            <div className="flex items-center justify-end p-4 mt-5 rounded-b md:p-5">
                <button
                    type="submit"
                    className="mr-5 btn btn-outline"
                    onClick={handleSubmitReport}
                    disabled={isLoading}
                >
                    {isLoading ? 'Reporting...' : 'Submit'}
                </button>
                <button type="button" onClick={setModalClose} className="btn btn-outline">
                    Cancel
                </button>
            </div>
        </>
    );
}

export default ReportTransaction;
