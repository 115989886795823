/* eslint-disable @typescript-eslint/no-explicit-any */
import { Controller, UseFormReturn } from 'react-hook-form';

const options = [
    {
        label: 'About Shiga',
        value: 'aboutShiga',
    },
    {
        label: 'Key Benefits',
        value: 'keyBenefits',
    },
    {
        label: 'How to use Shiga',
        value: 'howToUseShiga',
    },
    {
        label: 'Contact & Support',
        value: 'contactSupport',
    },
];

type formFaq = UseFormReturn<
    {
        section: string;
        question: string;
        answer: string;
    },
    any,
    undefined
>;
const SelectSectionType = ({ Form, handleSetIndex }: { Form: formFaq; handleSetIndex: () => void }) => {
    const { control, watch } = Form;
    const watchedValue = watch('section');
    return (
        <div className="flex w-full gap-4">
            {options.map(type => {
                return (
                    <div key={type.label} className="m-0 w-fullitems-center">
                        <div className="flex items-center justify-between w-full cursor-pointer rounded-xl gap-x-2">
                            <div>
                                <label htmlFor={type.value} className="text-[13px] font-[400] cursor-pointer">
                                    {type.label}
                                </label>
                            </div>
                            <div>
                                <Controller
                                    name={'section'}
                                    control={control}
                                    render={({ field: { onChange } }) => (
                                        <div className="text-[13px] flex gap-[5px] items-center cursor-pointer">
                                            <input
                                                type="checkbox"
                                                name={'idType'}
                                                checked={watchedValue === type.value}
                                                id={type.value}
                                                value={type.value}
                                                className={`border-[#0e2038] accent-[#0e2038] cursor-pointer`}
                                                onChange={e => {
                                                    onChange(e.target.checked ? e.target.value : '');
                                                    handleSetIndex();
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default SelectSectionType;
