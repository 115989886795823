import { decode } from 'bs58';

export function isSolanaAddress(address: string) {
    try {
        const decoded = decode(address);
        return decoded.length === 32;
    } catch (e) {
        return false;
    }
}
