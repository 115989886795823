import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import { Event } from '../../components/alert';
import { configs } from '../../config';
import { trpc } from '../../lib/trpc';


export interface dataMessageType {
    id: number;
    name: string | null;
    issue: string | null;
    createdAt: string;
    status: string | null;
    message: string | null;
    updatedAt: string;
    userId: number | null;
    companyId: number | null;
    uploadImage: string | null;
    resolvedAt: string | null;
    resolvedByName: string | null;
    resolveMessage: string | null;
    resolvedById: number | null;
    resolution: string | null;
}

function ViewMessageContact ({
    dataMessage,
    setModalClose,
    showAlert,

}: {
    dataMessage: dataMessageType;
    setModalClose: () => void;
    showAlert?: (message: string, type: Event, delay?: number) => void;

}) {
    const [selectStatus, setSelectStatus] = useState('');
    const utils = trpc.useUtils();

    const { mutate, isLoading } = trpc.contact.updateContactMessage.useMutation({
        onSuccess: (res) => {
            utils.contact.getAllContactUs.invalidate();
            showAlert && showAlert(res?.message, 'success');
            setModalClose();
        },
    }

    );

    const handleSelectStatus = (status: string) => {
        setSelectStatus(status);
    };

    const handleSubmitReport = () => {
        mutate({
            id: dataMessage?.id,
            status: selectStatus,
        });
        setSelectStatus('');
    };

    useEffect(() => {
        if (selectStatus) {
            handleSubmitReport();
        }
    }, [selectStatus]);

    return (
        <>
            <div className="flex justify-end gap-y-4">
                <label htmlFor="status">
                    <p className="text-[16px] font-semibold">Change Status after resolution:</p>
                    <select
                        value={
                            selectStatus
                        }
                        onChange={(e) => {
                            handleSelectStatus(e.target.value)
                        }}
                        className="w-full px-3 py-2 border border-gray-700 rounded-md outline-none cursor-pointer"
                    >
                        <option value="" disabled>       {isLoading ? 'Changing...' : 'Select Status'}
                        </option>
                        <option value="READ">Read</option>
                        <option value="UNREAD">Unread</option>
                    </select>
                </label>
            </div>
            <div className="grid w-full grid-cols-2">
                <div className="text-[16px] font-semibold flex flex-col gap-y-4">
                    <p>Name:</p>
                    <p>Issue:</p>
                    <p>Sent on:</p>
                    <p>Status:</p>
                </div>
                <div className="text-[16px] font-semibold flex flex-col gap-y-4">
                    <p>{dataMessage?.name || 'N/A'}</p>
                    <p>{dataMessage?.issue || 'N/A'}</p>
                    <p>{dataMessage?.createdAt && format(dataMessage?.createdAt, 'yyyy-MM-dd HH:mm:ss') || 'N/A'}</p>
                    <p>{dataMessage?.status || 'N/A'}</p>
                </div>
            </div>

            <div>
                <p className="text-[16px] font-semibold">Message:</p>
                <textarea
                    className="w-full px-3 py-2 border border-gray-700 rounded-md outline-none cursor-pointer"
                    maxLength={1200}
                    rows={10}
                    value={dataMessage?.message || ''}
                    disabled
                    style={{ resize: 'none' }}
                />
            </div>

            <p className='text-[16px] font-semibold'>Image Attachment:</p>
            <Zoom>
                <img
                    src={
                        (dataMessage?.uploadImage || dataMessage.uploadImage !== '' || dataMessage?.uploadImage !== null) ? configs.s3BaseUrl + dataMessage?.uploadImage as string :
                            'https://via.placeholder.com/300'
                    }
                    alt="Image 1"
                    className="w-full h-full rounded-md"
                />{' '}
            </Zoom>

            <div className="flex items-center justify-end p-4 mt-5 rounded-b md:p-5">
                <button type="button" disabled={isLoading} onClick={setModalClose} className="btn btn-outline">
                    Cancel
                </button>
            </div>
        </>
    );
}

export default ViewMessageContact;
