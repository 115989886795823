import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Alert from '../components/alert';
import Modal from '../components/modal';
import useAlert from '../hooks/useAlert';
import useModal from '../hooks/useModal';
import { trpc } from '../lib/trpc';
import FaqForm from '../section/faq';
import SelectSectionType from '../section/faq/selectSectionType';
import { RefreshIcon } from '../section/settings/exchangeRate';

export type FaqData = {
    question: string;
    answer: string;
    section: string;
    show: boolean;
    id: number;
    created_at: string;
    updated_at: string;
};

type FaqStateObject = {
    activeIndex: number | null;
    itemsToShow: number;
    searchTitle: string;
    filterSection: string;
    actionToPerform: 'create' | 'update';
};

function Faqs () {
    const utils = trpc.useUtils();

    const formFaq = useForm({
        defaultValues: {
            section: '',
            question: '',
            answer: '',
        },
    });
    const { isModalVisible, setModalClose, setModalOpen } = useModal();
    const { alert, showAlert } = useAlert();
    const [dataArray, setDataArray] = useState<FaqData[]>([]);
    const [dataSelected, setDataSelected] = useState<FaqData | null>(null);
    const [faqStateObject, setFaqStateObject] = useState<FaqStateObject>({
        activeIndex: 0,
        itemsToShow: 8,
        searchTitle: '',
        filterSection: '',
        actionToPerform: 'create',
    });
    const { data, isLoading, refetch, isRefetching } = trpc.faqs.getShigaFaq.useQuery<FaqData[]>({
        section: formFaq.getValues('section') || '',
    });

    // Slice the data based on the itemsToShow value
    const slicedData = data?.slice(0, faqStateObject.itemsToShow) as FaqData[];

    // Function to handle "Load More" button click
    const handleLoadMore = () => {
        setFaqStateObject(prevState => ({
            ...prevState,
            itemsToShow: prevState.itemsToShow + 5,
        }));
    };

    const toggleAccordion = (index: number) => {
        setFaqStateObject(prevState => ({
            ...prevState,
            activeIndex: index === faqStateObject.activeIndex ? null : index,
        }));
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setFaqStateObject(prevState => ({
            ...prevState,
            itemsToShow: 8,
            activeIndex: 0,
        }));
    };

    const handleChangeValue = (title: string) => {
        setFaqStateObject(prevState => ({
            ...prevState,
            searchTitle: title,
        }));
    };

    const clearSearch = () => {
        setFaqStateObject(prevState => ({
            ...prevState,
            searchTitle: '',
        }));
    };

    useEffect(() => {
        let slicedData = data?.slice(0, faqStateObject.itemsToShow) as FaqData[];
        if (faqStateObject.searchTitle !== '') {
            slicedData = data?.filter(
                item => item?.question?.toLowerCase()?.includes(faqStateObject.searchTitle?.toLowerCase()),
            ) as FaqData[];
        }

        setDataArray(slicedData);
    }, [faqStateObject, data]);

    const { mutate, isLoading: isLoadingDelete } = trpc.faqs.deleteShigaFaq.useMutation({
        onSuccess: res => {
            if (res) {
                showAlert && showAlert(res?.message, 'success');
                setModalClose(2);
                utils.faqs.getShigaFaq.refetch();
            }
        },
        onError: error => {
            showAlert?.(error.message, 'error');
        },
    });

    const deleteFaq = (id: number) => {
        mutate({ id: id });
    };

    return (
        <div className="mx-5">
            {alert && <Alert classes={'w-[20%] ml-auto'} message={alert.message} event={alert.type} />}

            <Modal
                title="Add FAQ "
                showModal={isModalVisible(1)}
                closeModal={() => {
                    setModalClose(1);
                    setDataSelected(null);
                }}
            >
                <FaqForm
                    setModalClose={() => {
                        setModalClose(1);
                        setDataSelected(null);
                    }}
                    showAlert={showAlert}
                    incomingData={dataSelected as FaqData}
                    formFaq={formFaq}
                    action={faqStateObject?.actionToPerform}
                />
            </Modal>
            <Modal
                title="Delete FAQ "
                showModal={isModalVisible(2)}
                closeModal={() => {
                    setModalClose(2);
                    setDataSelected(null);
                }}
            >
                <div className="text-cente">
                    <p className="text-center">
                        Are you sure you want to permanently delete this FAQ with title:{' '}
                        <b>{dataSelected?.question} </b> . This is an irreversible action.
                    </p>
                    <div className="flex items-center justify-end p-4 rounded-b md:p-5">
                        <button
                            type="submit"
                            className="mr-5 btn btn-outline"
                            onClick={() => deleteFaq(dataSelected?.id as number)}
                        >
                            {isLoadingDelete ? 'Deleting...' : 'Delete'}
                        </button>
                        <button
                            type="button"
                            onClick={() => {
                                setModalClose(2);
                                setDataSelected(null);
                            }}
                            className="btn btn-outline"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>

            <div className="flex items-center justify-between mb-4">
                <h1 className="text-xl">
                    <span className="font-bold">FAQ</span>
                </h1>
                <div className="flex gap-2">
                    <button
                        onClick={() => refetch()}
                        className={`
                           bg-white text-[#374151] px-3 py-2 border-none  font-medium cursor-pointer flex gap-x-2 items-center`}
                    >
                        Refresh
                        <RefreshIcon isRefetching={isLoading || isRefetching} />
                    </button>
                    <button
                        onClick={() => {
                            setModalOpen(1)
                            setFaqStateObject(prevState => ({
                                ...prevState,
                                actionToPerform: 'create',
                            }))
                        }}
                        className={`
                           bg-white text-[#374151] px-3 py-2 border-2 border-solid rounded-lg border-[#374151] font-medium hover:bg-[#374151] hover:text-white cursor-pointer`}
                    >
                        Create FAQ
                    </button>
                </div>
            </div>
            <div className="flex justify-center mt-[30px]">
                <div>
                    <div className="flex items-center gap-[20px] sm:gap-[0px] h-[45px] w-[600px] py-1 px-3 rounded-[8px] border-[1px] ring-offset-gray-800 focus:ring-2 border-gray-600">
                        <input
                            type="text"
                            value={faqStateObject.searchTitle}
                            onChange={e => handleChangeValue(e.target.value)}
                            placeholder="Search FAQ with any keyword..."
                            className="w-full h-full text-[18px] sm:text-[14px] outline-none bg-transparent border-none focus:outline-none"
                        />
                        <p
                            onClick={clearSearch}
                            className="font-secondary font-[400] text-darky-col rounded-[45px] py-1 cursor-pointer text-[13px]"
                        >
                            X
                        </p>
                    </div>
                    <div className="flex justify-center">
                        <div className="mt-7">
                            <label
                                htmlFor="section"
                                className="text-[13px] font-[500] cursor-pointer flex justify-center"
                            >
                                Filter By:
                            </label>
                            <SelectSectionType Form={formFaq} handleSetIndex={() => {
                                setFaqStateObject(prev => ({ ...prev, activeIndex: 0 }));
                            }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className=" font-primary flex justify-center py-[40px] px-[64px] md:px-0">
                <div className="w-full">
                    <>
                        <div className="space-y-[10px]">
                            {isLoading ? (
                                <p className="text-center h-[500px] flex flex-col justify-center text-[#505660] font-[500] text-[18px] leading-[27px]">
                                    Loading faq content list ...
                                </p>
                            ) : dataArray?.length === 0 ? (
                                <p className="text-center h-[500px] flex flex-col justify-center text-[#505660] font-[500] text-[18px] leading-[27px]">
                                    No Result Found for <b className="">{faqStateObject.searchTitle}</b>
                                </p>
                            ) : (
                                dataArray?.map((item, index) => (
                                    <div key={index}>
                                        {faqStateObject.activeIndex === index && (
                                            <div className="flex justify-end mt-4">
                                                <div className="flex items-center gap-x-3">
                                                    <p
                                                        onClick={() => {
                                                            setModalOpen(1);
                                                            setDataSelected(item);
                                                            setFaqStateObject(prevState => ({
                                                                ...prevState,
                                                                actionToPerform: 'update',
                                                            }))
                                                        }}
                                                        className="text-green-400 cursor-pointer"
                                                    >
                                                        Edit
                                                    </p>
                                                    <p
                                                        className="text-red-400 cursor-pointer"
                                                        onClick={() => {
                                                            setModalOpen(2);
                                                            setDataSelected(item);
                                                        }}
                                                    >
                                                        {' '}
                                                        Delete
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            key={index}
                                            className={` py-[24px] px-[40px] w-full h-full md:p-[24px] cursor-pointer bg-[#FFFFFF] rounded-[8px] ${faqStateObject.activeIndex === index
                                                    ? ' bg-[#f4fcff] pb-[40px] ease-in duration-500'
                                                    : 'border-[1px] border-[#E6E6E6] ease-out duration-500'
                                                }`}
                                        >
                                            <div
                                                className="flex items-center justify-between cursor-pointer"
                                                onClick={() => toggleAccordion(index)}
                                            >
                                                <h3 className="font-[700] font-secondary text-[20px] leading-[27px] text-[#101248] md:text-[16px] md:leading-[22px]">
                                                    {item.question}
                                                </h3>
                                                <span className="transition-transform transform">
                                                    {faqStateObject.activeIndex === index ? (
                                                        <div className="">
                                                            <img src="/assets/svg/closeFaq.svg" alt="-" />
                                                        </div>
                                                    ) : (
                                                        <div className="">
                                                            <img src="/assets/svg/openFaq.svg" alt="+" />
                                                        </div>
                                                    )}
                                                </span>
                                            </div>
                                            {faqStateObject.activeIndex === index && (
                                                <div className=" mt-[20px]">
                                                    <p className="font-[400] font-primary text-[16px] leading-[24px] text-[#505660] ease-in-out duration-800">
                                                        {item?.answer}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>

                        {slicedData?.length === data?.length || dataArray?.length === 0 ? null : (
                            <div className="flex flex-col items-center justify-center mt-[30px]">
                                <button
                                    onClick={handleLoadMore}
                                    className="py-[12px] flex justify-center items-center gap-[10px] w-full text-[16px] font-[500] leading-[24px] border-[1px] border-[#2F37EF] text-[#2F37EF] hover:bg-[#0DB4D7] hover:text-white hover:border-[#0DB4D7] rounded-[8px] font-[inherit] tracking-normal"
                                >
                                    Load more
                                </button>
                            </div>
                        )}
                    </>
                    {dataArray?.length === 0 || dataArray?.length <= 5 ? null : (
                        <div className="flex flex-col items-center justify-center mt-[30px]">
                            <p
                                onClick={scrollToTop}
                                className=" cursor-pointer text-[#2F37EF] text-[16px] font-[500] leading-[24px]"
                            >
                                Go back to the top
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Faqs;
