import { PDFDownloadLink } from '@react-pdf/renderer';
import ShigaDocument from './bankPdfComponent';

export const BankSvg = `<svg width="17px" height="14px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.37 2.14984L21.37 5.74982C21.72 5.88982 22 6.30981 22 6.67981V9.99982C22 10.5498 21.55 10.9998 21 10.9998H3C2.45 10.9998 2 10.5498 2 9.99982V6.67981C2 6.30981 2.28 5.88982 2.63 5.74982L11.63 2.14984C11.83 2.06984 12.17 2.06984 12.37 2.14984Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22 22H2V19C2 18.45 2.45 18 3 18H21C21.55 18 22 18.45 22 19V22Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path opacity="0.4" d="M4 18V11" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path opacity="0.4" d="M8 18V11" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path opacity="0.4" d="M12 18V11" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path opacity="0.4" d="M16 18V11" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path opacity="0.4" d="M20 18V11" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1 22H23" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path opacity="0.4" d="M12 8.5C12.8284 8.5 13.5 7.82843 13.5 7C13.5 6.17157 12.8284 5.5 12 5.5C11.1716 5.5 10.5 6.17157 10.5 7C10.5 7.82843 11.1716 8.5 12 8.5Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

type BankAccount = {
    uuid: string;
    currency: string;
    bic?: string; // Optional because it is present in some objects but not others
    iban?: string; // Optional because it is present in some objects but not others
    account_number?: string; // Optional because it is present in some objects but not others
    sort_code?: string; // Optional because it is present in some objects but not others
    bank_country: string;
    status: string;
};

const BankDownloadButton = ({
    bankData,
    fullName,
    firstName,
}: {
    bankData: BankAccount[] | undefined;
    fullName: string;
    firstName: string;
}) => {
    const eurData = bankData?.filter(item => item.currency === 'EUR');
    const gbpData = bankData?.filter(item => item.currency === 'GBP');

    return (
        <PDFDownloadLink
            document={
                <ShigaDocument
                    customerName={(firstName as string) || 'Ubo'}
                    fullName={fullName as string}
                    eurDetails={{
                        accountNumber: eurData && (eurData[0]?.iban as string),
                    }}
                    gbpDetails={{
                        sortCode: gbpData && (gbpData[0]?.sort_code as string),
                        accountNumber: gbpData && (gbpData[0]?.account_number as string),
                    }}
                />
            }
            fileName={`${fullName}-Shiga-Virtual-Fiat-Accounts.pdf`}
            style={{
                textDecoration: 'none',
                // padding: "10px 20px",
                color: '#000000',
                // backgroundColor: "#007bff",
                borderRadius: '5px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
                cursor: 'pointer',
            }}
        >
            <span dangerouslySetInnerHTML={{ __html: BankSvg }} className="h-[16px] w-[16px]" />
            Download Bank Account Details
        </PDFDownloadLink>
    );
};

export default BankDownloadButton;
