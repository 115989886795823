import { useState } from 'react';
import { Event } from '../components/alert';

const useAlert = (initialDelay = 10000) => {
    const [alert, setAlert] = useState<{ message: string; type: Event } | null>(null);

    const showAlert = (message: string, type: Event, delay = initialDelay) => {
        setAlert({ message, type });
        setTimeout(() => {
            setAlert(null);
        }, delay);
    };

    return { alert, showAlert };
};

export default useAlert;
