import { useAuth0 } from '@auth0/auth0-react';

function NavBar() {
    const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: '/home',
            },
            authorizationParams: {
                prompt: 'login',
            },
        });
    };

    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: import.meta.env.VITE_AUTH0_CALLBACK_URL,
            },
        });
        localStorage.removeItem('token');
    };
    return (
        <nav className="navbar bg-primary-blue mb-10 fixed top-0 z-50 w-full border-b border-gray-200 text-white">
            <div className="navbar-start">
                {/* <div className="dropdown">
                    <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h8m-8 6h16"
                            />
                        </svg>
                    </div>
                </div> */}
                <a className="btn btn-ghost text-2xl font-light">Shiga Admin</a>
            </div>
            <div className="navbar-end">
                {user && <p>Logged in as {user.email}</p>}{' '}
                <button className="ml-5 btn btn-outline" onClick={isAuthenticated ? handleLogout : handleLogin}>
                    Log{isAuthenticated ? 'out' : 'in'}
                </button>
            </div>
        </nav>
    );
}

export default NavBar;
