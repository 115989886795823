import { countryISOMap } from '@repo/constants';
import classNames from 'classnames';
import { useState } from 'react';
import Select, { SingleValue } from 'react-select';
import Alert from '../../components/alert';
import useAlert from '../../hooks/useAlert';
import { trpc } from '../../lib/trpc';
import { convertFileToBase64 } from '../../utils/convertFileToBase64';

type DocumentType = 'SELFIE' | 'PASSPORT' | 'DRIVERS' | 'ID_CARD';

type UploadKycDocProp = { userId: string };

function UploadKycDoc({ userId }: UploadKycDocProp) {
    const { showAlert, alert } = useAlert();
    const [file, setFile] = useState<undefined | File>();
    const [filename, setFileName] = useState<string>('');
    const [base64String, setBase64String] = useState<string | null>(null);
    const [fileError, setFileError] = useState<string | null>(null);
    const [documentType, setDocumentType] = useState<DocumentType | ''>('');
    const [documentSubType, setDocumentSubType] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [errors, setErrors] = useState({
        documentType: '',
        filename: '',
        country: '',
        content: '',
    });

    const countryOptions = Object.entries(countryISOMap).map(([key, value]) => ({
        value: key,
        label: `${value}`,
    }));
    const { mutate: uploadKycDocument, isLoading: uploadingKycDocument } = trpc.admin.uploadKycDocument.useMutation({
        onSuccess: () => {
            showAlert('KYC document successfully uploaded!', 'success', 5000);
            setBase64String(null);
            setFile(undefined);
            setFileName('');
            setDocumentType('');
            setDocumentSubType('');
            setErrors({
                documentType: '',
                filename: '',
                country: '',
                content: '',
            });
        },
        onError: e => {
            showAlert(e.message, 'error');
        },
    });

    function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target = event.target as HTMLInputElement;
        const selectedFile: File = (target.files as FileList)[0];
        if (selectedFile?.size > 500 * 1024) {
            showAlert('File size exceeds 500KB', 'error');
            setFile(undefined);
            setBase64String(null);
        } else {
            setFileError(null);
            setFile(selectedFile);
            convertFileToBase64(selectedFile)
                .then(base64 => {
                    setBase64String(base64);
                })
                .catch(() => {
                    showAlert('Error converting file to base64:', 'error');
                });
        }
    }

    function removeFile() {
        setFile(undefined);
        setBase64String(null);
    }

    function handleFileSubmit(event: any) {
        event.preventDefault();
        const newErrors = {
            documentType: documentType ? '' : 'Document type is required',
            filename: filename ? '' : 'Filename is required',
            country: country ? '' : 'Country is required',
            content: base64String ? '' : 'File content is required',
        };

        if (!documentType || !filename || !country || !base64String) {
            setErrors(newErrors);
            return;
        }

        convertFileToBase64(file!)
            .then(base64 => {
                if (!documentType) return;
                console.log(base64);
                uploadKycDocument({
                    id: +userId,
                    document_type: documentType,
                    document_subtype: documentSubType,
                    content: base64,
                    country,
                    filename,
                });
            })
            .catch(() => {
                showAlert('Error converting file to base64:', 'error');
            });
    }

    function handleDocumentTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const selectedType = event.target.value as DocumentType;
        setDocumentType(selectedType);
        setDocumentSubType(''); // Reset sub-type when document type changes
        setErrors({ ...errors, documentType: '' });
    }

    function handleDocumentSubTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setDocumentSubType(event.target.value);
    }

    function onCountryChange(newValue: SingleValue<{ value: string; label: string }>) {
        if (newValue) {
            setCountry(newValue.value);
        } else {
            setCountry('');
        }
        setErrors({ ...errors, country: '' });
    }

    function onFileNameChange(event: any) {
        setFileName(event.target.value);
        setErrors({ ...errors, filename: '' });
    }

    return (
        <>
            {alert && <Alert classes={'w-[20%] ml-auto'} message={alert.message} event={alert.type} />}
            <form onSubmit={handleFileSubmit} className="max-w-sm py-2 mx-auto">
                <label
                    htmlFor="document-sub-type"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                    Name of File
                </label>
                <input
                    value={filename}
                    onChange={onFileNameChange}
                    type="text"
                    className={`bg-gray-50 border mb-4 ${'border-gray-300'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                />
                {errors.filename && <p className="text-red-800 text-xs mt-1">{errors.filename}</p>}

                <label htmlFor="document-type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Select a document type
                </label>
                <select
                    value={documentType}
                    onChange={handleDocumentTypeChange}
                    className="bg-gray-50 border mb-4 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                >
                    <option value="" selected disabled>
                        Choose a document type
                    </option>
                    <option value="SELFIE">Selfie</option>
                    <option value="PASSPORT">Passport</option>
                    <option value="DRIVERS">Drivers</option>
                    <option value="ID_CARD">ID Card</option>
                </select>
                {errors.documentType && <p className="text-red-800 text-xs mt-1">{errors.documentType}</p>}

                {(documentType === 'DRIVERS' || documentType === 'ID_CARD') && (
                    <>
                        <label
                            htmlFor="document-sub-type"
                            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                            Select a document Sub-type
                        </label>
                        <select
                            value={documentSubType}
                            onChange={handleDocumentSubTypeChange}
                            className="bg-gray-50 mb-4 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                        >
                            <option disabled>Choose a Side</option>
                            <option value="FRONT_SIDE">FRONT SIDE</option>
                            <option value="BACK_SIDE">BACK SIDE</option>
                        </select>
                    </>
                )}
                <label htmlFor="country" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Country (2-letter code, ISO 3166-1 alfa-2 )
                </label>

                <Select
                    isClearable
                    components={{
                        IndicatorSeparator: () => null,
                    }}
                    value={countryOptions.find(option => option.value === country)}
                    onChange={onCountryChange}
                    options={countryOptions}
                    className={`
                                text-gray-900 mb-4 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                />
                {errors.country && <p className="text-red-800 text-xs mt-1">{errors.country}</p>}

                <label
                    htmlFor="uploadFile1"
                    className={classNames({
                        'bg-white text-gray-500 font-semibold text-base rounded max-w-md h-52 flex flex-col items-center justify-center cursor-pointer border-2 border-gray-300 border-dashed mx-auto font-[sans-serif]':
                            !base64String,
                    })}
                >
                    {base64String && (
                        <div className="relative">
                            <div onClick={removeFile} className="absolute z-10  -top-2 -right-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                    />
                                </svg>
                            </div>
                            {file &&
                                (['image/jpeg', 'image/jpg', 'image/png'].includes(file.type) ? (
                                    base64String && (
                                        <img
                                            src={`data:image/*;base64,${base64String}`}
                                            alt="Preview"
                                            className="mt-4 w-full object-cover mb-2 max-h-48 mx-auto"
                                        />
                                    )
                                ) : (
                                    <div className="flex justify-center h-10">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className="w-6 h-6"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                                            />
                                        </svg>
                                    </div>
                                ))}

                            {file && <p className="text-xl text-center">{file?.name}</p>}
                        </div>
                    )}
                    {!base64String && (
                        <>
                            Upload file
                            <input type="file" id="uploadFile1" onChange={handleFileChange} className="hidden" />
                            <p className="text-xs font-medium text-gray-400 mt-2">
                                PNG, JPG, SVG, WEBP, and GIF are allowed. Max size: 500KB.
                            </p>
                        </>
                    )}
                </label>
                {fileError && <p className="text-red-500 text-xs mt-2">{fileError}</p>}
                {errors.content && <p className="text-red-800 text-xs mt-1">{errors.content}</p>}

                <button type="submit" className="btn mt-4">
                    {uploadingKycDocument ? 'Uploading...' : 'Upload'}
                </button>
            </form>
        </>
    );
}

export default UploadKycDoc;
