import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import LoaderContainer from '../components/loader';
import Pagination from '../components/pagination';
import Table from '../components/table';
import { trpc } from '../lib/trpc';
import { IFilterBaseQuery } from './transactions';

const columns = [
    {
        label: 'Date',
        key: 'createdAt',
        format: (data: string) => format(parseISO(data), 'MMMM dd, yyyy'),
    },
    {
        label: 'Time',
        key: 'createdAt',
        format: (data: string) => format(parseISO(data), 'hh:mm:ss a'),
    },
    {
        label: 'Name',
        key: 'activityType',
        className: 'text-green-700 font-extrabold',
        format: (data: string) => data.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase(),
    },
    { label: 'Description of Activity', key: 'details' },
];

const activityFilter = [
    {
        label: 'Individual',
        value: 'user',
    },
    {
        label: 'Corporate',
        value: 'company',
    },
];

type queryType = 'user' | 'company';

function Activites() {
    const [queryParams, setQueryParams] = useState<IFilterBaseQuery>({
        page: 1,
        limit: 25,
        type: 'user',
    });
    const updateQueryParams = (params: IFilterBaseQuery) => {
        setQueryParams(prev => ({ ...prev, ...params }));
    };

    const { data: adminActivityTrails, isLoading, isError, error } = trpc.adminActivityTrail.list.useQuery(queryParams);

    const { meta } = adminActivityTrails || {}; // Destructuring meta once

    if (isError) {
        console.error(error);
        return <h2>Error...</h2>;
    }

    // For the pagination
    const [pageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);

    const changePage = (pageNumber: number) => {
        updateQueryParams({ page: pageNumber });
    };

    const incrementPage = () => {
        updateQueryParams({ page: (queryParams?.page as number) + 1 });
        if ((queryParams?.page as number) + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };

    const decrementPage = (): void | null => {
        updateQueryParams({ page: (queryParams?.page as number) - 1 });
        if (((queryParams?.page as number) - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
        if ((queryParams?.page as number) - 1 === 0) {
            return null;
        }
    };

    return (
        <div className="mx-5">
            <LoaderContainer loading={isLoading}>
                <main>
                    <div className="flex items-center justify-between mb-4 csv-download">
                        <h1 className="text-xl">
                            <span className="font-bold ml-3.5">Activities</span>
                        </h1>
                        <div className="flex gap-3">
                            {activityFilter.map(({ label, value }) => (
                                <button
                                    onClick={() => updateQueryParams({ type: value as queryType, page: 1 })}
                                    className={`${
                                        value === queryParams.type
                                            ? 'text-white bg-[#374151]'
                                            : 'bg-white text-[#374151]'
                                    } px-3 py-2 border-2 border-solid rounded-lg border-[#374151] font-medium`}
                                >
                                    {label}
                                </button>
                            ))}
                        </div>
                    </div>
                    <Table title="" columns={columns} data={adminActivityTrails?.data || []} />
                    <Pagination
                        totalPages={meta?.totalPages}
                        pageSize={meta?.limit}
                        totalRecords={meta?.totalRecords}
                        page={meta?.currentPage}
                        changePage={changePage}
                        incrementPage={incrementPage}
                        decrementPage={decrementPage}
                        pageNumberLimit={pageNumberLimit}
                        minPageNumberLimit={minPageNumberLimit}
                        maxPageNumberLimit={maxPageNumberLimit}
                    />
                </main>
            </LoaderContainer>
        </div>
    );
}

export default Activites;
