import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import Auth from '../../route/auth';
import Loader from '../loader/loader';

export enum ROLES {
    SHIGA_ADMIN = 'Shiga Admin',
}

export const AuthenticationGuard = ({ Component }: any) => {
    const { isAuthenticated, getIdTokenClaims, isLoading } = useAuth0();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        async function fetchTokens() {
            try {
                const idTokenClaims = await getIdTokenClaims();
                const roles = idTokenClaims?.[`${import.meta.env.VITE_AUTH0_AUDIENCE}/roles`] || [];
                const isAdmin = roles.includes(ROLES.SHIGA_ADMIN) && isAuthenticated;
                setIsAdmin(isAdmin);
            } catch (error) {
                console.error('Error fetching tokens:', error);
            }
        }
        if (isAuthenticated) {
            fetchTokens();
        } else {
            setIsAdmin(false);
        }
    }, [isAuthenticated, getIdTokenClaims]);

    if (isLoading)
        return (
            <main className="h-screen w-screen flex items-center justify-center">
                <Loader />
            </main>
        );

    return isAdmin ? <Component /> : <Auth />;
};
