import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import Alert from '../../components/alert';
import ErrorInput from '../../components/input';
import LoaderContainer from '../../components/loader';
import Pagination from '../../components/pagination';
import Table from '../../components/table';
import useAlert from '../../hooks/useAlert';
import { trpc } from '../../lib/trpc';

const columns = [
    {
        label: 'Image',
        key: 'imageUrl',
        meta: {
            type: 'image',
            base: 'https://shiga-country-flags.s3.amazonaws.com/',
        },
    },
    { label: 'Name', key: 'name' },
    { label: 'Country ISO', key: 'code' },

    { label: 'Phone Number Code', key: 'phoneNumberCode' },
    {
        label: 'Individual Supported',
        key: 'isIndividualSupported',
        format: (data: boolean) => (data ? 'True' : 'No'),
    },
    {
        label: 'Corporate Supported',
        key: 'isCorporateSupported',
        format: (data: boolean) => (data ? 'True' : 'No'),
    },
];

const createSupportedCountryFormSchema = z.object({
    name: z.string().min(1, 'Name is required').trim(),
    imageUrl: z.string().min(1, 'Image URL is required').trim(),
    code: z.string().min(1, 'Code is required').trim(),
    phoneNumberCode: z.string().min(1, 'Phone number code is required').trim(),
    isIndividualSupported: z.boolean(),
    isCorporateSupported: z.boolean(),
});

type CreateSupportedCountryFormSchema = z.infer<typeof createSupportedCountryFormSchema>;
type ContentType = 'image/jpeg' | 'image/png' | 'image/jpg' | 'application/pdf';
const SupportedCountryForm = () => {
    const utils = trpc.useUtils();
    const [page, setPage] = useState(1);
    const { data: response, isLoading } = trpc.adminSetting.listSupportedCountries.useQuery({ page, limit: 25 });
    const { meta, data: SupportedCountries } = response || {};
    const { alert, showAlert } = useAlert();

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm<CreateSupportedCountryFormSchema>({
        resolver: zodResolver(createSupportedCountryFormSchema),
    });

    const [imageUrl, setImageUrl] = useState('');
    const [fileType, setFileType] = useState<ContentType>('image/jpeg');

    const [pageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);

    const changePage = (pageNumber: number) => setPage(pageNumber);

    const incrementPage = () => {
        setPage(page + 1);
        if (page + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };

    const decrementPage = () => {
        if (page - 1 > 0) {
            setPage(page - 1);
            if ((page - 1) % pageNumberLimit === 0) {
                setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
                setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
            }
        }
    };

    const { data: uploadData } = trpc.adminUnblock.getUploadSignedUrlForCountryFlag.useQuery({
        id: '1',
        contentType: fileType,
    });
    const { mutate, isLoading: isCreatingSupportedCountry } = trpc.adminSetting.createSupportedCountry.useMutation({
        onSuccess: () => {
            utils.adminSetting.listSupportedCountries.refetch();
            reset(); // Reset the form after successful submission
        },
    });

    const onImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const validTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
            if (!validTypes.includes(file.type)) {
                showAlert('Invalid file type. Please upload a JPEG, PNG, JPG, or PDF file.', 'error', 10000);

                return;
            }
            try {
                if (uploadData) {
                    await axios.put(uploadData?.url as string, file, {
                        headers: {
                            'Content-Type': file.type,
                        },
                    });

                    setFileType(file.type as ContentType);
                    setImageUrl(uploadData?.key as string);
                    setValue('imageUrl', uploadData?.url as string);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const onSubmit = (data: CreateSupportedCountryFormSchema) => {
        data.imageUrl = imageUrl;
        mutate(data);
    };

    return (
        <>
            {' '}
            {alert && <Alert event={alert?.type} classes={'w-[20%] ml-auto'} message={alert.message} />}
            <form onSubmit={handleSubmit(onSubmit)} className="max-w-lg mx-auto p-6 bg-white shadow-lg rounded-lg">
                <div className="mb-6">
                    <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">
                        Country Name
                    </label>
                    <ErrorInput
                        error={{ message: errors.name?.message }}
                        register={register('name')}
                        type="text"
                        id="name"
                        className={`bg-gray-50 border ${
                            errors.name ? 'border-red-500' : 'border-gray-300'
                        } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2`}
                    />
                    {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name.message}</p>}
                </div>

                <div className="mb-6">
                    <label htmlFor="code" className="block text-gray-700 font-semibold mb-2">
                        Country Code
                    </label>
                    <ErrorInput
                        error={{ message: errors.code?.message }}
                        register={register('code')}
                        type="text"
                        id="code"
                        className={`bg-gray-50 border ${
                            errors.code ? 'border-red-500' : 'border-gray-300'
                        } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2`}
                    />
                    {errors.code && <p className="text-red-500 text-xs mt-1">{errors.code.message}</p>}
                </div>

                <div className="mb-6">
                    <label htmlFor="imageUrl" className="block text-gray-700 font-semibold mb-2">
                        Image
                    </label>
                    <input
                        id="imageUrl"
                        name="imageUrl"
                        type="file"
                        className="w-full p-2 border rounded-lg focus:ring-blue-500 focus:border-blue-500"
                        onChange={onImageChange}
                    />
                </div>

                <div className="mb-6">
                    <label htmlFor="phoneNumberCode" className="block text-gray-700 font-semibold mb-2">
                        Phone Number Code
                    </label>
                    <ErrorInput
                        error={{ message: errors.phoneNumberCode?.message }}
                        register={register('phoneNumberCode')}
                        type="text"
                        id="phoneNumberCode"
                        className={`bg-gray-50 border ${
                            errors.phoneNumberCode ? 'border-red-500' : 'border-gray-300'
                        } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2`}
                    />
                    {errors.phoneNumberCode && (
                        <p className="text-red-500 text-xs mt-1">{errors.phoneNumberCode.message}</p>
                    )}
                </div>

                <div className="mb-6">
                    <label htmlFor="isIndividualSupported" className="flex items-center text-gray-700 font-semibold">
                        <input
                            type="checkbox"
                            id="isIndividualSupported"
                            className="form-checkbox h-5 w-5 text-blue-600"
                            {...register('isIndividualSupported')}
                        />
                        <span className="ml-2">Individual Supported</span>
                    </label>
                </div>

                <div className="mb-6">
                    <label htmlFor="isCorporateSupported" className="flex items-center text-gray-700 font-semibold">
                        <input
                            type="checkbox"
                            id="isCorporateSupported"
                            className="form-checkbox h-5 w-5 text-blue-600"
                            {...register('isCorporateSupported')}
                        />
                        <span className="ml-2">Corporate Supported</span>
                    </label>
                </div>

                <button
                    disabled={isLoading}
                    type="submit"
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
                >
                    {isCreatingSupportedCountry ? 'Creating..' : 'Submit'}
                </button>
            </form>
            <LoaderContainer loading={isLoading}>
                <main className="px-4">
                    <Table
                        title="Supported Countries"
                        columns={columns}
                        data={SupportedCountries || []}
                        clickable={true}
                        useDelete={true}
                    />
                    <Pagination
                        totalPages={meta?.totalPages}
                        pageSize={meta?.limit}
                        totalRecords={meta?.totalRecords}
                        page={meta?.currentPage}
                        changePage={changePage}
                        incrementPage={incrementPage}
                        decrementPage={decrementPage}
                        pageNumberLimit={pageNumberLimit}
                        minPageNumberLimit={minPageNumberLimit}
                        maxPageNumberLimit={maxPageNumberLimit}
                    />
                </main>
            </LoaderContainer>
        </>
    );
};

export default SupportedCountryForm;
