import useAlert from "../../hooks/useAlert"
import { trpc } from "../../lib/trpc"
import Alert from "../../components/alert"

function DeleteCorporate({
  corporateId,
  setModalClose,
}: {
  corporateId: number
  setModalClose: () => void
}) {
  const { alert, showAlert } = useAlert()
  const trpcUtils = trpc.useUtils()
  const { mutate, isLoading } = trpc.corporate.corporateSoftDelete.useMutation({
    onSuccess: () => {
      showAlert("Corporate successfully deleted", "success")
      trpcUtils.admin.listCoprates.refetch()
      setModalClose()
    },
  })

  const handleDelete = (id: number) => {
    mutate({ companyId: id })
  }

  return (
    <div>
      {alert && (
        <Alert
          classes={"w-[20%] ml-auto"}
          message={alert.message}
          event={alert.type}
        />
      )}

      <div>
        <p className="font-[600] text-[20px] text-center">
          This will delete the corporate from the list of corporates
        </p>
        <p className="text-center mt-2">Are you sure you want to proceed?</p>

        <div className="flex items-center justify-center p-4 md:p-5  rounded-b mt-3">
          <button
            disabled={isLoading}
            type="submit"
            className="btn btn-outline mr-5"
            onClick={() => handleDelete(corporateId)}
          >
            {isLoading ? "Deleting" : "Delete"}
          </button>
          <button
            type="button"
            onClick={setModalClose}
            className="btn btn-outline"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeleteCorporate
