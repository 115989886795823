import { useAuth0 } from '@auth0/auth0-react';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Event } from '../../components/alert';
import { trpc } from '../../lib/trpc';
import { dataMessageType } from './viewContactMessage';



function ResolveMessageContact ({
    dataMessage,
    setModalClose,
    showAlert
}: {
    dataMessage: dataMessageType;
    setModalClose: () => void;
    showAlert?: (message: string, type: Event, delay?: number) => void;

}) {
    const { user } = useAuth0();

    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            resolveMessage: '',
        },
    });

    const utils = trpc.useUtils();

    const { mutate, isLoading } = trpc.contact.resolveContactMessage.useMutation({
        onSuccess: (res) => {
            utils.contact.getAllContactUs.invalidate();
            showAlert && showAlert(res?.message, 'success');
            setModalClose();
        },
    }

    );

    useEffect(() => {
        setValue('resolveMessage', dataMessage?.resolveMessage || '');
    }, [dataMessage]);

    const handleSubmitReport = (data: { resolveMessage: string }) => {
        mutate({
            id: dataMessage?.id,
            resolveMessage: data?.resolveMessage,
            adminEmail: user?.email as string,
        });
    };

    return (
        <>

            {
                dataMessage?.resolution === 'RESOLVED' &&
                <div className="grid w-full grid-cols-2">
                    <div className="text-[16px] font-semibold flex flex-col gap-y-4">
                        <p>Resolved By:</p>
                        <p>Resolved Date:</p>
                    </div>
                    <div className="text-[16px] font-semibold flex flex-col gap-y-4">
                        <p>{dataMessage?.resolvedByName || 'N/A'}</p>
                        <p>{dataMessage?.resolvedAt && format(dataMessage?.resolvedAt, 'yyyy-MM-dd HH:mm:ss') || 'N/A'}</p>
                    </div>
                </div>
            }

            <div>
                <p className="text-[16px] font-semibold">Message:</p>
                <textarea
                    className="w-full px-3 py-2 mt-3 border border-gray-700 rounded-md outline-none cursor-pointer"
                    maxLength={1200}
                    rows={13}
                    disabled={dataMessage?.resolution === 'RESOLVED' ? true : false}
                    {...register('resolveMessage')}
                    style={{ resize: 'none' }}
                />
            </div>

            <div className="flex items-center justify-end gap-4 p-4 mt-5 rounded-b md:p-5">
                <button type="button" disabled={isLoading} onClick={handleSubmit(handleSubmitReport)} className="btn btn-outline">
                    {isLoading ? 'Resolving...' : 'Resolve'}
                </button>
                <button type="button" disabled={isLoading} onClick={setModalClose} className="btn btn-outline">
                    Cancel
                </button>
            </div>
        </>
    );
}

export default ResolveMessageContact;
