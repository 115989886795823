import { cn } from "../../utils/classname";

interface AlertProps {
    event: Event;
    message: string;
    classes?: string
}

export type Event = 'success' | 'error' | 'info' | 'warning';

const Warning = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
    </svg>
);

const Success = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
    </svg>
);

const Error = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
    </svg>
);

function Alert({ event, message, classes }: AlertProps) {
    const renderIcon = () => {
        if (event == 'success') {
            return <Success />;
        }
        if (event == 'warning') {
            return <Warning />;
        }
        return <Error />;
    };

    function getAlertClass(event: Event): string {
        switch (event) {
            case 'success':
                return 'bg-green-500 text-white';
            case 'error':
                return 'bg-red-500 text-white';
            case 'info':
                return 'bg-blue-500 text-white';
            case 'warning':
                return 'bg-yellow-500 text-white';
            default:
                return '';
        }
    }

    return (
        <div role="alert" className={cn('alert',`${getAlertClass(event)} fixed top-0 right-[5%] m-4 z-50 px-10 py-7`, classes)}>
            {renderIcon()}
            <span>{message}</span>
        </div>
    );
}

export default Alert;
