import { useAuth0 } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthenticationGuard } from './components/authentication-guard';
import Dashboard from './components/dashboard';
import { trpc } from './lib/trpc';
import AccountsStatement from './route/accountsStatement';
import Activites from './route/activities';
import Auth from './route/auth';
import ContactUs from './route/contact-us';
import CorporateView from './route/corporateView';
import Corporates from './route/corporates';
import Create from './route/create';
import CreateCoporate from './route/createCorporate';
import Faqs from './route/faqs';
import Home from './route/home';
import { NotFoundPage } from './route/no-found';
import Settings from './route/settings';
import Transactions from './route/transactions';
import UserView from './route/userView';
import Users from './route/users';

function App() {
    const { getAccessTokenSilently } = useAuth0();
    const [queryClient] = useState(() => new QueryClient());
    const [trpcClient] = useState(() => {
        return trpc.createClient({
            links: [
                httpBatchLink({
                    url: import.meta.env.VITE_BACKEND_BASEURL,
                    async headers() {
                        const token = await getAccessTokenSilently();
                        localStorage.setItem('token', token);
                        return {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        };
                    },
                }),
            ],
        });
    });
    return (
        <trpc.Provider queryClient={queryClient} client={trpcClient}>
            <QueryClientProvider client={queryClient}>
                <Routes>
                    <Route path="/" element={<AuthenticationGuard Component={Auth} />} />
                    <Route path="/" element={<Dashboard />}>
                        <Route path="/home" element={<AuthenticationGuard Component={Home} />} />
                        <Route path="/individuals" element={<AuthenticationGuard Component={Users} />} />
                        <Route path="/transactions" element={<AuthenticationGuard Component={Transactions} />} />
                        <Route path="/settings" element={<AuthenticationGuard Component={Settings} />} />
                        <Route path="/corporates" element={<AuthenticationGuard Component={Corporates} />} />
                        <Route path="/activities" element={<AuthenticationGuard Component={Activites} />} />
                        <Route path="/individuals/create" element={<AuthenticationGuard Component={Create} />} />
                        <Route path="/corporates/create" element={<AuthenticationGuard Component={CreateCoporate} />} />
                        <Route path="/individuals/:id" element={<AuthenticationGuard Component={UserView} />} />
                        <Route path="/corporates/:id" element={<AuthenticationGuard Component={CorporateView} />} />
                        <Route path="/accounts-statement" element={<AuthenticationGuard Component={AccountsStatement} />} />
                        <Route path="/faqs" element={<AuthenticationGuard Component={Faqs} />} />
                        <Route path='/contact-us' element={<AuthenticationGuard Component={ContactUs} />} />
                    </Route>
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </QueryClientProvider>
        </trpc.Provider>
    );
}

export default App;
