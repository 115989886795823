import { format } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoaderContainer from '../components/loader';
import Modal from '../components/modal';
import Pagination from '../components/pagination';
import Table from '../components/table';
import featureFlags from '../featureFlags';
import useModal from '../hooks/useModal';
import { trpc } from '../lib/trpc';
import DeleteUser from '../section/deleteUser';

const columns = [
    {
        label: 'Date',
        key: 'createdAt',
        format: (date: string) => format(date, 'dd MMMM, yyyy'),
    },
    { label: 'First Name', key: 'firstName' },
    { label: 'Country', key: 'country' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'Source of Funds', key: 'sourceOfFunds' },
    {
        label: 'KYC status',
        key: 'kycstatus',
        className: (status: string) => {
            switch (status) {
                case 'SOFT_KYC_FAILED':
                case 'HARD_KYC_FAILED':
                case 'SUSPENDED':
                    return 'bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded';
                case 'FULL_USER':
                    return 'bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded';
                default:
                    return 'bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded';
            }
        },
        format: (data: string) => data?.replaceAll('_', ' ').toUpperCase(),
    },
];

function Users() {
    const [activeTab, setActiveTab] = useState('all');
    const { isModalVisible, setModalClose, setModalOpen } = useModal();
    const [individualsId, setIndividualsId] = useState<number | null>(null);

    const [page, setPage] = useState(1);
    const { data: usersData, isLoading, isError, error } = trpc.admin.listUsers.useQuery({ page, limit: 25 });
    const { meta, data } = usersData || {}; // Destructuring meta once

    const { data: listUsersCreatedByAdmin, isLoading:isLoadingUsersByAdmin} = trpc.admin.listUsersCreatedByAdmin.useQuery({ page, limit: 25 });
    const { meta: usersCreatedByAdminMeta, data: usersCreatedByAdmin } = listUsersCreatedByAdmin || {}; // Destructuring meta once

    const { data: listSelfSignedUpUsers, isLoading:isLoadingSelfSignedUpUsers} = trpc.admin.listSelfSignedUpUsers.useQuery({ page, limit: 25 });
    const { meta: listSelfSignedUpUsersMeta, data: selfSignedUpUsers } = listSelfSignedUpUsers || {}; // Destructuring meta once

    const navigate = useNavigate();

    if (isError) {
        console.error(error);
        return <h2>Error...</h2>;
    }

    const onRowClick = (_: any, item: any) => {
        navigate(`/individuals/${item.id}`);
    };

    const onClickSave = (data: any) => {
        console.log({ data });
    };

    // For the pagination
    const [pageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);

    const changePage = (pageNumber: number) => {
        setPage(pageNumber);
    };

    const incrementPage = () => {
        setPage(page + 1);
        if (page + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };

    const decrementPage = (): void | null => {
        setPage(page - 1);
        if ((page - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
        if (page - 1 === 0) {
            return null;
        }
    };
    const handleDeleteModal = (id: string) => {
        setModalOpen(1);
        setIndividualsId(Number(id));
    };

    return (
        <div className="mx-5">
            <Modal
                title="You are about to delete this user?"
                showModal={isModalVisible(1)}
                closeModal={() => setModalClose(1)}
            >
                <DeleteUser userId={individualsId as number} setModalClose={() => setModalClose(1)} />
            </Modal>
            {featureFlags.CREATE_USER && (
                <button
                    disabled={!featureFlags.CREATE_USER}
                    className="mb-5 btn"
                    onClick={() => navigate('/individuals/create')}
                >
                    Create Individual
                </button>
            )}

            <div>
                <div className="border-b border-gray-200 dark:border-gray-700">
                    <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                        <li className="me-2">
                            <button
                                onClick={() => setActiveTab('all')}
                                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group ${
                                    activeTab === 'all'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                                }`}
                            >
                                All
                            </button>
                        </li>

                        <li className="me-2">
                            <button
                                onClick={() => setActiveTab('adminCreated')}
                                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group ${
                                    activeTab === 'adminCreated'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                                }`}
                            >
                                Created by Admin
                            </button>
                        </li>
                        <li className="me-2">
                            <button
                                onClick={() => setActiveTab('selfCreated')}
                                className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg group ${
                                    activeTab === 'selfCreated'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300'
                                }`}
                            >
                                Self Registered{' '}
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="mt-4">
                    {activeTab === 'all' && (
                        <LoaderContainer loading={isLoading}>
                            <main className="">
                                <Table
                                    title="Individuals"
                                    onClickSave={onClickSave}
                                    onRowClick={onRowClick}
                                    columns={columns}
                                    data={data || []}
                                    clickable={true}
                                    enableActions={true}
                                    useDelete={true}
                                    onRowDelete={(data: any) => {
                                        handleDeleteModal(data.id);
                                    }}
                                />
                                <Pagination
                                    totalPages={meta?.totalPages}
                                    pageSize={meta?.limit}
                                    totalRecords={meta?.totalRecords}
                                    page={meta?.currentPage}
                                    changePage={changePage}
                                    incrementPage={incrementPage}
                                    decrementPage={decrementPage}
                                    pageNumberLimit={pageNumberLimit}
                                    minPageNumberLimit={minPageNumberLimit}
                                    maxPageNumberLimit={maxPageNumberLimit}
                                />{' '}
                            </main>
                        </LoaderContainer>
                    )}
                    {activeTab === 'adminCreated' && (
                        <LoaderContainer loading={isLoadingUsersByAdmin}>
                            <main className="">
                                <Table
                                    title="Individuals"
                                    onClickSave={onClickSave}
                                    onRowClick={onRowClick}
                                    columns={columns}
                                    data={usersCreatedByAdmin || []}
                                    clickable={true}
                                    enableActions={true}
                                    useDelete={true}
                                    onRowDelete={(data: any) => {
                                        handleDeleteModal(data.id);
                                    }}
                                />
                                <Pagination
                                    totalPages={usersCreatedByAdminMeta?.totalPages}
                                    pageSize={usersCreatedByAdminMeta?.limit}
                                    totalRecords={usersCreatedByAdminMeta?.totalRecords}
                                    page={usersCreatedByAdminMeta?.currentPage}
                                    changePage={changePage}
                                    incrementPage={incrementPage}
                                    decrementPage={decrementPage}
                                    pageNumberLimit={pageNumberLimit}
                                    minPageNumberLimit={minPageNumberLimit}
                                    maxPageNumberLimit={maxPageNumberLimit}
                                />{' '}
                            </main>
                        </LoaderContainer>
                    )}
                    {activeTab === 'selfCreated' && (
                        <LoaderContainer loading={isLoadingSelfSignedUpUsers}>
                            <main className="">
                                <Table
                                    title="Individuals"
                                    onClickSave={onClickSave}
                                    onRowClick={onRowClick}
                                    columns={columns}
                                    data={selfSignedUpUsers || []}
                                    clickable={true}
                                    enableActions={true}
                                    useDelete={true}
                                    onRowDelete={(data: any) => {
                                        handleDeleteModal(data.id);
                                    }}
                                />
                                <Pagination
                                    totalPages={listSelfSignedUpUsersMeta?.totalPages}
                                    pageSize={listSelfSignedUpUsersMeta?.limit}
                                    totalRecords={listSelfSignedUpUsersMeta?.totalRecords}
                                    page={listSelfSignedUpUsersMeta?.currentPage}
                                    changePage={changePage}
                                    incrementPage={incrementPage}
                                    decrementPage={decrementPage}
                                    pageNumberLimit={pageNumberLimit}
                                    minPageNumberLimit={minPageNumberLimit}
                                    maxPageNumberLimit={maxPageNumberLimit}
                                />{' '}
                            </main>
                        </LoaderContainer>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Users;
