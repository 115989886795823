import { SyntheticEvent, useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import { trpc } from '../../lib/trpc';

const customStyles = {
    control: (base: any) => ({
        ...base,
        flexDirection: 'row-reverse',
    }),
    clearIndicator: (base: any) => ({
        ...base,
        position: 'absolute',
        right: 0,
    }),
    valueContainer: (base: any) => ({
        ...base,
        paddingRight: '2.3rem',
    }),
};

const SearchIcon = () => (
    <svg width="22" height="22" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="38" cy="40" r="20.5" stroke="currentColor" strokeWidth="7" />
        <path
            d="M76.0872 84.4699C78.056 86.4061 81.2217 86.3797 83.158 84.4109C85.0943 82.442 85.0679 79.2763 83.099 77.34L76.0872 84.4699ZM50.4199 59.2273L76.0872 84.4699L83.099 77.34L57.4317 52.0974L50.4199 59.2273Z"
            fill="currentColor"
        />
    </svg>
);

const DropdownIndicator = (props: any) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <SearchIcon />
            </components.DropdownIndicator>
        )
    );
};

function SetCorporateAdminModal({ onSubmit, isLoading }: { isLoading: boolean; onSubmit: (data: string) => void }) {
    //TODO: To use Infinite scroll
    const { data: userData } = trpc.admin.listUsers.useQuery({limit: 25,page: 1});
    const {data} = userData ||{}

    const [inputText, setInputText] = useState<string>('');

    const memoizedUsers = useMemo(
        () => data?.map(user => ({ value: user.id, label: `${user.firstName}  ${user.lastName}` })) || [],
        [data],
    );

    const handleSelectChange = (selectedOption: any) => {
        setInputText(selectedOption?.value || '');
    };

    const onSetCorporateAdmin = (e: SyntheticEvent) => {
        e.preventDefault();
        onSubmit(inputText);
    };
    
    return (
        <form>
            <label className="mb-2">Select a user</label>
            <Select
                options={memoizedUsers}
                isClearable={true}
                styles={customStyles}
                className="mb-10"
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                }}
                onChange={handleSelectChange}
            />
            <button onClick={onSetCorporateAdmin} className="mb-5 btn btn-primary">
                {isLoading ? 'Setting Corporate Admin' : 'Set Corporate Admin'}
            </button>
        </form>
    );
}

export default SetCorporateAdminModal;
