import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Event } from '../../components/alert';
import { trpc } from '../../lib/trpc';

type KycEvaluationProps = {
    setModalClose: () => void;
    id: number;
    showAlert?: (message: string, type: Event, delay?: number) => void;
};

const options = [
    { value: 'CREATED', label: 'Created' },
    { value: 'REVIEWING', label: 'Reviewing' },
    { value: 'ATTENTION_REQUIRED', label: 'Attention Required' },
    { value: 'FAILED', label: 'Failed' },
    { value: 'ACTIVE', label: 'Active' },
];

const kycEvaluationSchema = z.object({
    status: z.enum(['CREATED', 'REVIEWING', 'ATTENTION_REQUIRED', 'FAILED', 'ACTIVE']),
    reason: z.string().optional(),
});
function KycEvaluation({ setModalClose, id, showAlert }: KycEvaluationProps) {
    const { reset, control, handleSubmit } = useForm({
        defaultValues: {
            status: '',
            reason: '',
        },
        resolver: zodResolver(kycEvaluationSchema),
        mode: 'onChange',
    });
    const [kycStatus, setKycStatus] = useState('');
    const { mutate: setShigaKYC, isLoading: isSettingShigaKYC } = trpc.admin.setKycStatus.useMutation({
        onSuccess: res => {
            if (res.status) {
                setKycStatus(res.message);
                showAlert && showAlert('Status set successfully', 'success');
            }
        },
        onError: error => {
            showAlert?.(error.message, 'error');
        },
    });

    const onSubmit = (data: { status: string; reason: string }) => {
        if (data.status === '') {
            return alert('Please select status');
        }
        setShigaKYC({ userId: id, status: data.status });
    };

    const closeModal = () => {
        reset();
        setModalClose();
    };

    return (
        <div>
            {kycStatus && <div className="text-lg text-center text-green-500">{kycStatus}</div>}

            <div className="flex flex-col gap-4">
                <div>
                    <label htmlFor="status">Status</label>
                    <Controller
                        name="status"
                        control={control}
                        render={({ field: { onChange, value }, fieldState }) => (
                            <>
                                <select
                                    className="w-full px-3 py-2 border border-gray-700 rounded-md outline-none cursor-pointer"
                                    onChange={e => onChange(e.target.value)}
                                    value={value}
                                >
                                    <option value="" disabled>
                                        Select Status
                                    </option>
                                    {options.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                {fieldState.error && <p className="text-sm text-red-500">{fieldState.error.message}</p>}
                            </>
                        )}
                    />
                </div>
                <div>
                    <label htmlFor="reason">Reason</label>
                    <Controller
                        name="reason"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <textarea
                                className="w-full px-3 py-2 border border-gray-700 rounded-md outline-none cursor-pointer"
                                onChange={e => onChange(e.target.value)}
                                value={value}
                                maxLength={1200}
                                rows={9}
                                placeholder="Enter reason"
                                style={{ resize: 'none' }}
                            />
                        )}
                    />
                </div>
            </div>
            <p onClick={() => reset()} className="my-5 italic text-left text-green-400 underline cursor-pointer">
                Reset Form
            </p>
            <div className="flex items-center justify-end p-4 rounded-b md:p-5">
                <button type="submit" className="mr-5 btn btn-outline" onClick={handleSubmit(onSubmit)}>
                    {isSettingShigaKYC ? 'Submitting...' : 'Submit'}
                </button>
                <button type="button" onClick={closeModal} className="btn btn-outline">
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default KycEvaluation;
