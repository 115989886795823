import { ReactNode } from 'react';
import Loader from './loader';

interface LoaderContainerProps {
    loading: boolean;
    children: ReactNode;
}

const LoaderContainer: React.FC<LoaderContainerProps> = ({ loading, children }) => {
    return <div>{loading ? <Loader /> : <div>{children}</div>}</div>;
};

export default LoaderContainer;
