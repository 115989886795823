import React, { useState } from 'react';

interface Option {
    value: string;
    label: string;
}

interface TableCellProps {
    columnMeta?: {
        type?: string;
        options?: Option[];
        base?:string
    };
    value: string;
    onChangeInput: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, key: string, rowIndex: number) => void;
    rowIndex: number;
}

const TableCell: React.FC<TableCellProps> = ({ columnMeta, value, onChangeInput, rowIndex }) => {
    const [inputValue, setInputValue] = useState(value);

    const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setInputValue(e.target.value);
        onChangeInput(e, columnMeta?.type || '', rowIndex);
    };

    return (
        <>
            {columnMeta?.type === 'select' ? (
                <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5  b placeholder-gray-400  focus:ring-blue-500 focus:border-blue-500"
                    onChange={onSelectChange}
                    value={inputValue}
                >
                    {columnMeta?.options?.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            ) : columnMeta?.type === 'image' ? (
                <img className="w-10 h-10 rounded-full" src={columnMeta.base + value} alt="Rounded avatar" />
            ) : (
                <input
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    type={columnMeta?.type || 'text'}
                    className="py-3 px-2 rounded-md"
                />
            )}
        </>
    );
};

export default TableCell;
