import { useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Event } from '../../components/alert';
import { trpc } from '../../lib/trpc';
import { FaqData } from '../../route/faqs';

const options = [
    {
        label: 'About Shiga',
        value: 'aboutShiga',
    },
    {
        label: 'Key Benefits',
        value: 'keyBenefits',
    },
    {
        label: 'How to use Shiga',
        value: 'howToUseShiga',
    },
    {
        label: 'Contact & Support',
        value: 'contactSupport',
    },
];

type FaqFormProps = {
    setModalClose: () => void;
    showAlert?: (message: string, type: Event, delay?: number) => void;
    incomingData?: FaqData;
    action: string;
    formFaq: UseFormReturn<
        {
            section: string;
            question: string;
            answer: string;
        },
        any,
        undefined
    >;
};

function FaqForm({ showAlert, setModalClose, incomingData, formFaq, action }: FaqFormProps) {
    const utils = trpc.useUtils();
    const { reset, control, handleSubmit, setValue } = formFaq;

    useEffect(() => {
        if (incomingData) {
            setValue('question', incomingData?.question),
                setValue('answer', incomingData?.answer),
                setValue('section', incomingData?.section);
        }
    }, [incomingData, setValue]);

    const closeModal = () => {
        reset();
        setModalClose();
    };

    const { mutate: createMutate, isLoading: isLoadingCreate } = trpc.faqs.createShigaFaq.useMutation({
        onSuccess: res => {
            if (res) {
                showAlert && showAlert(res?.message, 'success');
                closeModal();
                utils.faqs.getShigaFaq.refetch();
            }
        },
        onError: error => {
            showAlert?.(error.message, 'error');
        },
    });
    const { mutate: updateMutate, isLoading: isLoadingUpdate } = trpc.faqs.updateShigaFaq.useMutation({
        onSuccess: res => {
            if (res) {
                showAlert && showAlert(res?.message, 'success');
                closeModal();
                utils.faqs.getShigaFaq.refetch();
            }
        },
        onError: error => {
            showAlert?.(error.message, 'error');
        },
    });

    const renderAction = (action: string, data: { question: string; answer: string; section: string; show?: boolean; id?: number }) => {
        switch (action) {
            case 'create':
                return createMutate({ question: data.question, answer: data.answer, section: data.section });
            case 'update':
                return   updateMutate({
                    question: data.question,
                    answer: data.answer,
                    section: data.section,
                    id: incomingData?.id as number,
                });
            default: 
                return 
        }
    };

    const onSubmit = (data: { question: string; answer: string; section: string }) => {
        if (data.section === '' || data.question === '' || data.answer === '') {
            return alert('Please fill in all fields');
        }
        renderAction(action, data);
    };

    return (
        <div>
            <div className="flex flex-col gap-4">
                <div>
                    <label htmlFor="section">Section</label>
                    <Controller
                        name="section"
                        control={control}
                        render={({ field: { onChange, value }, fieldState }) => (
                            <>
                                <select
                                    className="w-full px-3 py-2 border border-gray-700 rounded-md outline-none cursor-pointer"
                                    onChange={e => onChange(e.target.value)}
                                    value={value}
                                >
                                    <option value="" disabled>
                                        Select Faq Section
                                    </option>
                                    {options.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                                {fieldState.error && <p className="text-sm text-red-500">{fieldState.error.message}</p>}
                            </>
                        )}
                    />
                </div>
                <div>
                    <label htmlFor="question">Question</label>
                    <Controller
                        name="question"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <input
                                className="w-full px-3 py-2 border border-gray-700 rounded-md outline-none cursor-pointer"
                                onChange={e => onChange(e.target.value)}
                                value={value}
                                placeholder="Enter question"
                            />
                        )}
                    />
                </div>
                <div>
                    <label htmlFor="answer">Reason</label>
                    <Controller
                        name="answer"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <textarea
                                className="w-full px-3 py-2 border border-gray-700 rounded-md outline-none cursor-pointer"
                                onChange={e => onChange(e.target.value)}
                                value={value}
                                maxLength={1200}
                                rows={9}
                                placeholder="Enter answer"
                                style={{ resize: 'none' }}
                            />
                        )}
                    />
                </div>
            </div>
            <p onClick={() => reset()} className="my-5 italic text-left text-green-400 underline cursor-pointer">
                Reset Form
            </p>
            <div className="flex items-center justify-end p-4 rounded-b md:p-5">
                <button type="submit" className="mr-5 btn btn-outline" onClick={handleSubmit(onSubmit)}>
                    {isLoadingCreate || isLoadingUpdate ? 'Loading...' : 'Submit'}
                </button>
                <button type="button" onClick={closeModal} className="btn btn-outline">
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default FaqForm;
