import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Make sure to import the styles
import { Controller, UseFormReturn } from 'react-hook-form';
import { contactStatusEnum } from '../../route/contact-us';

type ContactForm = UseFormReturn<{
    dateFrom: Date | string;
    dateTo: Date | string;
    status: contactStatusEnum | string;
}>;

const formDateArray = [
    {
        label: 'Date From',
        name: 'dateFrom',
    },
    {
        label: 'Date To',
        name: 'dateTo',
    },
];

const transactionOptions = [
    {
        label: 'Read',
        value: 'READ',
    },
    {
        label: 'Unread',
        value: 'UNREAD',
    },
];

type inputName = 'dateFrom' | 'dateTo';

function FilterContact({
    contactFormField,
    submitFilter,
    handleResetForm,
}: {
    contactFormField: ContactForm;
    setModalClose: () => void;
    submitFilter: () => void;
    handleResetForm: () => void;

}) {
    const { control, watch } = contactFormField;

    const submitForm = () => {
        submitFilter();
    };

    const watchedValue = watch('status');

    return (
        <div>
            <div className="border-[1px] px-[16px] rounded-[8px] cursor-pointer">
                {transactionOptions.map(type => {
                    return (
                        <div key={type.label} className="w-full border-b-[1px] items-center last:border-0 m-0">
                            <div className="flex items-center justify-between cursor-pointer w-full  border-[#DFE2E2] py-[16px] rounded-xl last:mt-[0px] ">
                                <div>
                                    <label htmlFor={type.value} className="text-[13px] font-[500] cursor-pointer">
                                        {type.label}
                                    </label>
                                </div>
                                <div>
                                    <Controller
                                        name={'status'}
                                        control={control}
                                        render={({ field: { onChange } }) => (
                                            <div className="text-[13px] flex gap-[5px] items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    name={'status'}
                                                    checked={watchedValue === type.value}
                                                    id={type.value}
                                                    value={type.value}
                                                    className={`border-[#0e2038] accent-[#0e2038] cursor-pointer`}
                                                    onChange={e => onChange(e.target.checked ? e.target.value : '')}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="flex items-center justify-between w-full mt-6 gap-x-3">
                {formDateArray.map((item, index) => (
                    <div key={`${item.label}-${index}`} className="w-full">
                        <label className="mb-3 text-[13px] font-[500]">{item.label}</label>
                        <div className="w-full border border-[#DFE2E2] px-3 py-2 focus:outline-none focus:ring-0 focus:border-[#374151] rounded-md">
                            <Controller
                                control={control}
                                name={item.name as inputName}
                                render={({ field: { onChange, value, ...fieldProps } }) => (
                                    <ReactDatePicker
                                        {...fieldProps}
                                        dateFormat="yyyy/MM/dd"
                                        placeholderText="Select date"
                                        className="w-full focus:outline-none focus:border-none"
                                        selected={value ? new Date(value) : undefined}
                                        maxDate={item.name === 'dateTo' ? new Date() : undefined}
                                        onChange={(date: any) => onChange(date)} // Ensure that the date is handled correctly
                                    />
                                )}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex items-center justify-end p-4 mt-5 rounded-b md:p-5">
                <button type="submit" className="mr-5 btn btn-outline" onClick={submitForm}>
                    Apply Filter
                </button>
                <button type="button" onClick={handleResetForm} className="btn btn-outline">
                    Cancel
                </button>
            </div>
        </div>
    );
}

export default FilterContact;
