import { zodResolver } from '@hookform/resolvers/zod';
import { countryISOMap } from '@repo/constants';
import { differenceInYears, parse } from 'date-fns';
import { Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { twMerge } from 'tailwind-merge';
import * as z from 'zod';
import Alert, { Event } from '../components/alert';
import ErrorInput from '../components/input';
import { trpc } from '../lib/trpc';
import { isEvmAddress } from '../utils/isEvmAddress';
import { isSolanaAddress } from '../utils/isSolanaAddress';

// https://github.com/colinhacks/zod/issues/310#issuecomment-919420331
const emptyStringToUndefined = z.literal('').transform(() => undefined);

export function asOptionalField<T extends z.ZodTypeAny>(schema: T) {
    return schema.optional().or(emptyStringToUndefined);
}

const createUserschema = z.object({
    firstName: z.string().min(1, 'First name is required').trim(),
    lastName: z.string().min(1, 'Last name is required').trim(),
    walletAddress: z
        .string()
        .trim()
        .optional()
        .refine(value => !value || isEvmAddress(value), {
            message: 'Invalid Ethereum address',
        }),
    targetSolanaAddress: z.preprocess(
        value => {
            if ((typeof value === 'string' && value.trim() === '') || value === undefined || value == null) {
                return undefined;
            }
            return value;
        },
        z
            .string()
            .trim()
            .optional()
            .refine(value => value === undefined || isSolanaAddress(value), {
                message: 'Invalid Solana address',
            }),
    ),
    phoneNumber: z.string().trim().min(1, 'Phone number is required'),
    email: z.string().trim().email('Invalid email address'),
    dob: z
        .string()
        .trim()
        .refine(
            value => {
                const dateFormatIsValid = /^\d{4}-\d{2}-\d{2}$/.test(value);
                if (!dateFormatIsValid) {
                    return false;
                }

                const parsedDate = parse(value, 'yyyy-MM-dd', new Date());
                const age = differenceInYears(new Date(), parsedDate);
                return age >= 18;
            },
            {
                message: 'Date format YYYY-MM-DD and must be 18 or above',
            },
        ),
    sourceOfFunds: z.string().trim().min(1, 'Source of funds is required'),
    ngnBvn: asOptionalField(
        z.string().trim().length(11, 'BVN must be exactly 11 digits').regex(/^\d+$/, 'BVN must contain only numbers'),
    ),
    address: z.object({
        addressLine1: z.string().trim().min(1, 'Address line 1 is required'),
        postcode: z.string().trim().min(1, 'Postcode is required'),
        city: z.string().trim().min(1, 'City is required'),
        country: z
            .string()
            .trim()
            .length(2, 'Country must be exactly 2 characters long')
            .regex(/^[A-Za-z]{2}$/, 'Invalid country code format'),
    }),
});

type CreateUserschema = z.infer<typeof createUserschema>;

export type CreateComponent = {
    pathName?: string;
    useTitle?: boolean;
    sectionClassName?: string;
    btnContainerClassName?: string;
    closeModal?: () => void;
};

function Create({ useTitle = true, sectionClassName, btnContainerClassName, closeModal }: CreateComponent) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [alert, setAlert] = useState<{ message: string; type: Event } | null>(null);
    const { isLoading, mutate } = trpc.admin.create.useMutation({
        onError: res => {
            setAlert({ message: res.message, type: 'error' });
        },
        onSuccess: (insertedId: number) => {
            reset();
            setAlert({ message: 'User successfully created', type: 'success' });
            setTimeout(() => {
                if (pathname === '/home') {
                    closeModal?.();
                } else {
                    navigate(`/individuals/${insertedId}`);
                }
            }, 3000);
        },
    });

    const countryOptions = Object.entries(countryISOMap).map(([key, value]) => ({
        value: key,
        label: `${value} - ${key}`,
    }));

    const {
        reset,
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<CreateUserschema>({
        resolver: zodResolver(createUserschema),
    });

    const onSubmit = (data: CreateUserschema) => {
        mutate({ ...data, country: data.address.country });
    };
    const redirect = () => {
        if (pathname === '/home') {
            closeModal?.();
        } else {
            navigate('/individuals');
        }
    };

    return (
        <Fragment>
            {alert && <Alert classes={'w-[20%] ml-auto'} message={alert.message} event={alert.type} />}
            {useTitle && (
                <h1 className="block text-center  text-3xl font-bold text-dark mt-5 mb-3">CREATE INDIVIDUAL</h1>
            )}
            <section className={twMerge('flex pt-10 justify-center', sectionClassName)}>
                <form className="w-[90%]" onSubmit={handleSubmit(onSubmit)}>
                    <div className="bg-slate-500 py-2 px-5 mb-5">
                        <p className="block  text-xl font-bold text-white">Personal Information</p>
                    </div>
                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                        <div>
                            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 ">
                                First name
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.firstName?.message,
                                }}
                                register={register('firstName')}
                                type="text"
                                id="first_name"
                                className={`bg-gray-50 border ${
                                    errors.firstName ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Last name
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.lastName?.message,
                                }}
                                register={register('lastName')}
                                type="text"
                                id="last_name"
                                className={`bg-gray-50 border ${
                                    errors.lastName ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label htmlFor="walletAddress" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Ethereum Wallet Address
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.walletAddress?.message,
                                }}
                                register={register('walletAddress')}
                                type="text"
                                id="walletAddress"
                                placeholder="0x..."
                                className={`bg-gray-50 border ${
                                    errors.walletAddress ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="targetSolanaAddress"
                                className="block mb-2 text-sm font-medium text-gray-900 "
                            >
                                Solana Wallet Address
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.targetSolanaAddress?.message,
                                }}
                                register={register('targetSolanaAddress')}
                                type="text"
                                id="targetSolanaAddress"
                                placeholder="0x57..."
                                className={`bg-gray-50 border ${
                                    errors.targetSolanaAddress ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Phone number
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.phoneNumber?.message,
                                }}
                                register={register('phoneNumber')}
                                type="text"
                                id="Phone"
                                className={`bg-gray-50 border ${
                                    errors.phoneNumber ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Email
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.email?.message,
                                }}
                                register={register('email')}
                                type="text"
                                id="email"
                                placeholder="user@example.com"
                                className={`bg-gray-50 border ${
                                    errors.email ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label htmlFor="dob" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Date of Birth (YYYY-MM-DD){' '}
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.dob?.message,
                                }}
                                register={register('dob')}
                                type="text"
                                id="dob"
                                className={`bg-gray-50 border ${
                                    errors.dob ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label htmlFor="sourceOfFunds" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Source Of Funds{' '}
                            </label>

                            <select
                                id="sourceOfFunds"
                                {...register('sourceOfFunds')}
                                className={`bg-gray-50 border ${
                                    errors.sourceOfFunds ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            >
                                <option disabled value={''} selected>
                                    select
                                </option>
                                <option value="SALARY">SALARY</option>
                                <option value="BUSINESS_INCOME">BUSINESS INCOME</option>
                                <option value="PENSION">PENSION</option>
                                <option value="OTHER">OTHER</option>
                            </select>
                            {errors.sourceOfFunds && (
                                <span className="text-red-500 text-sm">{errors.sourceOfFunds.message}</span>
                            )}
                        </div>

                        <div>
                            <label htmlFor="ngnBvn" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Nigeria BVN{' '}
                            </label>

                            <ErrorInput
                                error={{
                                    message: errors.ngnBvn?.message,
                                }}
                                register={register('ngnBvn')}
                                type="text"
                                id="ngnBvn"
                                className={`bg-gray-50 border ${
                                    errors.ngnBvn ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                    </div>
                    <hr className="my-10" />
                    <div className="bg-slate-500 py-2 px-5 mb-5">
                        <p className="block  text-xl font-bold text-white">Address </p>
                    </div>
                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                        <div>
                            <label htmlFor="addressLine1" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Address Line
                            </label>

                            <ErrorInput
                                error={{
                                    message: errors.address?.addressLine1?.message,
                                }}
                                register={register('address.addressLine1')}
                                type="text"
                                id="addressLine1"
                                className={`bg-gray-50 border ${
                                    errors.address?.addressLine1 ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>

                        <div>
                            <label htmlFor="postcode" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Post Code
                            </label>

                            <ErrorInput
                                error={{
                                    message: errors.address?.postcode?.message,
                                }}
                                register={register('address.postcode')}
                                type="text"
                                id="postcode"
                                className={`bg-gray-50 border ${
                                    errors.address?.postcode ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label htmlFor="city" className="block mb-2 text-sm font-medium text-gray-900 ">
                                City
                            </label>

                            <ErrorInput
                                error={{
                                    message: errors.address?.city?.message,
                                }}
                                register={register('address.city')}
                                type="text"
                                id="city"
                                className={`bg-gray-50 border ${
                                    errors.address?.city ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                            />
                        </div>
                        <div>
                            <label htmlFor="country" className="block mb-2 text-sm font-medium text-gray-900 ">
                                Country (2-letter code, ISO 3166-1 alfa-2 )
                            </label>
                            <Controller
                                name="address.country"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        name={field.name}
                                        ref={field.ref}
                                        isClearable={true}
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                        value={countryOptions.find(c => c.value === field.value)}
                                        onChange={(val: any) => field.onChange(val.value)}
                                        options={countryOptions}
                                        className={`bg-gray-50 border ${
                                            errors.address?.country ? 'border-red-500' : 'border-gray-300'
                                        } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                    />
                                )}
                            />
                            {errors?.address?.country && (
                                <span className="text-red-500 text-sm">{errors?.address?.country.message}</span>
                            )}
                        </div>
                    </div>
                    <div className={twMerge('flex justify-start mt-10', btnContainerClassName)}>
                        <button
                            onClick={redirect}
                            className=" bg-white mr-5 btn btn-outline focus:ring-4 focus:outline-none font-medium rounded-lg text-sm lg:w-48 sm:w-auto px-5 py-2.5 text-center"
                        >
                            Cancel
                        </button>
                        <button
                            disabled={isLoading}
                            type="submit"
                            className="text-white btn bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm lg:w-48 sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            {isLoading ? 'Submitting' : 'Submit'}{' '}
                        </button>
                    </div>
                </form>
            </section>
        </Fragment>
    );
}

export default Create;
