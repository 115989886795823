import { format } from 'date-fns';
import { HTMLInputTypeAttribute, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useForm } from 'react-hook-form';
import LoaderContainer from '../components/loader';
import Modal from '../components/modal';
import Pagination from '../components/pagination';
import Table from '../components/table';
import useModal from '../hooks/useModal';
import { trpc } from '../lib/trpc';
import FilterTransaction from '../section/filterTransaction';
import ReportTransaction, { TransactionType } from '../section/report-transaction';
import { formatNumberWithCommasAndTwoDecimals } from '../utils/formatCurrency';

interface Option {
    value: string;
    label: string;
}

export type transactionEnum = 'IN_PROGRESS' | 'FAILED' | 'SUCCESS' | 'ON_HOLD' | 'LIMIT_BREACHED';

export type IFilterBaseQuery = {
    dateFrom?: string;
    dateTo?: string;
    page?: number;
    limit?: number;
    status?: string;
    type?: 'user' | 'company' | undefined;
};

type Column = {
    label: string;
    key: string;
    format?: (v1: any, v2?: any) => any;
    meta?: { type: HTMLInputTypeAttribute | 'select'; options?: Option[] } | null;
};

const transactionsColumn: Column[] = [
    { label: 'Vendor Transaction Id', key: 'vendorTransactionId' },
    { label: 'Status', key: 'status', format: (status: string) => status?.replaceAll('_', ' ') },
    { label: 'Direction', key: 'direction' },
    { label: 'Transaction Hash', key: 'transactionHash' },
    { label: 'Exchange Rate', key: 'exchangeRate' },
    { label: 'Currency Fiat', key: 'currencyFiat' },
    { label: 'Currency Crypto', key: 'currencyCrypto' },
    { label: 'Amount Fiat', key: 'amountFiat' },
    { label: 'Amount Crypto', key: 'amountCrypto' },
    { label: 'Chain', key: 'chain' },
    { label: 'Overall-Fee', key: 'fees' },
    { label: 'Shiga Fee', key: 'merchantFee' },
    { label: 'Unblock Fee', key: 'unblockFee' },
    { label: 'Created At', key: 'createdAt', format: (date: string) => format(date, 'hh:mm dd MMMM, yyyy') },
];

function Transactions () {
    const { isModalVisible, setModalClose, setModalOpen } = useModal();
    const [queryParams, setQueryParams] = useState<{
        page: number;
        limit: number;
        dateFrom?: string;
        dateTo?: string;
        status?: string;
    }>({
        page: 1,
        limit: 25,
    });
    const [transactionDetail, setTransactionDetail] = useState({} as TransactionType);

    const transactionForm = useForm({
        defaultValues: {
            dateFrom: '' as Date | string,
            dateTo: '' as Date | string,
            status: '' as transactionEnum | string,
        },
        mode: 'onChange',
    });
    
    const updateQueryParams = (params: IFilterBaseQuery) => {
        setQueryParams(prev => ({ ...prev, ...params }));
    };

    const { data: monthlyFees, isLoading: monthlyFeesLoading } = trpc.adminTransaction.getMonthlyFees.useQuery();

    const { data: txnData, isLoading, isError } = trpc.adminTransaction.listTransactions.useQuery(queryParams);
    const { meta, data } = txnData || {};

    const { data: downloadTransaction } = trpc.adminTransaction.downLoadListTransactions.useQuery({
        dateFrom: queryParams?.dateFrom,
        dateTo: queryParams?.dateTo,
        status: queryParams?.status,
    }, { refetchOnWindowFocus: false });

    // For the pagination
    const [pageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);

    const changePage = (pageNumber: number) => {
        updateQueryParams({ page: pageNumber });
    };

    const incrementPage = () => {
        updateQueryParams({ page: queryParams?.page + 1 });
        if (queryParams?.page + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };

    const decrementPage = (): void | null => {
        updateQueryParams({ page: queryParams?.page - 1 });
        if ((queryParams?.page - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
        if (queryParams?.page - 1 === 0) {
            return null;
        }
    };

    if (isError) {
        return <h2>Error...</h2>;
    }

    const submitFilter = () => {
        const { dateFrom, dateTo } = transactionForm.getValues();
        const dateFromFormatted = dateFrom ? format(dateFrom, 'yyyy-MM-dd') : '';
        const dateToFormatted = dateTo ? format(dateTo, 'yyyy-MM-dd') : '';

        updateQueryParams({
            page: 1,
            dateFrom: dateFromFormatted,
            dateTo: dateToFormatted,
            status: transactionForm.getValues().status as transactionEnum | string,
        });
        setModalClose(1);
    };

    const handleFilterModal = () => {
        setModalOpen(1);
    };

    const handleClear = () => {
        transactionForm.reset();
        updateQueryParams({ dateFrom: '', dateTo: '', page: 1, status: '' });
        setModalClose(1);
    };

    return (
        <div className="mx-5">
            <Modal title="Filter Transaction" showModal={isModalVisible(1)} closeModal={() => setModalClose(1)}>
                <FilterTransaction
                    transactionForm={transactionForm}
                    setModalClose={() => setModalClose(1)}
                    submitFilter={submitFilter}
                    handleResetForm={handleClear}
                />
            </Modal>

            <Modal title="Report Transaction" closeModal={() => setModalClose(2)} showModal={isModalVisible(2)}>
                <ReportTransaction dataReport={transactionDetail} setModalClose={() => setModalClose(2)} />
            </Modal>

            <LoaderContainer loading={isLoading}>
                <div className="flex items-center justify-between csv-download">
                    <h1 className="text-xl">
                        <span className="font-bold">Transactions</span>
                    </h1>
                    <div>
                        <button
                            onClick={handleFilterModal}
                            className="border-2 border-solid rounded-lg border-[#374151] text-white bg-[#374151] px-3 py-2 mr-3.5 font-medium hover:bg-white hover:text-[#374151] hover:font-semibold"
                        >
                            Filter Transaction
                        </button>
                        <CSVLink
                            data={downloadTransaction || []}
                            headers={transactionsColumn}
                            filename={'Transactions-data.csv'}
                            className="border-2 border-solid rounded-lg border-[#374151] text-white bg-[#374151] px-3 py-2 font-medium hover:bg-white hover:text-[#374151] hover:font-semibold"
                            target="_blank"
                        >
                            Download transactions
                        </CSVLink>
                    </div>
                </div>
                <div className="flex justify-end my-4">
                    <div className="w-[40%] p-4 overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 hover:cursor-pointer">
                        <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                            Summary of Fees Earned For The Month
                        </p>
                        <div className="grid grid-cols-3">
                            <p className="text-lg font-semibold text-gray-700">
                                <LoaderContainer loading={monthlyFeesLoading}>
                                    <b>£</b>
                                    {formatNumberWithCommasAndTwoDecimals((monthlyFees?.totalFeeGBP as number) || 0)}
                                </LoaderContainer>
                            </p>
                            <p className="text-lg font-semibold text-gray-700">
                                <LoaderContainer loading={monthlyFeesLoading}>
                                    <b>€</b>
                                    {formatNumberWithCommasAndTwoDecimals((monthlyFees?.totalFeeEUR as number) || 0)}
                                </LoaderContainer>
                            </p>
                            <p className="text-lg font-semibold text-gray-700">
                                <LoaderContainer loading={monthlyFeesLoading}>
                                    {formatNumberWithCommasAndTwoDecimals((monthlyFees?.totalFeeUSDT as number) || 0)}
                                    <b>USDT</b>
                                </LoaderContainer>
                            </p>
                        </div>
                    </div>
                </div>

                <main className="">
                    <Table
                        title=""
                        columns={transactionsColumn}
                        data={data || []}
                        useReport={true}
                        enableActions={true}
                        onRowReport={(data: any) => {
                            setModalOpen(2);
                            setTransactionDetail(data);
                        }}
                    />
                    <Pagination
                        totalPages={meta?.totalPages}
                        pageSize={meta?.limit}
                        totalRecords={meta?.totalRecords}
                        page={meta?.currentPage}
                        changePage={changePage}
                        incrementPage={incrementPage}
                        decrementPage={decrementPage}
                        pageNumberLimit={pageNumberLimit}
                        minPageNumberLimit={minPageNumberLimit}
                        maxPageNumberLimit={maxPageNumberLimit}
                    />{' '}
                </main>
            </LoaderContainer>
        </div>
    );
}

export default Transactions;
