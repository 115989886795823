import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

type DocumentProps = {
  customerName: string;
  gbpDetails?: {
    sortCode?: string;
    accountNumber?: string;
  };
  eurDetails?: {
    accountNumber?: string;
  };
  fullName: string;
};

Font.register({
    family: 'Montserrat',
    src: 'https://fonts.gstatic.com/s/montserrat/v10/zhcz-_WihjSQC0oHJ9TCYC3USBnSvpkopQaUR-2r7iU.ttf',
});

const styles = StyleSheet.create({
    body: {
        position: 'relative',
        fontFamily: 'Montserrat',
    },
    page: {
        padding: 30,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 25,
    },
    logo: {
        width: 110,
        height: 30,
    },
    date: {
        fontSize: 13,
        color: 'gray',
    },
    title: {
        fontSize: 20,
        marginBottom: 25,
        fontWeight: 'extrabold',
        color: '#101828',
        fontFamily: 'Montserrat',
    },
    customerName: {
        fontSize: 13,
        marginBottom: 15,
        lineHeight: 1.5,
        fontFamily: 'Montserrat',
        fontWeight: 'thin',
    },
    paragraph: {
        fontSize: 13,
        marginBottom: 15,
        lineHeight: 1.5,
        fontFamily: 'Montserrat',
        fontWeight: 'thin',
    },
    fullName: {
        fontSize: 13,
        marginBottom: 5,
        lineHeight: 1.5,
        fontFamily: 'Montserrat',
        fontWeight: 'thin',
    },
    accountContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        padding: 15,
        borderRadius: 5,
        backgroundColor: '#f6f4e8',
    },
    accountLabelBox: {
        borderRight: '1px solid #D0D5DD',
        paddingRight: 25,
        paddingVertical: 10,
    },
    accountDetailsBox: {
        paddingLeft: 25,
    },
    accountDetails: {
        fontSize: 12,
        fontFamily: 'Montserrat',
    },
    accountDetailsA: {
        fontSize: 12,
        fontWeight: 'light',
        color: '#182230',
    },
    accountDetailsB: {
        fontSize: 12,
        marginTop: 5,
        fontWeight: 'light',
        color: '#182230',
    },
    accountDetailsC: {
        fontSize: 8,
        marginTop: 5,
        color: '#475467',
        fontWeight: 'normal',
    },
    accountLabel: {
        fontWeight: 'semibold',
    },
    paragraphHow: {
        fontWeight: 'extrabold',
        marginVertical: 10,
        color: '#101828',
        fontSize: 13,
    },
    footer: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    footerText: {
        fontSize: 13,
        marginBottom: 6,
        color: '#101828',
        fontFamily: 'Montserrat',
    },
    footerLogo: {
        backgroundColor: '#EFF8FF',
        flexDirection: 'row',
        paddingVertical: 6,
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 8,
    },
    shigaLink: {
        color: '#101828',
        fontSize: 12,
        fontFamily: 'Montserrat',
    },
});

function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const day = date.getDate();
  const daySuffix = getDaySuffix(day);
  const formattedDate = date.toLocaleDateString("en-GB", options);

  return formattedDate.replace(day.toString(), `${day}${daySuffix}`);
}

function getDaySuffix(day: number): string {
  if (day > 3 && day < 21) return "th"; // handle teens (11th, 12th, 13th, etc.)
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

const ShigaDocument = ({
  customerName,
  fullName,
  gbpDetails,
  eurDetails,
}: DocumentProps) => (
  <Document>
    <Page size="A4">
      <View style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src="/assets/png/shigaLogoForPdf.png" />
          <Text style={styles.date}>{formatDate(new Date())}</Text>
        </View>
        <Text style={styles.title}>Your Shiga virtual fiat accounts</Text>
        <Text style={styles.customerName}>Dear {customerName},</Text>
        <Text style={styles.paragraph}>
          Welcome to Shiga! We are excited to have you on board as a valued
          customer. We are pleased to inform you that your Know Your Customer
          (KYC) verification has been successfully completed, and your virtual
          accounts have been created.
        </Text>
        <Text style={styles.paragraph}>
          This is to certify that we have opened with our payment services
          provider, OpenPayd Financial Services Malta Limited, a dedicated
          payment account in the name of our client:
        </Text>
        <Text style={styles.fullName}>
          Name: <Text style={{ fontWeight: "bold" }}>{fullName}.</Text>
        </Text>
        <View style={styles.accountContainer}>
          <View style={styles.accountLabelBox}>
            <Text style={[styles.accountDetails, styles.accountLabel]}>
              GBP account details
            </Text>
          </View>
          <View style={styles.accountDetailsBox}>
            <Text style={[styles.accountDetails, styles.accountDetailsA]}>
              Sort code: {gbpDetails?.sortCode}
            </Text>
            <Text style={[styles.accountDetails, styles.accountDetailsB]}>
              Account number: {gbpDetails?.accountNumber}
            </Text>
            <Text style={[styles.accountDetails, styles.accountDetailsC]}>
              supports local GBP payments, including Faster Payments
            </Text>
          </View>
        </View>

        <View style={styles.accountContainer}>
          <View style={styles.accountLabelBox}>
            <Text style={[styles.accountDetails, styles.accountLabel]}>
              EUR account details
            </Text>
          </View>
          <View style={styles.accountDetailsBox}>
            <Text style={[styles.accountDetails, styles.accountDetailsA]}>
              Account number:
            </Text>
            <Text style={[styles.accountDetails, styles.accountDetailsB]}>
              {eurDetails?.accountNumber}
            </Text>
            <Text style={[styles.accountDetails, styles.accountDetailsC]}>
              supports SEPA and SEPA Instant payments
            </Text>
          </View>
        </View>

        <Text style={styles.paragraphHow}>How it works</Text>
        <Text style={styles.paragraph}>
          When you send money to these virtual accounts in GBP or EUR (portal
          in), the funds will be instantly converted to USDT/USDC and credited
          to your saved stablecoin wallet on Shiga. Similarly, when you portal
          out of stablecoins, we will convert the stablecoins to GBP or EUR and
          transfer the equivalent amount from these virtual accounts to your
          saved bank accounts on Shiga.
        </Text>
        <Text style={styles.paragraph}>
          If you have any questions or need assistance, please contact
          help@shiga.io. We look forward to supporting you on your financial
          journey.
        </Text>
        <View style={styles.footer}>
          <View>
            <Text style={styles.footerText}>Kind regards,</Text>
            <Text style={styles.footerText}>Shiga Team</Text>
          </View>
          <View style={styles.footerLogo}>
            <Text style={styles.shigaLink}>shiga.io</Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default ShigaDocument;
