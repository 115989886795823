import { format } from 'date-fns';
import { HTMLInputTypeAttribute, SyntheticEvent, useEffect, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import OtpInput from 'react-otp-input';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import Alert from '../components/alert';
import CopyToClipboardInput from '../components/clipboard';
import CopyButton from '../components/copyBtn';
import EditableText from '../components/editableText';
import LoaderContainer from '../components/loader';
import Modal from '../components/modal';
import { SessionValidityChecker } from '../components/SessionValidityChecker';
import Table from '../components/table';
import { configs } from '../config';
import useAlert from '../hooks/useAlert';
import useModal from '../hooks/useModal';
import { trpc } from '../lib/trpc';
import AddBankDEstinationForm from '../section/add-bank-trf';
import KycEvaluation from '../section/kycEvaluation';
import BankDownloadButton from '../section/pdfDocuments/bankDownloadButton';
import WalletDownloadButton, { GroupedWalletData } from '../section/pdfDocuments/walletDownloadButton';
import SetCorporateAdminModal from '../section/setCorporateAdmin';
import UserSetUnblockBankDefault from '../section/settingBankDefault';
import { isEvmAddress } from '../utils/isEvmAddress';
import { isSolanaAddress } from '../utils/isSolanaAddress';
import { UnblockBankObject } from './userView';

interface Option {
    value: string;
    label: string;
}

type Column = {
    label: string;
    key: string;
    format?: (v1: any, v2?: any) => any;
    meta?: { type: HTMLInputTypeAttribute | 'select'; options?: Option[] } | null;
};

type FeeOpt = 'add' | 'subtract' | '';
type Currency = 'EUR' | 'GBP' | 'USD' | '';
type Direction = 'OFFRAMP' | 'ONRAMP' | '';

const transactionColumn: Column[] = [
    { label: 'Created At', key: 'created_at', format: (date: string) => format(date, 'hh:mm dd MMMM, yyyy') },
    { label: 'Status', key: 'status', format: (status: string) => status?.replaceAll('_', ' ') },
    { label: 'Direction', key: 'direction' },
    { label: 'Input Amount', key: 'input.amount' },
    { label: 'Input Currency', key: 'input.currency' },
    { label: 'Output Amount', key: 'output.amount' },
    { label: 'Output Currency', key: 'output.currency' },
    { label: 'Input Transaction ID', key: 'input.transaction_id' },
    { label: 'Output Transaction ID', key: 'output.transaction_id' },
    { label: 'User UUID', key: 'user_uuid' },
];

const unblockBankAccountsColumns: Column[] = [
    { label: 'Account Number', key: 'account_number' },
    { label: 'IBAN', key: 'iban' },
    { label: 'BIC', key: 'bic' },
    { label: 'Sort Code', key: 'sort_code' },
    { label: 'Bank Country', key: 'bank_country' },
    { label: 'Currency', key: 'currency' },
    { label: 'Country', key: 'country' },
];

const unblockWalletColumns: Column[] = [
    { label: 'Network', key: 'network' },
    {
        label: 'Address',
        key: 'address',
    },
];

const bankDestinationAccounts: Column[] = [
    { label: 'Account Number', key: 'account_number' },
    { label: 'Account Name', key: 'account_name' },
    { label: 'Main Beneficiary', key: 'main_beneficiary' },
    { label: 'IBAN', key: 'iban' },
    { label: 'BIC', key: 'bic' },
    { label: 'Sort Code', key: 'sort_code' },
    { label: 'Bank Code', key: 'bank_code' },
    { label: 'Bank Country', key: 'bank_country' },
    { label: 'Currency', key: 'currency' },
    { label: 'Country', key: 'country' },
];

const customFeeConfig = [
    {
        label: 'Input Currency',
        key: 'input_currency',
    },
    {
        label: 'Output Currency',
        key: 'output_currency',
    },
    {
        label: 'Direction',
        key: 'direction',
    },
    {
        label: 'Percentage Fee',
        key: 'percentage_fee',
    },
    {
        label: 'Fee Op Type',
        key: 'fee_op_type',
    },
];

const tokenPreferencesColumn: Column[] = [
    {
        label: 'Currency Name',
        key: 'currency',
        meta: null,
    },
    {
        label: 'Chain',
        key: 'chain',
        meta: {
            type: 'select',
            options: [
                { value: 'polygon', label: 'polygon' },
                { value: 'celo', label: 'celo' },
                { value: 'arbitrum', label: 'arbitrum' },
                { value: 'optimism', label: 'optimism' },
                { value: 'tron', label: 'tron' },
                { value: 'mainnet', label: 'mainnet' },
                { value: 'solana', label: 'solana' },
                { value: 'base', label: 'base' },
            ],
        },
    },
    {
        label: 'Token',
        key: 'token',
        meta: {
            type: 'select',
            options: [
                { value: 'usdc', label: 'usdc' },
                { value: 'usdce', label: 'usdce' },
                { value: 'usdt', label: 'usdt' },
                { value: 'cEUR', label: 'cEUR' },
                { value: 'cUSD', label: 'cUSD' },
                { value: 'agEUR', label: 'agEUR' },
            ],
        },
    },
];

const walletAddressSchema = z.object({
    walletAddress: z.string().refine(value => isEvmAddress(value), {
        message: 'Invalid Ethereum address',
    }),
});

const targetSolanaAddressSchema = z.object({
    targetSolanaAddress: z.preprocess(
        value => {
            if ((typeof value === 'string' && value.trim() === '') || value === undefined || value == null) {
                return undefined;
            }
            return value;
        },
        z
            .string()
            .optional()
            .refine(value => value === undefined || isSolanaAddress(value), {
                message: 'Invalid Solana address',
            }),
    ),
});

function CorporateView() {
    const [fee, setFee] = useState('');
    const [otp, setOtp] = useState('');
    const [inputCurrency, setInputCurrency] = useState<Currency>('');
    const [outputCurrency, setOutputCurrency] = useState<Currency>('');
    const [direction, setDirection] = useState<Direction>('');
    const [feeOpType, setFeeOpType] = useState<FeeOpt>('');
    const { showAlert, alert } = useAlert();
    const [currency, setCurrency] = useState<'GBP' | 'EUR'>('EUR');
    const params = useParams<{ id: string }>();
    const [holdUnblockBankData, setHoldUnblockBankData] = useState<UnblockBankObject | null>(null);


    const { isModalVisible, setModalClose, setModalOpen } = useModal();
    if (!params?.id && params.id === undefined) {
        return null;
    }
    const {
        data: companyData,
        isLoading: corporateIsLoading,
        refetch: refetchCorporateData,
    } = trpc.corporate.getById.useQuery(+params?.id);

    const administratorId = companyData?.administratorId?.toString() ?? '';
    const {
        data: userData,
        isLoading: usersLoading,
        refetch: refetchUserData,
        isFetching: isFetchingUser,
    } = trpc.admin.getById.useQuery(administratorId, {
        enabled: !!companyData && !!administratorId,
    });

    const { data: userLastSession, refetch: refetchUserLastSession } = trpc.admin.getUserLastSession.useQuery(
        companyData?.administratorId as number,
        { enabled: !!companyData?.administratorId },
    );

    const { data: unblockCorporataData } = trpc.corporate.getUnblockByUuid.useQuery(
        { userId: +administratorId, companyId: +params?.id },
        {
            enabled: !!companyData && !!administratorId,
        },
    );

    const {
        data: unblockBankAccounts,
        isLoading: unblockBankAccountsIsLoading,
        refetch: refetchUnblockBankAccounts,
        isFetching: isFetchingUnblockBankAccounts,
    } = trpc.corporate.listUnblockBankAccounts.useQuery(
        { userId: +administratorId, companyId: +params?.id },
        {
            enabled: !!companyData && !!administratorId,
        },
    );

    const { data: unblockCorporateData, isLoading: isLoadingUnblockCorporateData } =
        trpc.corporate.getUnblockCorporateData.useQuery(
            { userId: +administratorId, companyId: +params?.id },
            {
                enabled: !!companyData && !!administratorId,
            },
        );

    const isInitializingUnblockBankAccounts = unblockBankAccountsIsLoading && isFetchingUnblockBankAccounts;

    const {
        data: unblockWallets,
        isLoading: unblockWalletsLoading,
        isFetching: isFetchingUnblockWallet,
    } = trpc.corporate.listUnblockWallets.useQuery(
        { userId: +administratorId, companyId: +params?.id },
        {
            enabled: !!params.id && !!administratorId,
        },
    );

    const { data: corporateDocs, isLoading: isLoadingCorporateDocs } = trpc.corporate.fetchCorporateDoc.useQuery({
        corporateId: +params?.id,
    });

    const isInitializingUnblockWallets = unblockWalletsLoading && isFetchingUnblockWallet;

    const {
        data: kybUrl,
        isLoading: isLoadingkybUrl,
        isFetching: isFetchingkybUrl,
    } = trpc.corporate.generateKybPath.useQuery(
        { userId: +administratorId, companyId: +params?.id },
        {
            enabled: !!companyData && !!administratorId,
        },
    );

    const isInitializingKybUrl = isLoadingkybUrl && isFetchingkybUrl;

    const {
        data: tokenPreferences,
        refetch: RefetchtokenPreferences,
        isLoading: isLoadingTokenPreferences,
        isFetching: isFetchingTokenPreference,
    } = trpc.corporate.getTokenPreferences.useQuery(
        { userId: +administratorId, companyId: +params?.id },
        {
            enabled: !!params.id && !!administratorId,
        },
    );

    const isInitializingTokenPreferences = isLoadingTokenPreferences && isFetchingTokenPreference;

    const {
        data: transactionData,
        isLoading: transactionIsLoading,
        isFetching: transactionsFetching,
    } = trpc.corporate.listUnblockTransactions.useQuery(
        { userId: +administratorId, companyId: +params.id },
        {
            enabled: !!userData && !!params.id,
        },
    );
    const isInitializingTransaction = transactionIsLoading && transactionsFetching;

    const {
        data: unblockDestBankAcct,
        isLoading: uIsLoadingUnblockDesBankAcct,
        isFetching: isFetchingUnblockDestBankAcct,
        isRefetching: isRefetchingUnblockDestBankAcct,
    } = trpc.corporate.listUnblockDestinationBankAccounts.useQuery(
        { userId: +administratorId, companyId: +params.id },
        {
            enabled: !!params.id && !!administratorId,
        },
    );

    const isInitializingUnblockDestBankAcct = uIsLoadingUnblockDesBankAcct && isFetchingUnblockDestBankAcct;

    useEffect(() => {
        if (companyData) {
            refetchUserData();
        }
    }, [companyData, refetchUserData]);

    const { mutate: setCorporateAdmin, isLoading: isLoadingCorporateAdmin } =
        trpc.corporate.setCorporateAdministrator.useMutation({
            onError: (res: any) => {
                showAlert(res?.message, 'error');
            },
            onSuccess: () => {
                showAlert('Corporate Admin successfully set', 'success');
                setModalClose(1);
            },
        });
    const { mutate: updateCorporateMutation } = trpc.corporate.updateCoporate.useMutation({
        onError: error => {
            showAlert(error.message, 'error');
            refetchCorporateData();
        },
        onSuccess: () => {
            showAlert('User successfully updated', 'success');
            refetchCorporateData();
        },
    });

    const { mutate: createBankMutation, isLoading: isLoadingCreatingBankAcct } =
        trpc.corporate.createUnblockBankAccount.useMutation({
            onSuccess: () => {
                showAlert('Unblock bank account sucessfully created!', 'success', 5000);
                refetchUnblockBankAccounts();
            },
            onError: (error: any) => {
                showAlert(error.message, 'error');
            },
        });
    const { mutate: setUnblockTokenPreferences } = trpc.corporate.setUnblockTokenPreferences.useMutation({
        onError: (e: any) => {
            showAlert(e?.message, 'error');
        },
        onSuccess: () => {
            showAlert('Preferences successfully Updated', 'success');
            RefetchtokenPreferences();
        },
    });

    const onModalOpen = (value: number) => {
        setModalOpen(value);
    };

    const onSubmit = (userId: string) => {
        params?.id && setCorporateAdmin({ userId: +userId, companyId: +params?.id });
    };

    const handleChangeCurrency = (event: SyntheticEvent) => {
        const target = event.target as typeof event.target & { value: 'GBP' | 'EUR' };
        setCurrency(target.value);
    };

    const handleSubmit = () => {
        params.id && createBankMutation({ companyId: +params.id, currency, userId: +administratorId });
    };

    const onClickSaveTokenPreferences = (row: any) => {
        params?.id && setUnblockTokenPreferences({ userId: +administratorId, ...row, companyId: +params?.id });
    };

    const updateCorporate = (type: string) => {
        return function (value: string) {
            params?.id && updateCorporateMutation({ companyId: +params.id, userId: +administratorId, [type]: value });
        };
    };

    const { mutate, isLoading } = trpc.corporate.updateCorporateCustomerFee.useMutation({
        onSuccess: () => {
            showAlert('Corporate fee successfully updated', 'success');
            setInputCurrency('');
            setOutputCurrency('');
            setDirection('');
            setFeeOpType('');
            setFee('');
        },
        onError: () => {
            showAlert('Corporate fee failed to update', 'error');
        },
    });

    const handleConfigSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        if (!params?.id || !inputCurrency || !outputCurrency || !direction || !feeOpType) return;
        if (+administratorId === 0) {
            showAlert('Please link a user to Corporate account', 'error');
        } else {
            const feeData = {
                companyId: +params?.id,
                userId: +administratorId,
                fee: parseFloat(fee),
                input_currency: inputCurrency,
                output_currency: outputCurrency,
                direction,
                fee_op_type: feeOpType,
            };
            mutate(feeData);
        }
    };

    const textToCopyData = [
        {
            walletAddress: companyData ? companyData?.walletAddress : null,
            solanaWalletAddress: companyData ? companyData?.targetSolanaAddress : null,
            unblockWallets: companyData && !isInitializingUnblockWallets ? unblockWallets : null,
            unblockBankAccts: companyData && !isInitializingUnblockBankAccounts ? unblockBankAccounts : null,
            unblockDestBankAcct: companyData && !isInitializingUnblockDestBankAcct ? unblockDestBankAcct : null,
            currency: currency,
        },
    ];

    const { mutate: onExchangeOtpForSessionId } = trpc.admin.exchangeOtpForSessionId.useMutation({
        onSuccess: () => {
            refetchUserLastSession();
            RefetchtokenPreferences();
            setOtp('');
        },
        onError: e => {
            showAlert(e?.message, 'error');
        },
    });

    const { mutate: onTriggerOtp, isLoading: IsOtpprocessing } = trpc.admin.triggerOtp.useMutation({
        onSuccess: () => {
            showAlert('OTP sent successfully', 'success');
        },
        onError: error => {
            showAlert(error.message, 'error');
        },
    });

    const triggerOTP = () => {
        if (companyData?.administratorId) onTriggerOtp(+companyData?.administratorId);
    };

    const handleChange = (otp: string) => {
        setOtp(otp);
        // Check if the OTP otp length is 6
        if (otp.length === 6 && companyData?.administratorId) {
            // Trigger API call to send OTP
            onExchangeOtpForSessionId({ id: +companyData?.administratorId, otp });
        }
    };

    const isEmpty = (data: any) => Array.isArray(data) && data.length === 0;

    return (
        <div>
            {alert && <Alert classes={'w-[20%] ml-auto'} message={alert.message} event={alert.type} />}
            <Modal
                title="Set corporate administrator"
                showModal={isModalVisible(1)}
                closeModal={() => setModalClose(1)}
            >
                <SetCorporateAdminModal isLoading={isLoadingCorporateAdmin} onSubmit={onSubmit} />
            </Modal>
            <Modal
                title="Add Destination Bank Account"
                showModal={isModalVisible(2)}
                closeModal={() => setModalClose(2)}
            >
                <AddBankDEstinationForm
                    isCorporate
                    administratorId={administratorId}
                    setModalOpen={() => setModalOpen(2)}
                    setModalClose={() => setModalClose(2)}
                />
            </Modal>
            <Modal title="Evaluate Kyc Application" showModal={isModalVisible(4)} closeModal={() => setModalClose(4)}>
                <KycEvaluation setModalClose={() => setModalClose(4)} id={+params.id} />
            </Modal>
            <Modal title="Unblock Bank Account" showModal={isModalVisible(5)} closeModal={() => setModalClose(5)}>
                <UserSetUnblockBankDefault
                    setModalClose={() => setModalClose(5)}
                    id={+params.id}
                    incomingBankData={holdUnblockBankData as UnblockBankObject}
                    showAlert={showAlert}
                />
            </Modal>
            <main className="max-w-screen-lg p-4 mx-auto">
                <div className="py-10">
                    <div className="flex items-center justify-between mb-12 header-top">
                        <h1 className="text-2xl font-bold capitalize">{companyData?.legalName}</h1>
                        {companyData && <CopyButton textToCopy={textToCopyData} extraClass={false} />}
                    </div>
                    <section>
                        <div className="px-5 py-2 mb-5 bg-slate-500">
                            <p className="block text-xl font-bold text-white">Shiga</p>
                        </div>

                        <LoaderContainer loading={corporateIsLoading}>
                            <div className="mb-4">
                                <p className="font-bold">Legal Name:</p>
                                <p className="">{companyData?.legalName}</p>
                            </div>
                            <div className="mb-4">
                                <p className="font-bold">Registration Number:</p>
                                <p className="">{companyData?.registrationNumber}</p>
                            </div>

                            <div className="mb-4">
                                <p className="font-bold">Type of Business:</p>
                                <p className="">{companyData?.type.replaceAll('_', ' ')}</p>
                            </div>
                            <div className="mb-4">
                                <p className="font-bold">Ethereum Wallet Address:</p>
                                {companyData && (
                                    <EditableText
                                        name="walletAddress"
                                        validationSchema={walletAddressSchema}
                                        value={companyData.walletAddress ?? ''}
                                        onChange={updateCorporate('walletAddress')}
                                    />
                                )}
                            </div>
                            <div className="mb-4">
                                <p className="font-bold">Solana Wallet Address:</p>
                                {companyData && (
                                    <EditableText
                                        name="targetSolanaAddress"
                                        validationSchema={targetSolanaAddressSchema}
                                        value={companyData.targetSolanaAddress ?? ''}
                                        onChange={updateCorporate('targetSolanaAddress')}
                                    />
                                )}
                            </div>

                            <div className="mb-4">
                                <p className="font-bold">Country:</p>
                                <p className="">{companyData?.country}</p>
                            </div>
                            <div className="mb-4">
                                <p className="font-bold">Created At:</p>
                                <p className="">
                                    {companyData && format(new Date(companyData?.createdAt), 'dd MMMM, yyyy')}
                                </p>
                            </div>
                            <hr className="my-5 border-2 border-gray-300" />
                            <div className="my-5">
                                <p className="mb-4 text-xl font-bold">Corporate Admin</p>
                                <LoaderContainer loading={usersLoading}>
                                    {userData && !usersLoading && !isFetchingUser ? (
                                        <ul>
                                            <li>
                                                <p className="font-bold">User Name:</p>
                                                <p className="capitalize">{`${userData?.firstName} ${userData?.lastName}`}</p>
                                            </li>
                                            <li>
                                                <p className="font-bold">User Email:</p>
                                                <p className="capitalize">{userData?.email}</p>
                                            </li>
                                        </ul>
                                    ) : (
                                        <button className="mb-5 btn btn-primary" onClick={() => onModalOpen(1)}>
                                            Set corporate administrator
                                        </button>
                                    )}
                                </LoaderContainer>
                            </div>

                            {userData && (
                                <>
                                    <hr className="my-5 border-2 border-gray-300" />
                                    <div className="p-4 mb-10 bg-gray-100 rounded-lg shadow-md">
                                        <p className="text-xl font-bold">
                                            Session Last Updated:
                                            <span className="ml-2 text-primary">
                                                {userLastSession?.createdAt
                                                    ? format(
                                                          new Date(userLastSession?.updatedAt),
                                                          'hh:mm a, dd MMMM, yyyy',
                                                      )
                                                    : '-'}
                                                {/* TODO: x hours and y minutes ago */}
                                            </span>
                                            <SessionValidityChecker lastUpdated={userLastSession?.updatedAt} />
                                        </p>
                                        <p>{userLastSession?.sessionId}</p>
                                        <div className="flex justify-between">
                                            <small>
                                                This is needed before making edits to the user. If the 'Session Last
                                                Updated' is more than 4 hours ago, a new OTP needs to be sent.
                                                <br />
                                                First, trigger OTP. Then, check the inbox and enter the OTP here. After
                                                pasting the number, no additional keys need to be pressed.
                                            </small>
                                            <button className="ml-5 btn btn-outline" onClick={triggerOTP}>
                                                {IsOtpprocessing ? 'Processing' : 'Trigger OTP'}
                                            </button>
                                        </div>
                                        <div className="mb-5">
                                            <OtpInput
                                                inputStyle={{
                                                    width: '1.5rem',
                                                    height: '1.5rem',
                                                    margin: '0 1rem',
                                                    fontSize: '1rem',
                                                    borderRadius: '4px',
                                                    border: '1px solid rgba(0, 0, 0, 0.3)',
                                                }}
                                                value={otp}
                                                onChange={handleChange}
                                                numInputs={6}
                                                renderSeparator={<span>-</span>}
                                                renderInput={props => <input {...props} />}
                                            />
                                        </div>
                                    </div>
                                    <hr className="my-5 border-2 border-gray-300" />
                                </>
                            )}
                        </LoaderContainer>
                    </section>

                    <section className="container py-8 mx-auto">
                        <LoaderContainer loading={isLoadingCorporateDocs}>
                            <h2 className="mb-4 text-2xl font-bold">
                                KYC documents <span className="text-xs text-red-600"> (Click images to zoom)</span>
                            </h2>
                            <div className="grid grid-cols-2 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
                                <div className="rounded-lg aspect-w-1 aspect-h-1">
                                    <Zoom>
                                        <img
                                            src={
                                                corporateDocs?.businessRegistrationUpload
                                                    ? configs.s3BaseUrl + corporateDocs?.businessRegistrationUpload
                                                    : 'https://via.placeholder.com/50'
                                            }
                                            alt="Image 1"
                                            className="object-cover w-full h-full"
                                        />
                                    </Zoom>
                                    <p className="mt-2 font-bold text-center text-gray-700">
                                        Company’s official registration document issued by the relevant government
                                        authority.
                                    </p>
                                    <a
                                        href={configs.s3BaseUrl + corporateDocs?.businessRegistrationUpload}
                                        download={`businessRegistrationUpload/${companyData?.id}.png`}
                                        className="block mt-4 text-center text-blue-500 hover:text-blue-700"
                                    >
                                        Download
                                    </a>
                                </div>
                                <div className="rounded-lg aspect-w-1 aspect-h-1">
                                    <Zoom>
                                        <img
                                            src={
                                                corporateDocs?.corporateGovernanceUpload
                                                    ? configs.s3BaseUrl + corporateDocs?.corporateGovernanceUpload
                                                    : 'https://via.placeholder.com/50'
                                            }
                                            alt="Image 2"
                                            className="object-cover w-full h-full"
                                        />
                                    </Zoom>
                                    <p className="mt-2 font-bold text-center text-gray-700">
                                        Company’s bylaws or articles of association, which outline the internal rules
                                        and regulations.
                                    </p>
                                    <a
                                        href={
                                            'https://shiga-kyc-files-dev.s3.amazonaws.com/corporateGovernanceUpload/2c0e91d0-c552-4906-9200-d921db90d9d3.image/jpeg'
                                        }
                                        download="corporate_governance.pdf"
                                        className="block mt-4 text-center text-blue-500 hover:text-blue-700"
                                    >
                                        Download
                                    </a>
                                </div>
                                <div className="rounded-lg aspect-w-1 aspect-h-1">
                                    <Zoom>
                                        <img
                                            src={
                                                corporateDocs?.financialStatementsUpload
                                                    ? configs.s3BaseUrl + corporateDocs?.financialStatementsUpload
                                                    : 'https://via.placeholder.com/50'
                                            }
                                            alt="Image 3"
                                            className="object-cover w-full h-full"
                                        />
                                    </Zoom>
                                    <p className="mt-2 font-bold text-center text-gray-700">
                                        Bank statement (no more than three months old) confirming your company’s bank
                                        account details.
                                    </p>
                                    <a
                                        href={
                                            corporateDocs?.financialStatementsUpload
                                                ? configs.s3BaseUrl + corporateDocs?.financialStatementsUpload
                                                : 'https://via.placeholder.com/50'
                                        }
                                        download={`businessRegistrationUpload/${companyData?.id}.png`}
                                        className="block mt-4 text-center text-blue-500 hover:text-blue-700"
                                    >
                                        Download
                                    </a>
                                </div>
                                <div className="rounded-lg aspect-w-1 aspect-h-1">
                                    <Zoom>
                                        <img
                                            src={
                                                corporateDocs?.ownershipInformationUpload
                                                    ? configs.s3BaseUrl + corporateDocs?.ownershipInformationUpload
                                                    : 'https://via.placeholder.com/50'
                                            }
                                            alt="Image 4"
                                            className="object-cover w-full h-full"
                                        />
                                    </Zoom>
                                    <p className="mt-2 font-bold text-center text-gray-700">
                                        List of all shareholders with names and ownership percentages, plus a signed
                                        ownership structure chart
                                    </p>
                                    <a
                                        href={configs.s3BaseUrl + corporateDocs?.ownershipInformationUpload}
                                        download={`businessRegistrationUpload/${companyData?.id}.png`}
                                        className="block mt-4 text-center text-blue-500 hover:text-blue-700"
                                    >
                                        Download
                                    </a>
                                </div>
                                <div className="rounded-lg aspect-w-1 aspect-h-1">
                                    <Zoom>
                                        <img
                                            src={
                                                corporateDocs?.licenseUpload
                                                    ? configs.s3BaseUrl + corporateDocs?.licenseUpload
                                                    : 'https://via.placeholder.com/50'
                                            }
                                            alt="Image 5"
                                            className="object-cover w-full h-full"
                                        />
                                    </Zoom>
                                    <p className="mt-2 font-bold text-center text-gray-700">
                                        Licenses, permits, or special registrations issued by regulatory authorities.
                                    </p>
                                    <a
                                        href={configs.s3BaseUrl + corporateDocs?.licenseUpload}
                                        download={`businessRegistrationUpload/${companyData?.id}.png`}
                                        className="block mt-4 text-center text-blue-500 hover:text-blue-700"
                                    >
                                        Download
                                    </a>
                                </div>
                                <div className="rounded-lg aspect-w-1 aspect-h-1">
                                    <Zoom>
                                        <img
                                            src={
                                                corporateDocs?.amlPolicyUpload
                                                    ? configs.s3BaseUrl + corporateDocs?.amlPolicyUpload
                                                    : 'https://via.placeholder.com/50'
                                            }
                                            alt="Image 6"
                                            className="object-cover w-full h-full"
                                        />
                                    </Zoom>
                                    <p className="mt-2 font-bold text-center text-gray-700">
                                        Company’s Anti-Money Laundering (AML) and Combating the Financing of Terrorism
                                        (CFT) status
                                    </p>
                                    <a
                                        href={configs.s3BaseUrl + corporateDocs?.amlPolicyUpload}
                                        download={`businessRegistrationUpload/${companyData?.id}.png`}
                                        className="block mt-4 text-center text-blue-500 hover:text-blue-700"
                                    >
                                        Download
                                    </a>
                                </div>
                            </div>
                        </LoaderContainer>
                        <div className="my-7">
                            <div className="px-5 py-2 bg-slate-500">
                                <p className="block text-xl font-bold text-white">KYC Compliance Evaluation</p>
                            </div>
                            <div className="flex items-center justify-between mt-3">
                                <p>Evaluate and review customer submitted KYC documents</p>
                                <button className="ml-5 btn btn-outline" onClick={() => setModalOpen(4)}>
                                    Evaluate
                                </button>
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="px-5 py-2 mb-5 bg-slate-500">
                            <p className="block text-xl font-bold text-white">Unblock</p>
                        </div>
                        <div tabIndex={0} className="collapse bg-base-200">
                            <div className="mb-4 text-xl font-bold cursor-pointer collapse-title">
                                Unblock Corporate Data <span className="text-xs text-red-600">(Click to View)</span>
                            </div>
                            <div className="p-4 bg-white border border-gray-200 rounded-lg collapse-content">
                                <LoaderContainer loading={isLoadingUnblockCorporateData}>
                                    <pre className="p-4 font-mono text-sm text-gray-800 bg-gray-100 rounded-lg">
                                        {JSON.stringify(unblockCorporateData, null, 2)}
                                    </pre>
                                </LoaderContainer>
                            </div>
                        </div>
                        <LoaderContainer loading={isInitializingTransaction}>
                            <div className="flex mb-5">
                                {!isInitializingTransaction && (
                                    <Table
                                        title="Unblock Transactions"
                                        columns={transactionColumn}
                                        data={transactionData}
                                    />
                                )}
                            </div>
                        </LoaderContainer>
                        <hr className="my-5 border-2 border-gray-300" />
                        <div className="flex justify-end">
                            <button onClick={() => setModalOpen(2)} className="btn">
                                Add Destination Bank Account
                            </button>
                        </div>
                        <LoaderContainer loading={isInitializingUnblockDestBankAcct || isRefetchingUnblockDestBankAcct}>
                            <div className="flex mb-5">
                                <Table
                                    title="Unblock Destination Bank Accounts"
                                    subtitle="for off-ramping"
                                    columns={bankDestinationAccounts}
                                    data={unblockDestBankAcct || []}
                                    enableActions
                                    useThreeDots
                                    onRowView={data => {
                                        setModalOpen(5);
                                        setHoldUnblockBankData(data);
                                    }}
                                />
                            </div>
                        </LoaderContainer>
                        <hr className="my-5 border-2 border-gray-300" />
                        <div className="p-4 mb-10 bg-gray-100 rounded-lg shadow-md">
                            <p className="mb-4 text-xl">
                                <span className="font-bold">Create Unblock Bank Account</span>
                                <span> for on-ramping</span>
                            </p>
                            <div>
                                <p>Currency:</p>
                                <select
                                    className="p-2 mr-3 border border-gray-300 rounded-md"
                                    value={currency}
                                    onChange={handleChangeCurrency}
                                >
                                    <option value="EUR">EUR</option>
                                    <option value="GBP">GBP</option>
                                </select>
                                <button
                                    className="ml-5 btn btn-outline"
                                    onClick={handleSubmit}
                                    disabled={isLoadingCreatingBankAcct}
                                >
                                    {isLoadingCreatingBankAcct ? 'Submitting...' : 'Submit'}
                                </button>
                            </div>
                        </div>
                        <LoaderContainer loading={isInitializingUnblockBankAccounts}>
                            {unblockBankAccounts && isEmpty(unblockBankAccounts) === true ? null : (
                                <div className="flex justify-end mb-2">
                                    <BankDownloadButton
                                        bankData={unblockBankAccounts}
                                        fullName={companyData?.legalName as string}
                                        firstName={userData?.firstName as string}
                                    />
                                </div>
                            )}
                            <div className="flex mb-5">
                                <Table
                                    title="Unblock Bank Accounts"
                                    subtitle="for on-ramping"
                                    columns={unblockBankAccountsColumns}
                                    data={unblockBankAccounts || []}
                                />
                            </div>
                        </LoaderContainer>
                        <LoaderContainer loading={isInitializingUnblockWallets}>
                            {unblockWallets &&
                            (isEmpty(unblockWallets) === true || unblockWallets === undefined) ? null : (
                                <div className="flex justify-end mt-10">
                                    <WalletDownloadButton
                                        wallData={unblockWallets as GroupedWalletData[]}
                                        firstName={userData?.firstName as string}
                                        fullName={companyData?.legalName as string}
                                    />
                                </div>
                            )}
                            <div className="flex mb-4">
                                <Table
                                    title="Unblock Wallets"
                                    subtitle="for off-ramping"
                                    columns={unblockWalletColumns}
                                    data={unblockWallets || []}
                                />
                            </div>
                        </LoaderContainer>
                        <LoaderContainer loading={isInitializingTokenPreferences}>
                            <div className="flex flex-col mb-5">
                                {!isInitializingTokenPreferences && (
                                    <Table
                                        enableActions
                                        onClickSave={onClickSaveTokenPreferences}
                                        title="Unblock Token Preferences"
                                        subtitle="for on-ramping"
                                        columns={tokenPreferencesColumn}
                                        data={tokenPreferences}
                                    />
                                )}
                            </div>
                        </LoaderContainer>
                        <LoaderContainer loading={isInitializingKybUrl}>
                            {unblockCorporataData?.status !== 'FULL_USER' && (
                                <div className="mb-4">
                                    <p className="font-bold">Unblock KYB URL:</p>
                                    <div className="mb-5 text-gray-600">
                                        {kybUrl && <CopyToClipboardInput url={kybUrl} />}
                                    </div>
                                    <p className="font-bold">KYB Status:</p>
                                    <p className="text-gray-600">
                                        {unblockCorporataData?.status
                                            ? unblockCorporataData?.status.replaceAll('_', ' ')
                                            : 'N/A (kyb application not yet created)'}
                                    </p>
                                </div>
                            )}
                        </LoaderContainer>
                        <LoaderContainer loading={isLoadingUnblockCorporateData}>
                            <div className="mt-[30px]">
                                <Table
                                    title="Custom Fee Config"
                                    subtitle=""
                                    columns={customFeeConfig}
                                    data={unblockCorporateData?.customFeeConfig || []}
                                />
                            </div>
                        </LoaderContainer>
                        <section className="feeConfig">
                            <div className="feeConfig-content bg-[#f9fafb] pb-5 rounded-lg shadow-md">
                                <div className="content-title w-full bg-[#1f2937] px-4 py-2.5">
                                    <h2 className="text-base font-bold text-gray-300">Fee Operation Configuration</h2>
                                </div>
                                <div className="w-full py-5 content-form px-9">
                                    <form onSubmit={handleConfigSubmit}>
                                        <div className="flex flex-wrap items-center justify-start mb-2 content-form-double">
                                            <label className="text-base font-semibold text-gray-700 w-[158px] mr-7 my-4">
                                                Operation Direction:
                                                <select
                                                    value={direction}
                                                    onChange={e => setDirection(e.target.value as Direction)}
                                                    className="mt-2 w-full block border-[1.5px] bg-white font-normal border-gray-300 focus:outline-none focus:ring-[#5f9dfb] focus:border-[#5f9dfb] rounded-md py-2 px-3"
                                                >
                                                    <option value="" disabled>
                                                        Select
                                                    </option>
                                                    <option value="ONRAMP">ONRAMP</option>
                                                    <option value="OFFRAMP">OFFRAMP</option>
                                                </select>
                                            </label>

                                            <label className="text-base font-semibold text-gray-700 w-[188px] mr-7 my-4">
                                                Operation Type:
                                                <select
                                                    value={feeOpType}
                                                    onChange={e => setFeeOpType(e.target.value as FeeOpt)}
                                                    className="mt-2 w-full block border-[1.5px] bg-white font-normal border-gray-300 focus:outline-none focus:ring-[#5f9dfb] focus:border-[#5f9dfb] rounded-md py-2 px-3"
                                                >
                                                    <option value="" disabled>
                                                        Select
                                                    </option>
                                                    <option value="add">Normal Fee </option>
                                                    <option value="subtract">Discount Fee by </option>
                                                </select>
                                            </label>

                                            <label className="text-base font-semibold text-gray-700 w-[124px] mr-7">
                                                Input Currency:
                                                <select
                                                    value={inputCurrency}
                                                    onChange={e => setInputCurrency(e.target.value as Currency)}
                                                    className="mt-2 w-full block border-[1.5px] bg-white font-normal border-gray-300 focus:outline-none focus:ring-[#5f9dfb] focus:border-[#5f9dfb] rounded-md py-2 px-3"
                                                >
                                                    <option value="" disabled>
                                                        Select
                                                    </option>
                                                    <option value="EUR">EUR</option>
                                                    <option value="USD">USD</option>
                                                    <option value="GBP">GBP</option>
                                                </select>
                                            </label>

                                            <label className="text-base font-semibold text-gray-700 w-[188px] mr-7 my-4">
                                                Operation Fee:
                                                <input
                                                    type="number"
                                                    placeholder="0.01"
                                                    step="0.0001"
                                                    value={fee}
                                                    onChange={e => setFee(e.target.value)}
                                                    className="mt-2 block w-full border-[1.5px] bg-white font-normal border-gray-300 focus:outline-none focus:ring-[#5f9dfb] focus:border-[#5f9dfb] rounded-md py-1.5 px-3"
                                                    required
                                                />
                                                <p className="absolute text-xs font-medium text-gray-700">
                                                    e.g.: 0.01 corresponds to 1%
                                                </p>
                                            </label>

                                            <label className="text-base font-semibold text-gray-700 w-[138px] my-4">
                                                Output Currency:
                                                <select
                                                    value={outputCurrency}
                                                    onChange={e => setOutputCurrency(e.target.value as Currency)}
                                                    className="mt-2 w-full block border-[1.5px] bg-white font-normal border-gray-300 focus:outline-none focus:ring-[#5f9dfb] focus:border-[#5f9dfb] rounded-md py-2 px-3"
                                                >
                                                    <option value="" disabled>
                                                        Select
                                                    </option>
                                                    <option value="EUR">EUR</option>
                                                    <option value="USD">USD</option>
                                                    <option value="GBP">GBP</option>
                                                </select>
                                            </label>
                                        </div>

                                        <div className="flex items-center justify-end mt-4 content-form-btns">
                                            <button
                                                type="submit"
                                                className="border-2 border-solid rounded-lg border-[#374151] text-white bg-[#374151] px-3 py-2 mr-3.5 font-medium hover:bg-white hover:text-[#374151] hover:font-semibold"
                                            >
                                                {isLoading ? 'Configuring....' : 'Configure Fee'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </section>
                </div>
            </main>
        </div>
    );
}

export default CorporateView;
