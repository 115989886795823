import { Controller } from 'react-hook-form';

export default function MainBeneficiaryInput({ control, name, errors }: any) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value } }) => (
                <>
                    <label htmlFor="accountName" className="block text-sm font-medium  ">
                        Main Beneficiary
                    </label>
                    <div className="flex">
                        <div className="flex items-center mr-7  rounded ">
                            <input
                                id="bordered-radio-1"
                                type="radio"
                                onBlur={onBlur}
                                onChange={() => onChange(true)}
                                checked={value === true}
                                className="w-4 h-4 text-blue-600  ring-offset-gray-800 focus:ring-2 border-gray-600"
                            />
                            <label
                                htmlFor="bordered-radio-1"
                                className="w-full py-4 ms-2 text-sm font-medium"
                            >
                                Yes
                            </label>
                        </div>
                        <div className="flex items-center ps-4  rounded ">
                            <input
                                id="bordered-radio-2"
                                type="radio"
                                onBlur={onBlur}
                                onChange={() => onChange(false)}
                                checked={value === false}
                                className="w-4 h-4 text-blue-600  ring-offset-gray-800 focus:ring-2 border-gray-600"
                            />
                            <label
                                htmlFor="bordered-radio-2"
                                className="w-full py-4 ms-2 text-sm font-medium"
                            >
                                No
                            </label>
                        </div>
                    </div>
                    {errors.mainBeneficiary && (
                        <span className="text-red-500 text-sm">{errors.mainBeneficiary.message}</span>
                    )}
                </>
            )}
        />
    );
}
