import { PDFDownloadLink } from '@react-pdf/renderer';
import WalletShigaDocument from './walletPdfComponentB';

export const WalletSvg = `<svg width="17px" height="17px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.25 14.75C6.25 15.1642 6.58579 15.5 7 15.5C7.41421 15.5 7.75 15.1642 7.75 14.75V8.75C7.75 8.33579 7.41421 8 7 8C6.58579 8 6.25 8.33579 6.25 8.75V14.75Z" fill="#1C274C"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.1884 8.00377C21.1262 7.99995 21.0584 7.99998 20.9881 8L20.9706 8.00001H18.2149C15.9435 8.00001 14 9.73607 14 12C14 14.2639 15.9435 16 18.2149 16H20.9706L20.9881 16C21.0584 16 21.1262 16 21.1884 15.9962C22.111 15.9397 22.927 15.2386 22.9956 14.2594C23.0001 14.1952 23 14.126 23 14.0619L23 14.0444V9.95556L23 9.93815C23 9.874 23.0001 9.80479 22.9956 9.74058C22.927 8.76139 22.111 8.06034 21.1884 8.00377ZM17.9706 13.0667C18.5554 13.0667 19.0294 12.5891 19.0294 12C19.0294 11.4109 18.5554 10.9333 17.9706 10.9333C17.3858 10.9333 16.9118 11.4109 16.9118 12C16.9118 12.5891 17.3858 13.0667 17.9706 13.0667Z" fill="#1C274C"/>
<path opacity="0.5" d="M21.1394 8.00152C21.1394 6.82091 21.0965 5.55447 20.3418 4.64658C20.2689 4.55894 20.1914 4.47384 20.1088 4.39124C19.3604 3.64288 18.4114 3.31076 17.239 3.15314C16.0998 2.99997 14.6442 2.99999 12.8064 3H10.6936C8.85583 2.99999 7.40019 2.99997 6.26098 3.15314C5.08856 3.31076 4.13961 3.64288 3.39124 4.39124C2.64288 5.13961 2.31076 6.08856 2.15314 7.26098C1.99997 8.40019 1.99999 9.85581 2 11.6936V11.8064C1.99999 13.6442 1.99997 15.0998 2.15314 16.239C2.31076 17.4114 2.64288 18.3604 3.39124 19.1088C4.13961 19.8571 5.08856 20.1892 6.26098 20.3469C7.40018 20.5 8.8558 20.5 10.6935 20.5H12.8064C14.6442 20.5 16.0998 20.5 17.239 20.3469C18.4114 20.1892 19.3604 19.8571 20.1088 19.1088C20.3133 18.9042 20.487 18.6844 20.6346 18.4486C21.0851 17.7291 21.1394 16.8473 21.1394 15.9985C21.0912 16 21.0404 16 20.9882 16L18.2149 16C15.9435 16 14 14.2639 14 12C14 9.73607 15.9435 8.00001 18.2149 8.00001L20.9881 8.00001C21.0403 7.99999 21.0912 7.99997 21.1394 8.00152Z" fill="#1C274C"/>
</svg>`;

export type GroupedWalletData = {
    network: string;
    address: string;
};

const WalletDownloadButton = ({
    wallData,
    fullName,
    firstName,
}: {
    wallData: GroupedWalletData[];
    fullName: string;
    firstName: string;
}) => {
    const groupByAddress = (data: GroupedWalletData[]) => {
        const grouped: Record<string, GroupedWalletData> = {};

        data?.forEach(curr => {
            const { address, network } = curr;

            if (!grouped[address as unknown as string]) {
                grouped[address as unknown as string] = { network, address };
            } else {
                grouped[address as unknown as string].network += `, ${network}`;
            }
        });

        return Object.values(grouped);
    };

    const reformedData = groupByAddress(wallData);

    return (
        <PDFDownloadLink
            document={
                <WalletShigaDocument
                    customerName={(firstName as string) || 'Ubo'}
                    fullName={fullName as string}
                    walletObeject={reformedData}
                />
            }
            fileName={`${fullName}-Shiga-Virtual-Wallet-Accounts.pdf`}
            style={{
                textDecoration: 'none',
                // padding: "10px 20px",
                color: '#000000',
                // backgroundColor: "#007bff",
                borderRadius: '5px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
                cursor: 'pointer',
            }}
        >
            <span dangerouslySetInnerHTML={{ __html: WalletSvg }} className="h-[16px] w-[16px]" />
            Download Stablecoin Wallet Details
        </PDFDownloadLink>
    );
};

export default WalletDownloadButton;
