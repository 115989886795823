import { format } from 'date-fns';
import { HTMLInputTypeAttribute, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import LoaderContainer from '../components/loader';
import Modal from '../components/modal';
import Table from '../components/table';
import UnblockExchangeRates from '../components/unblockExchangeRates';
import useModal from '../hooks/useModal';
import { trpc } from '../lib/trpc';
import ReportTransaction, { TransactionType } from '../section/report-transaction';
import { formatNumberWithCommasAndTwoDecimals } from '../utils/formatCurrency';
import Create from './create';
import CreateCoporate from './createCorporate';

interface Option {
    value: string;
    label: string;
}

type Column = {
    label: string;
    key: string;
    format?: (v1: any, v2?: any) => any;
    meta?: { type: HTMLInputTypeAttribute | 'select'; options?: Option[] } | null;
};

const transactionsColumn: Column[] = [
    { label: 'Vendor Transaction Id', key: 'vendorTransactionId' },
    { label: 'Status', key: 'status', format: (status: string) => status?.replaceAll('_', ' ') },
    { label: 'Direction', key: 'direction' },
    { label: 'Transaction Hash', key: 'transactionHash' },
    { label: 'Exchange Rate', key: 'exchangeRate' },
    { label: 'Currency Fiat', key: 'currencyFiat' },
    { label: 'Currency Crypto', key: 'currencyCrypto' },
    { label: 'Amount Fiat', key: 'amountFiat' },
    { label: 'Amount Crypto', key: 'amountCrypto' },
    { label: 'Chain', key: 'chain' },
    { label: 'Overall-Fee', key: 'fees' },
    { label: 'Shiga Fee', key: 'merchantFee' },
    { label: 'Unblock Fee', key: 'unblockFee' },
    { label: 'Created At', key: 'createdAt', format: (date: string) => format(date, 'hh:mm dd MMMM, yyyy') },
];

function Home() {
    const [title, setTitle] = useState('Individual');
    const [transactionDetail, setTransactionDetail] = useState({} as TransactionType);

    const { isModalVisible, setModalClose, setModalOpen } = useModal();
    const { data: txnData, isLoading } = trpc.adminTransaction.listTransactions.useQuery({ limit: 5, page: 1 });
    const { data: txns, isLoading: txnsLoading } = trpc.adminTransaction.transactionCount.useQuery();
    const { data: users, isLoading: usersLoading } = trpc.admin.userCount.useQuery();
    const { data: corporate, isLoading: corpoatesLoading } = trpc.corporate.CorporateCount.useQuery();
    const { data: monthlyFees, isLoading: monthlyFeesLoading } = trpc.adminTransaction.getMonthlyFees.useQuery();
    const { data: exchangeRates, isLoading: isLoadingExchangeRates } = trpc.adminInfo.getUnblockExchangeRates.useQuery(
        undefined,
        {
            refetchInterval: 1000, // Refetch every 1 second
        },
    );
    const { data: shigaProcessedRate } = trpc.adminInfo.getShigaProcessedExchangeRate.useQuery(undefined, {
        refetchInterval: 1000, //   Refetch every 1 second
    });

    const { data: unblockProcessedRate } = trpc.adminInfo.getUnblockProcessedExchangeRate.useQuery(undefined, {
        refetchInterval: 1000, //   Refetch every 1 second
    });

    const navigate = useNavigate();

    const handleNavigate = (pathName: string) => {
        navigate(pathName);
    };

    const handleCreateModal = () => {
        setModalOpen(1);
        setTitle('Individual');
    };

    return (
        <div className="mx-5 mt-5">
            <div className="flex justify-end -mt-8">
                <div>
                    <button className="mb-5 btn" onClick={handleCreateModal}>
                        Create Customer
                    </button>
                </div>
            </div>

            <div className="flex justify-between gap-5">
                <div
                    className="w-full overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 hover:cursor-pointer"
                    onClick={() => handleNavigate('/individuals')}
                >
                    <div className="flex items-center p-4">
                        <div className="p-3 mr-4 text-orange-500 rounded-full">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
                                />
                            </svg>
                        </div>
                        <div>
                            <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                                Total Individuals
                            </p>
                            <p className="text-lg font-semibold text-gray-700">
                                <LoaderContainer loading={usersLoading}>{users?.count}</LoaderContainer>
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    onClick={() => handleNavigate('/corporates')}
                    className="w-full overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 hover:cursor-pointer"
                >
                    <div className="flex items-center p-4">
                        <div
                            className="p-3 mr-4 text-green-500 bg-green-100 rounded-full dark:text-green-100 dark:bg-green-500"
                            onClick={() => handleNavigate('/corporates')}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"
                                />
                            </svg>
                        </div>
                        <div>
                            <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                                Total Corporates
                            </p>

                            <p className="text-lg font-semibold text-gray-700">
                                <LoaderContainer loading={corpoatesLoading}>{corporate?.count}</LoaderContainer>
                            </p>
                        </div>
                    </div>
                </div>
                <div
                    className="w-full overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 hover:cursor-pointer"
                    onClick={() => handleNavigate('/transactions')}
                >
                    <div className="flex items-center p-4">
                        <div className="p-3 mr-4 text-blue-500 bg-blue-100 rounded-full dark:text-blue-100 dark:bg-blue-500">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                                />
                            </svg>
                        </div>
                        <div>
                            <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                                Total Transactions
                            </p>
                            <p className="text-lg font-semibold text-gray-700">
                                <LoaderContainer loading={txnsLoading}>{txns?.count}</LoaderContainer>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-end mt-4 mb-9">
                <div className="w-[40%] p-4 overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 hover:cursor-pointer">
                    <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                        Summary of Fees Earned For The Month
                    </p>
                    <div className="grid grid-cols-3">
                        <p className="text-lg font-semibold text-gray-700">
                            <LoaderContainer loading={monthlyFeesLoading}>
                                <b>£</b>
                                {formatNumberWithCommasAndTwoDecimals((monthlyFees?.totalFeeGBP as number) || 0)}
                            </LoaderContainer>
                        </p>
                        <p className="text-lg font-semibold text-gray-700">
                            <LoaderContainer loading={monthlyFeesLoading}>
                                <b>€</b>
                                {formatNumberWithCommasAndTwoDecimals((monthlyFees?.totalFeeEUR as number) || 0)}
                            </LoaderContainer>
                        </p>
                        <p className="text-lg font-semibold text-gray-700">
                            <LoaderContainer loading={monthlyFeesLoading}>
                                {formatNumberWithCommasAndTwoDecimals((monthlyFees?.totalFeeUSDT as number) || 0)}
                                <b>USDT</b>
                            </LoaderContainer>
                        </p>
                    </div>
                </div>
            </div>

            <UnblockExchangeRates
                exchangeRates={exchangeRates}
                isLoadingExchangeRates={isLoadingExchangeRates}
                shigaProcessesedExchangeRates={shigaProcessedRate}
                ublockProcessesedExchangeRates={unblockProcessedRate}
            />
            <LoaderContainer loading={isLoading}>
                <main className="">
                    <Table
                        title="Last Five (5) Transactions"
                        columns={transactionsColumn}
                        data={txnData?.data || []}
                        useReport={true}
                        enableActions={true}
                        onRowReport={(data: any) => {
                            setModalOpen(2);
                            setTransactionDetail(data);
                        }}
                    />
                </main>
            </LoaderContainer>
            <Modal
                title="Create Customer"
                showModal={isModalVisible(1)}
                closeModal={() => setModalClose(1)}
                containerClassName="p-5 md:p-0 space-y-0"
            >
                <div>
                    <div className={twMerge('flex justify-start gap-x-4 py-5 px-8 ')}>
                        <button
                            onClick={() => setTitle('Individual')}
                            className={`font-medium rounded-lg text-sm lg:w-48 sm:w-auto px-5 py-2.5 text-center  ${
                                title === 'Individual'
                                    ? 'bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 text-white btn'
                                    : 'text-black btn focus:ring-4 focus:outline-none focus:ring-blue-300 '
                            }`}
                        >
                            Individual
                        </button>
                        <button
                            onClick={() => setTitle('Corporate')}
                            disabled={isLoading}
                            type="submit"
                            className={`font-medium rounded-lg text-sm lg:w-48 sm:w-auto px-5 py-2.5 text-center  ${
                                title === 'Corporate'
                                    ? 'bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 text-white btn'
                                    : 'text-black btn focus:ring-4 focus:outline-none focus:ring-blue-300 '
                            }`}
                        >
                            Corporate
                        </button>
                    </div>
                    {title === 'Individual' && (
                        <Create
                            useTitle={false}
                            sectionClassName="pb-5 pt-0"
                            btnContainerClassName="flex justify-center mt-12"
                            closeModal={() => setModalClose(1)}
                        />
                    )}

                    {title === 'Corporate' && (
                        <CreateCoporate
                            useTitle={false}
                            sectionClassName="pb-5 pt-0"
                            btnContainerClassName="flex justify-center mt-12"
                            closeModal={() => setModalClose(1)}
                        />
                    )}
                </div>
            </Modal>
            <Modal title="Report Transaction" closeModal={() => setModalClose(2)} showModal={isModalVisible(2)}>
                <ReportTransaction dataReport={transactionDetail} setModalClose={() => setModalClose(2)} />
            </Modal>
        </div>
    );
}

export default Home;
