interface ErrorInputProps {
    id: string;
    error: { message: string | undefined };
    register: any;
    [key: string]: any;
}

const ErrorInput = ({ id, error, register, ...inputProps }: ErrorInputProps) => {
    return (
        <>
            <input
                type="text"
                id={id}
                className={`bg-gray-50 border ${
                    error ? 'border-red-500' : 'border-gray-300'
                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                {...register}
                {...inputProps}
            />
            {error && <span className="text-red-500 text-sm">{error.message}</span>}
        </>
    );
};

export default ErrorInput;
