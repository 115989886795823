import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { trpc } from '../../lib/trpc';
import { accountStatementForm } from './type';

const UserDropdown = ({ accountStatementForm }: { accountStatementForm: accountStatementForm }) => {
    const { watch, control } = accountStatementForm;
    const watchedValue = watch('idType');

    const { data: userData } = trpc.admin.unpaginatedListUsers.useQuery(
        {},
        {
            enabled: watchedValue === 'user',
        },
    );

    const options = userData?.map(user => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
    }));


    return (
        <div className="w-full">
            <label className="mb-3 text-[13px] font-[500]">Select User</label>
            <Controller
                name="userId"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <Select
                        placeholder="Enter the name of the user"
                        options={options}
                        value={options?.find(option => option.value === Number(value))}
                        onChange={e => {
                            onChange(e?.value);
                        }}
                        isClearable={true}
                        isSearchable={true}
                    />
                )}
            />
        </div>
    );
};

export default UserDropdown;
