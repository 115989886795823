import { Fragment } from 'react';

export const NotFoundPage = () => {
    return (
        <Fragment>
            <div className="content-layout">
                <h1 className="font-bold">Not Found</h1>
            </div>
        </Fragment>
    );
};
