import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import Alert from '../../components/alert';
import ErrorInput from '../../components/input';
import useAlert from '../../hooks/useAlert';
import { trpc } from '../../lib/trpc';
import MainBeneficiaryInput from './mainBeneficiaryInput';

type DestinationAccountType = 'BA' | 'DW';
const addDestinationBankSchema = z
    .object({
        accountName: z.string().min(1, 'Account Name is required'),
        iban: z.string().optional(),
        currency: z.enum(['GBP', 'EUR', 'INR', 'PKR']),
        mainBeneficiary: z.boolean(),
        sortCode: z.string().optional(),
        accountNumber: z.string().optional(),
        ifsc: z.string().optional(),
        bankCode: z.string().optional(),
        accountType: z.string().optional(),
        customerdetails: z
            .object({
                name: z.string().optional(),
                contact: z.string().optional(),
            })
            .optional(),
    })
    .superRefine(({ currency, iban, sortCode, accountNumber, customerdetails, ifsc, bankCode, accountType }, refinementContext) => {
        if (currency === 'EUR' && !iban) {
            return refinementContext.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'IBAN is required for EUR currency',
                path: ['iban'],
            });
        }

        if (currency === 'GBP') {
            if (!sortCode) {
                return refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Sort Code is required for GBP currency',
                    path: ['sortCode'],
                });
            }
            if (!accountNumber) {
                return refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Account Number is required for GBP currency',
                    path: ['accountNumber'],
                });
            }
        }

        if (currency === 'INR') {
            if (!ifsc) {
                return refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Bank ifsc is required for INR currency',
                    path: ['ifsc'],
                });
            }
            if (!customerdetails) {
                return refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Customer details is required for INR currency',
                    path: ['customerdetails'],
                });
            }
        }

        if (currency === 'PKR') {
            if(!bankCode){
                return refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Bank code is required for PKR currency',
                    path: ['bankCode'],
                });
            }

            if(!accountType){    
                return refinementContext.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Account type is required for PKR currency',
                    path: ['accoutType'],
                });
            }
        }

        return refinementContext;
    });

type AddDestinationBankSchema = z.infer<typeof addDestinationBankSchema>;

const AddBankDEstinationForm = ({
    setModalClose,
    isCorporate,
    administratorId,
}: {
    setModalOpen: () => void;
    setModalClose: () => void;
    isCorporate?: boolean;
    administratorId?: string;
}) => {
    const { showAlert, alert } = useAlert();
    const utils = trpc.useUtils();
    const {
        reset,
        control,
        watch,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<AddDestinationBankSchema>({
        resolver: zodResolver(addDestinationBankSchema),
    });

    const params = useParams<{ id: string }>() as { id: string };

    const { mutate: createDestinationBankAcct, isLoading: isLoadingCreateDestinationBankAcct } =
        trpc.admin.createDestinationBankAccount.useMutation({
            onSuccess: () => {
                showAlert('Destination bank account successfully created', 'success');
                reset();
                utils.admin.listUserDestinationBankAccounts.refetch();
                setModalClose();
            },
            onError: e => {
                showAlert(e?.message, 'error');
            },
        });

    const { mutate: createCorporateDestinationBankAcct, isLoading: isLoadingCorporateCreateDestinationBankAcct } =
        trpc.corporate.createDestinationBankAccount.useMutation({
            onSuccess: () => {
                showAlert('Destination bank account successfully created', 'success');
                setModalClose();
                reset();
                utils.admin.listUserDestinationBankAccounts.refetch();
            },
            onError: e => {
                showAlert(e?.message, 'error');
            },
        });

    const onSubmit = (e: any) => {
        params.id && administratorId && isCorporate
            ? createCorporateDestinationBankAcct({
                  userId: +administratorId,
                  mainBeneficiary: e.mainBeneficiary,
                  accountName: e.accountName,
                  currency: e.currency,
                  iban: e.iban,
                  accountNumber: e.accountNumber,
                  sortCode: e?.sortCode,
                  companyId: +params.id,
              })
            : createDestinationBankAcct({
                  id: +params.id,
                  mainBeneficiary: e.mainBeneficiary,
                  accountName: e.accountName,
                  currency: e.currency,
                  iban: e.iban,
                  accountNumber: e.accountNumber,
                  sortCode: e?.sortCode,
                  ifsc: e.ifsc,
                  customerDetails: e.customerdetails,
                  bankCode: e.bankCode,
                  accountType: e.accountType as DestinationAccountType,
              });
    };

    return (
        <>
            {alert && <Alert classes={'w-[20%] ml-auto'} message={alert.message} event={alert.type} />}
            <form className="w-[100%] px-8" onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-5">
                    <label htmlFor="currency" className="block mb-2 text-sm font-medium ">
                        Currency{' '}
                    </label>

                    <select
                        id="currency"
                        {...register('currency')}
                        className={`bg-gray-50 border ${
                            errors.currency ? 'border-red-500' : 'border-gray-300'
                        } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                    >
                        <option disabled value={''} selected>
                            select
                        </option>
                        <option value="GBP">GBP</option>
                        <option value="EUR">EUR</option>
                        {!isCorporate && <option value="INR">INR</option>}
                        {!isCorporate && <option value="PKR">PKR</option>}
                    </select>
                    {errors.currency && <span className="text-sm text-red-500">{errors.currency.message}</span>}
                </div>

                <div className="mb-5">
                    <label htmlFor="accountName" className="block mb-2 text-sm font-medium ">
                        Account Name{' '}
                    </label>
                    <ErrorInput
                        error={{
                            message: errors.accountName?.message,
                        }}
                        register={register('accountName')}
                        type="text"
                        id="accountName"
                        className={`bg-gray-50 border ${
                            errors.accountName ? 'border-red-500' : ''
                        }  text-sm rounded-lg   block w-full p-2.5  border-gray-600 placeholder-gray-400 `}
                    />
                </div>
                <div className="mb-5">
                    <MainBeneficiaryInput errors={errors} control={control} name={'mainBeneficiary'} />
                </div>

                {/* Conditionally render inputs based on the selected currency */}
                {watch('currency') === 'GBP' && (
                    <div>
                        {/* Add GBP-specific inputs here */}
                        <div className="mb-5">
                            <label htmlFor="sortCode" className="block mb-2 text-sm font-medium">
                                Sort Code{' '}
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.sortCode?.message,
                                }}
                                register={register('sortCode')}
                                type="text"
                                id="sortCode"
                                className={`bg-gray-50 border ${
                                    errors.sortCode ? 'border-red-500' : ''
                                }  text-sm rounded-lg   block w-full p-2.5  border-gray-600 placeholder-gray-400`}
                            />
                        </div>
                        <div className="mb-5">
                            <label htmlFor="accountNumber" className="block mb-2 text-sm font-medium">
                                Account Number{' '}
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.accountNumber?.message,
                                }}
                                register={register('accountNumber')}
                                type="text"
                                id="accountNumber"
                                className={`bg-gray-50 border ${
                                    errors.accountNumber ? 'border-red-500' : ''
                                }  text-sm rounded-lg   block w-full p-2.5  border-gray-600 placeholder-gray-400`}
                            />
                        </div>
                    </div>
                )}

                {watch('currency') === 'EUR' && (
                    <div>
                        {/* Add EUR-specific inputs here */}
                        <div>
                            <label htmlFor="iban" className="block mb-2 text-sm font-medium">
                                IBAN{' '}
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.iban?.message,
                                }}
                                register={register('iban')}
                                type="text"
                                id="iban"
                                className={`bg-gray-50 border ${
                                    errors.iban ? 'border-red-500' : ''
                                }  text-sm rounded-lg   block w-full p-2.5  border-gray-600 placeholder-gray-400`}
                            />
                        </div>
                    </div>
                )}
                {watch('currency') === 'INR' && !isCorporate && (
                    <div>
                        {/* Add GBP-specific inputs here */}
                        <div className="mb-5">
                            <label htmlFor="accountNumber" className="block mb-2 text-sm font-medium">
                                Account Number{' '}
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.accountNumber?.message,
                                }}
                                register={register('accountNumber')}
                                type="text"
                                id="accountNumber"
                                className={`bg-gray-50 border ${
                                    errors.accountNumber ? 'border-red-500' : ''
                                }  text-sm rounded-lg   block w-full p-2.5  border-gray-600 placeholder-gray-400`}
                            />
                        </div>
                        <div className="mb-5">
                            <label htmlFor="ifsc" className="block mb-2 text-sm font-medium">
                                The bank's IFSC
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.ifsc?.message,
                                }}
                                register={register('ifsc')}
                                type="text"
                                id="ifsc"
                                className={`bg-gray-50 border ${
                                    errors.ifsc ? 'border-red-500' : ''
                                }  text-sm rounded-lg   block w-full p-2.5  border-gray-600 placeholder-gray-400`}
                            />
                        </div>
                        <div className="px-5 py-2 mb-5 bg-slate-500">
                            <p className="block text-xl font-bold text-white">Contact Details </p>
                        </div>
                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                            <div>
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">
                                    Name
                                </label>
                                <ErrorInput
                                    error={{
                                        message: errors.customerdetails?.name?.message,
                                    }}
                                    register={register('customerdetails.name')}
                                    type="text"
                                    id="name"
                                    className={`bg-gray-50 border ${
                                        errors?.customerdetails?.name ? 'border-red-500' : 'border-gray-300'
                                    } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                />
                            </div>

                            <div>
                                <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 ">
                                    Phone number
                                </label>
                                <ErrorInput
                                    error={{
                                        message: errors?.customerdetails?.contact?.message,
                                    }}
                                    register={register('customerdetails.contact')}
                                    type="text"
                                    id="Phone"
                                    placeholder="e.g +442038857874"
                                    className={`bg-gray-50 border ${
                                        errors?.customerdetails?.contact ? 'border-red-500' : 'border-gray-300'
                                    } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {watch('currency') === 'PKR' && !isCorporate && (
                    <div>
                        {/* Add GBP-specific inputs here */}
                        <div className="mb-5">
                            <label htmlFor="accountNumber" className="block mb-2 text-sm font-medium">
                                Account Number{' '}
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.accountNumber?.message,
                                }}
                                register={register('accountNumber')}
                                type="text"
                                id="accountNumber"
                                className={`bg-gray-50 border ${
                                    errors.accountNumber ? 'border-red-500' : ''
                                }  text-sm rounded-lg   block w-full p-2.5  border-gray-600 placeholder-gray-400`}
                            />
                        </div>
                        <div className="mb-5">
                            <label htmlFor="bankCode" className="block mb-2 text-sm font-medium">
                                Bank code
                            </label>
                            <ErrorInput
                                error={{
                                    message: errors.bankCode?.message,
                                }}
                                register={register('bankCode')}
                                type="text"
                                id="ifsc"
                                className={`bg-gray-50 border ${
                                    errors.bankCode ? 'border-red-500' : ''
                                }  text-sm rounded-lg   block w-full p-2.5  border-gray-600 placeholder-gray-400`}
                            />
                        </div>
                        <div className="mb-5">
                            <label htmlFor="accountType" className="block mb-2 text-sm font-medium ">
                                Account Type{' '}
                            </label>

                            <select
                                id="accountType"
                                {...register('accountType')}
                                className={`bg-gray-50 border ${
                                    errors.accountType ? 'border-red-500' : 'border-gray-300'
                                } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
                            >
                                <option disabled value={''} selected>
                                    select
                                </option>
                                <option value="BA">Bank Account</option>
                                <option value="DW">Digital Wallet</option>
                            </select>
                            {errors.accountType && <span className="text-sm text-red-500">{errors.accountType.message}</span>}
                        </div>
                        <div className="px-5 py-2 mb-5 bg-slate-500">
                            <p className="block text-xl font-bold text-white">Contact Details </p>
                        </div>
                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                            <div>
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 ">
                                    Name
                                </label>
                                <ErrorInput
                                    error={{
                                        message: errors.customerdetails?.name?.message,
                                    }}
                                    register={register('customerdetails.name')}
                                    type="text"
                                    id="name"
                                    className={`bg-gray-50 border ${
                                        errors?.customerdetails?.name ? 'border-red-500' : 'border-gray-300'
                                    } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                />
                            </div>

                            <div>
                                <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 ">
                                    Phone number
                                </label>
                                <ErrorInput
                                    error={{
                                        message: errors?.customerdetails?.contact?.message,
                                    }}
                                    register={register('customerdetails.contact')}
                                    type="text"
                                    id="Phone"
                                    placeholder="e.g +442038857874"
                                    className={`bg-gray-50 border ${
                                        errors?.customerdetails?.contact ? 'border-red-500' : 'border-gray-300'
                                    } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className="flex items-center p-4 rounded-b md:p-5">
                    <button
                        disabled={isLoadingCreateDestinationBankAcct || isLoadingCorporateCreateDestinationBankAcct}
                        type="submit"
                        className="mr-5 btn btn-outline"
                    >
                        {isLoadingCreateDestinationBankAcct || isLoadingCorporateCreateDestinationBankAcct
                            ? 'Saving'
                            : 'Save'}
                    </button>
                    <button type="button" onClick={() => setModalClose()} className="btn btn-outline">
                        Cancel
                    </button>
                </div>
            </form>
        </>
    );
};

export default AddBankDEstinationForm;
