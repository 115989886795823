import { format } from 'date-fns';
import { HTMLInputTypeAttribute, useState } from 'react';
import { CSVLink } from 'react-csv';
import Pagination from '../../components/pagination';
import Table from '../../components/table';
import { trpc } from '../../lib/trpc';


interface Option {
    value: string;
    label: string;
}

export type transactionEnum = 'IN_PROGRESS' | 'FAILED' | 'SUCCESS' | 'ON_HOLD' | 'LIMIT_BREACHED';

export type FilterBaseQuery = {
    id?: number;
    type?: 'user' | 'company';
    direction?: string;
    currencyCrypto?: string;
    currencyFiat?: string;
    chain?: string;
    page?: number;
    limit?: number;
    dateFrom?: string;
    dateTo?: string;
};

type Column = {
    label: string;
    key: string;
    format?: (v1: any, v2?: any) => any;
    meta?: { type: HTMLInputTypeAttribute | 'select'; options?: Option[] } | null;
};

const transactionsColumn: Column[] = [
    { label: 'Vendor Transaction Id', key: 'vendorTransactionId' },
    { label: 'Status', key: 'status', format: (status: string) => status?.replaceAll('_', ' ') },
    { label: 'Direction', key: 'direction' },
    { label: 'Transaction Hash', key: 'transactionHash' },
    { label: 'Exchange Rate', key: 'exchangeRate' },
    { label: 'Currency Fiat', key: 'currencyFiat' },
    { label: 'Currency Crypto', key: 'currencyCrypto' },
    { label: 'Amount Fiat', key: 'amountFiat' },
    { label: 'Amount Crypto', key: 'amountCrypto' },
    { label: 'Chain', key: 'chain' },
    { label: 'Overall-Fee', key: 'fees' },
    { label: 'Shiga Fee', key: 'merchantFee' },
    { label: 'Unblock Fee', key: 'unblockFee' },
    { label: 'Created At', key: 'createdAt', format: (date: string) => format(date, 'hh:mm dd MMMM, yyyy') },
];

function StatementTransactionsList ({ incomingqueryParams }: { incomingqueryParams: FilterBaseQuery }) {
    const [queryParams, setQueryParams] = useState({
        limit: 10,
        page: 1,
        ...(incomingqueryParams || {}),
    });

    const updateQueryParams = (params: FilterBaseQuery) => {
        setQueryParams(prev => ({ ...prev, ...params }));
    };

    const { data: txnData, isError } = trpc.adminTransaction.summaryListofTransactions.useQuery(
        queryParams,
        { enabled: incomingqueryParams.id !== undefined ? true : false },
    );

    const { data: downloadTransaction } = trpc.adminTransaction.downLoadListTransactions.useQuery({
        dateFrom: queryParams?.dateFrom,
        dateTo: queryParams?.dateTo,
    }, { refetchOnWindowFocus: false });

    const { meta, data } = txnData || {};

    // For the pagination
    const [pageNumberLimit] = useState(5);
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);

    const changePage = (pageNumber: number) => {
        updateQueryParams({ page: pageNumber });
    };

    const incrementPage = () => {
        updateQueryParams({ page: (queryParams?.page as number) + 1 });
        if ((queryParams?.page as number) + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
        }
    };

    const decrementPage = (): void | null => {
        updateQueryParams({ page: (queryParams?.page as number) - 1 });
        if (((queryParams?.page as number) - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
        }
        if ((queryParams?.page as number) - 1 === 0) {
            return null;
        }
    };

    if (isError) {
        return <h2>Error...</h2>;
    }

    return (
        <div className="">
            <div className='flex justify-end mb-3'>
                <CSVLink
                    data={downloadTransaction || []}
                    headers={transactionsColumn}
                    filename={'Transactions-data.csv'}
                    className="border-2 border-solid rounded-lg border-[#374151] text-white bg-[#374151] px-3 py-2 font-medium hover:bg-white hover:text-[#374151] hover:font-semibold"
                    target="_blank"
                >
                    Download transactions
                </CSVLink>
            </div>
            <Table
                title="Transaction List"
                columns={transactionsColumn}
                data={data || []}
                useReport={true}
                enableActions={true}
            />
            <Pagination
                totalPages={meta?.totalPages || 0}
                pageSize={meta?.limit || 0}
                totalRecords={meta?.totalRecords || 0}
                page={meta?.currentPage || 0}
                changePage={changePage}
                incrementPage={incrementPage}
                decrementPage={decrementPage}
                pageNumberLimit={pageNumberLimit || 0}
                minPageNumberLimit={minPageNumberLimit || 0}
                maxPageNumberLimit={maxPageNumberLimit || 0}
            />{' '}
        </div>
    );
}

export default StatementTransactionsList;
