import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { accountStatementForm } from './type';

const objectToMap = [
    {
        label: 'Direction',
        options: [
            {
                label: 'FiatToCrypto',
                value: 'fiatToCrypto',
            },
            {
                label: 'CryptoToFiat',
                value: 'cryptoToFiat',
            },
        ],
    },
    {
        label: 'Chain',
        options: [
            {
                label: 'Polygon',
                value: 'polygon',
            },
            {
                label: 'Tron',
                value: 'tron',
            }
        ],
    },
];


export type directType = 'direction' | 'chain';

function DirectionAndChain({ accountStatementForm }: { accountStatementForm: accountStatementForm }) {
    const { control } = accountStatementForm;
    return (
        <div className="w-full">
            <div className="flex items-center justify-between w-full gap-x-3">
                {objectToMap.map((item, index) => (
                    <div key={`${item.label}-${index}`} className="w-full">
                        <label className="mb-3 text-[13px] font-[500]">{item.label}</label>
                        <Controller
                            name={item.label.toLocaleLowerCase() as directType}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    placeholder={`Select ${item.label}`}
                                    options={item.options}
                                    value={item.options?.find(option => option.value === value)}
                                    onChange={e => {
                                        onChange(e?.value);
                                    }}
                                    isClearable={true}
                                    isSearchable={true}
                                />
                            )}
                        />{' '}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DirectionAndChain;
